import * as React from 'react'
import { SymbolSquare } from './symbol-square'
import { GridDisplay } from '../../layout/grid-display'
import { useSelector } from '../../../ducks/root-reducer'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { idEquals } from '../../../ducks/helpers'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { useRoute } from '@react-navigation/core'
import { getNumGridColsPerRow } from '../../../modules/ui-helpers/grid-helpers'
import { CountedFastTag } from '../../../../../api/_openapi'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { i18nNumber } from '../../../modules/strings/string-helpers'
import { selectUserLanguage } from '../../../ducks/user/user'
import { Column } from '../../common/column/column'
import {
  LINE_WIDTH,
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'

type SymbolGridProps = InterfaceBoxProps & {
  width: number
  tags: CountedFastTag[]
  showNumUses?: boolean
  editable?: boolean
  onDeleteSymbol?: (tagId: string) => void
}

export const SymbolGrid = ({
  width,
  tags,
  showNumUses,
  editable,
  onDeleteSymbol,
  ...props
}: SymbolGridProps) => {
  // Selectors
  const route = useRoute<any>()
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = useActiveGroupId()
  const userLanguage = useSelector(selectUserLanguage)

  // Vars
  const isPrivateGroup =
    Boolean(activeGroupId) &&
    Boolean(privateGroupId) &&
    idEquals(activeGroupId, privateGroupId)

  const isLibraryDreamView = route.name === 'LibraryDreamView'
  width = Math.min(width, WEB_MAX_WIDTH - PADDING_HORIZONTAL_PIXELS * 2)
  const symbolsPerRow = getNumGridColsPerRow(width)
  const boxWidth = width / symbolsPerRow
  const symbolSquareWidth = boxWidth - LINE_WIDTH * (symbolsPerRow - 1)

  return (
    <Column maxWidth={WEB_MAX_WIDTH} {...props}>
      <GridDisplay
        width={width}
        items={tags.map((s, i) => {
          const gridItemId = `symbol-square-${s.id}`
          return {
            id: gridItemId,
            item: (
              <SymbolSquare
                key={gridItemId}
                width={symbolSquareWidth}
                tag={s}
                hidden={s.count === 0}
                hiddenText={i18nNumber(i + 1, userLanguage)}
                showNumUses={showNumUses}
                clickable={isPrivateGroup && !isLibraryDreamView}
                editable={editable}
                canAddImage={s.type === 'CHARACTER'}
                onDeleteSymbol={onDeleteSymbol}
              />
            ),
          }
        })}
      />
    </Column>
  )
}
