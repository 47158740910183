import React, { useState, useMemo } from 'react'
import { View } from 'react-native'
import { simpleColorsList } from '../../../modules/color/colorList'
import { Box } from '../../common/box/box'
import { InputToggle } from '../../common/inputs/input-toggle'
import { Row } from '../../common/row/row'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { AlertDetails } from '../../../../../api/frontend-types'
import { getColorDefinitionsWithChildren } from '../../../modules/color/color-helpers'
import { PaletteSection } from './palette-section'
import { ColorCircle } from './color-circle'
import useContentWidth from '../../../hooks/useContentWidth'
import { LINE_WIDTH } from '../../../constants/constants'
import { useColorModeValue } from 'native-base'
import { Column } from '../../common/column/column'
import { SansText } from '../../common/copy/text-sans'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../layout/loading'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import {
  colorIsShiny,
  hslToHexString,
} from '../../../modules/color/color-string-helpers'
import {
  Color,
  ColorDefinitionWithChildren,
  ColorTagSubType,
  ColorWithChildren,
  PickerI18nLayout,
} from '../../../modules/color/color-types'
import { COLOR_ORDER } from './color-picker-config'

const DEFAULT_MAX_COLORS = 12
const MAX_ROW_HEIGHT = 70
const MAX_CIRCLE_DIAMETER = 55
const MIN_PADDING = 6
const PICKER_BREAKPOINT_PX = 450
const NUM_BASIC_COLORS = 12
const BACK_BTN_WIDTH = 45

export type PickerColors = {
  simpleColors: Color[]
  complexColors: Color[]
}

type ColorPickerProps = {
  colors: PickerColors
  loading: boolean
  showAdvancedToggle: boolean
  onAddColor: (newColor: Color, existingColors: PickerColors) => void
  onRemoveColor: (newColor: Color, existingColors: PickerColors) => void
  maxColors?: number
  pickerLayout?: PickerI18nLayout
}

export const ColorPicker = ({
  colors,
  loading,
  onAddColor,
  showAdvancedToggle,
  onRemoveColor,
  maxColors = DEFAULT_MAX_COLORS,
  pickerLayout = 'standard',
}: ColorPickerProps) => {
  // STATE
  const [advancedModeOn, setAdvancedModeOn] =
    useState<boolean>(showAdvancedToggle)
  const [alert, setAlert] = useState<AlertDetails | undefined>()
  const [selectedBasicColor, setSelectedBasicColor] = useState<
    Color | undefined
  >()

  // HOOKS
  const { t } = useTranslation()
  const { paddedContentWidth: pickerWidth } = useContentWidth()
  const bgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const borderColor = useColorModeValue(BLACK, WHITE)

  const setAdvancedMode = (value: boolean | undefined) => {
    const val = Boolean(value)
    if (val === true) {
      setAdvancedModeOn(true)
    } else {
      setAdvancedModeOn(false)
      // Clear the selected basic color when turning off advanced mode
      setSelectedBasicColor(undefined)
    }
  }

  // VARS
  const { complexColors } = colors
  const isWideLayout = pickerWidth > PICKER_BREAKPOINT_PX
  const numColsSimple = isWideLayout ? 6 : 4
  const numColsAdvanced = isWideLayout ? 8 : 4
  const numRows = NUM_BASIC_COLORS / numColsSimple
  const colWidthPercent = 100 / numColsSimple
  const numColors = complexColors.length
  const hasAlert = Boolean(alert)
  const p = pickerWidth / 30
  const palettePadding = p > MIN_PADDING ? p : MIN_PADDING
  const totalPadding = palettePadding * 2
  const rh = (pickerWidth - totalPadding) / numColsSimple
  const colorRowHeight = rh > MAX_ROW_HEIGHT ? MAX_ROW_HEIGHT : rh
  const paletteHeight = colorRowHeight * numRows + totalPadding
  const maxColorsReached = complexColors.length >= maxColors
  const colorOrder = COLOR_ORDER[pickerLayout]

  // MEMOS
  // Get list of colours as colours
  const basicColorsWithChildren: ColorWithChildren[] = useMemo(() => {
    const colorDefinitionsWithChildren: ColorDefinitionWithChildren[] =
      getColorDefinitionsWithChildren(simpleColorsList, pickerLayout)

    const defs = colorDefinitionsWithChildren.map((c) => {
      const colorHex = hslToHexString(...c.hsl)
      const color: ColorWithChildren = {
        colorName: c.name,
        simpleColor: c.associations[0],
        hex: colorHex,
        hasChildColors: c.hasChildColors,
        hasChildColorsInSimpleMode: c.hasChildColorsInSimpleMode,
      }
      return color
    })
    // Order the colors
    const ret = defs.sort((a: ColorWithChildren, b: ColorWithChildren) => {
      const simpleColorA: ColorTagSubType = a.simpleColor
      const simpleColorB: ColorTagSubType = b.simpleColor
      return colorOrder.indexOf(simpleColorA) - colorOrder.indexOf(simpleColorB)
    })
    return ret
  }, [simpleColorsList, pickerLayout])

  // Calculate circle diameter
  const wideLayoutBaseWidth = (pickerWidth - BACK_BTN_WIDTH) / numColsAdvanced
  const narrowLayoutBaseWidth = colorRowHeight
  const baseWidth = isWideLayout ? wideLayoutBaseWidth : narrowLayoutBaseWidth
  const cd = baseWidth * 0.75
  const circleDiameter = cd > MAX_CIRCLE_DIAMETER ? MAX_CIRCLE_DIAMETER : cd

  // ALERTS
  const colorAlreadyAddedAlert: AlertDetails = {
    header: t('colorPicker.colorAlreadyAdded'),
    description: t('colorPicker.colorAlreadyAdded.description'),
  }

  const maxColorsReachedAlert: AlertDetails = {
    header: t('colorPicker.maxColorsReached'),
    description: t('colorPicker.maxColorsReached.description', { maxColors }),
  }

  if (!colors) {
    return null
  }

  return (
    <View>
      <PaletteSection
        isWideLayout={isWideLayout}
        backButtonWidth={BACK_BTN_WIDTH}
        selectedBasicColor={selectedBasicColor}
        selectedColors={colors}
        rowHeight={colorRowHeight}
        height={paletteHeight}
        numColsSimpleView={numColsSimple}
        numColsAdvancedView={numColsAdvanced}
        width={pickerWidth}
        paddingPx={palettePadding}
        circleDiameter={circleDiameter}
        onAddColor={onAddColor}
        maxColors={maxColors}
        maxColorsReached={maxColorsReached}
        advancedModeOn={advancedModeOn}
        onAlertColorAlreadyAdded={() => setAlert(colorAlreadyAddedAlert)}
        onAlertMaxColorsReached={() => setAlert(maxColorsReachedAlert)}
        basicColorsWithChildren={basicColorsWithChildren}
        setSelectedBasicColor={setSelectedBasicColor}
        pickerLayout={pickerLayout}
      />
      {/* ADVANCED MODE TOGGLE */}
      {showAdvancedToggle && (
        <Row justifyContent={'flex-end'} mt={4}>
          <InputToggle
            label={t('colorPicker.advancedMode')}
            value={advancedModeOn}
            onValueChange={setAdvancedMode}
            isPremiumFeature={true}
          />
        </Row>
      )}
      {/* SELECTED COLORS */}
      <Column
        mt={4}
        width={'100%'}
        borderWidth={LINE_WIDTH}
        bgColor={bgColor}
        borderColor={borderColor}
      >
        <Row p={4} justifyContent={'center'}>
          <SansText>{t('colorPicker.selectedColors')}</SansText>
          {maxColorsReached && (
            <SansText>{` (${numColors}/${maxColors}) `}</SansText>
          )}
        </Row>
        <Box
          flexDirection={'row'}
          flexWrap={'wrap'}
          padding={`${palettePadding}px`}
          width={`${pickerWidth}px`}
        >
          {complexColors.map((color, index) => {
            const isMetal = colorIsShiny(color)
            return (
              <Box
                key={index}
                width={`${colWidthPercent}%`}
                justifyContent={'center'}
                alignItems={'center'}
                height={colorRowHeight}
              >
                <ColorCircle
                  color1={color}
                  onColorClick={(color) => {
                    onRemoveColor(color, colors)
                  }}
                  isShiny={isMetal}
                  diameter={circleDiameter}
                  centreIcon="close"
                />
              </Box>
            )
          })}
          {loading && (
            <Box
              key={'loading'}
              width={`${colWidthPercent}%`}
              justifyContent={'center'}
              alignItems={'center'}
              height={colorRowHeight}
            >
              <Loading sizePx={circleDiameter} />
            </Box>
          )}
        </Box>
      </Column>

      <AlertPopup
        header={alert?.header}
        description={alert?.description}
        isOpen={hasAlert}
        onClose={() => setAlert(undefined)}
        onConfirm={() => setAlert(undefined)}
        confirmLabel={t('common.ok')}
        hideCancel={true}
        hideClose={false}
      />
    </View>
  )
}
