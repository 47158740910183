import { CountedFastTag, FastTag } from '../../../../api/_openapi'
import { TagType } from '../../../../api/frontend-types'
import { i18nKeyFromColorTag } from '../color/color-helpers'
import { ColorTagSubType } from '../color/color-types'

export type TagDisplayBuckets = {
  regularTags: (FastTag & { index?: number })[]
  colorTags: FastTag[]
  symbolTags: FastTag[]
  dreamTypeTags: FastTag[]
  themeTags: FastTag[]
}

// Split an array of tags into regular tags, color tags and symbol tags
export function separateTagsForDisplay(
  tags: FastTag[] | undefined,
): TagDisplayBuckets {
  // Make sure any extra tag types we add
  // don't leak into edit mode unexpectedly
  // Only tags with editable names are 'regular'
  const isRegularTag: { [key in TagType]: boolean } = {
    CHARACTER: true,
    SETTING: true,
    EMOTION: true,
    ACTION: true,
    ANIMAL: true,
    OTHER: true,
    MOON_PHASE: false,
    NONE: false,
    COLOR: false,
    SYMBOL: false,
    DREAM_TYPE: false,
    THEME: false,
  }

  const regularTags: (FastTag & { index?: number })[] = []
  const colorTags: FastTag[] = []
  const symbolTags: FastTag[] = []
  const dreamTypeTags: FastTag[] = []
  const themeTags: FastTag[] = []

  if (!tags) {
    return {
      regularTags,
      colorTags,
      symbolTags,
      dreamTypeTags,
      themeTags,
    }
  }

  tags.forEach((tag, index) => {
    if (tag.type === 'COLOR') {
      if (colorTags.find((ct) => ct.id === tag.id)) {
        return
      } else {
        colorTags.push(tag)
      }
    } else if (tag.type === 'SYMBOL') {
      if (symbolTags.find((st) => st.id === tag.id)) {
        return
      } else {
        symbolTags.push(tag)
      }
    } else if (tag.type === 'DREAM_TYPE') {
      if (symbolTags.find((st) => st.id === tag.id)) {
        return
      } else {
        dreamTypeTags.push(tag)
      }
    } else if (tag.type === 'THEME') {
      if (themeTags.find((st) => st.id === tag.id)) {
        return
      } else {
        themeTags.push(tag)
      }
    } else {
      if (isRegularTag[tag.type]) {
        if (regularTags.find((rt) => rt.id === tag.id)) {
          return
        }
        regularTags.push({ ...tag, index })
      }
    }
  })

  return {
    regularTags,
    colorTags,
    symbolTags,
    dreamTypeTags,
    themeTags,
  }
}

// Turn the name of a tag into an i18n key
export function getTagI18nKey(tag: FastTag | CountedFastTag): string {
  const { type, name, subType } = tag
  switch (type) {
    case 'DREAM_TYPE':
      return `dreamType.${name.toLowerCase().replace(' ', '_')}`
    case 'THEME':
      return name
    case 'COLOR':
      return i18nKeyFromColorTag(name, subType as ColorTagSubType)
    default:
      return name
  }
}

export function getTagLabel(
  tagName: string | undefined,
  tagType: TagType | undefined,
  subType: string | null | undefined,
  t: any,
): string {
  if (!tagName) {
    return t('common.tag_plural', { count: 1 })
  } else if (tagType === 'THEME' || tagType === 'MOON_PHASE') {
    return t(`${tagName}`)
  } else if (tagType === 'SYMBOL') {
    return t(`symbol.${tagName}`)
  } else if (tagType === 'COLOR') {
    const sType = (subType || 'COLOR') as ColorTagSubType
    return t(i18nKeyFromColorTag(tagName, sType))
  } else {
    return tagName
  }
}
