import React, { useState, useMemo, useEffect } from 'react'
import { useDisclose } from 'native-base'
import { TagPill } from './tag-pill'
import { InputAddText } from '../../common/inputs/input-add-text'
import { elsewhereToast } from '../../common/toast/toast'
import { createTag } from '../../../ducks/dream-tag/functions/tag-functions'
import { separateTagsForDisplay } from '../../../modules/tag-helpers/tag-helpers'
import { Defer } from '../../../utilities/Defer'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Keyboard, Dimensions } from 'react-native'
import { useSelector } from '../../../ducks/root-reducer'
import { undecorateId } from '../../../../../api/decorate-ids'
import { TagSectionArea } from './tag-section-area'
import { SymbolGrid } from '../symbols/symbol-grid'
import { FEW } from '../../../i18n/config'
import { MainStackNavigationProp } from '../../../navigation/types'
import { ActionSheetOptionsMenuMulti } from '../../common/action-sheet/action-sheet-options-menu-multi'
import { Row } from '../../common/row/row'
import { Box } from '../../common/box/box'
import { View } from '../../common/view/view'
import { TagColorPicker } from '../colorpicker/tag-color-picker'
import {
  FormFieldOption,
  LocalTag,
  TagType,
} from '../../../../../api/frontend-types'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import {
  selectDreamTypeOptions,
  selectThemeOptions,
} from '../../../ducks/ui/ui'
import {
  FastDream,
  FastTag,
  UserTheme,
  UserThemeService,
} from '../../../../../api/_openapi'

type TagAreaInputProps = {
  tags: FastTag[]
  onChangeTags: (tags: FastTag[]) => void
  editing?: boolean
  dream: FastDream
}

type TagSectionProps = {
  heading: string
  tagsForSection: FastTag[]
  selectOpen: () => void
  mt?: number
  hasButton?: boolean
}

const TagSection = ({
  heading,
  tagsForSection,
  selectOpen,
  hasButton,
}: TagSectionProps) => {
  const hasTags = tagsForSection.length > 0

  return (
    <TagSectionArea
      heading={heading}
      hasButton={hasButton}
      buttonIconKey={tagsForSection.length > 0 ? 'edit' : 'plus'}
      onButtonPress={() => {
        Keyboard.dismiss()
        selectOpen()
      }}
    >
      {hasTags && (
        <Row flexWrap={'wrap'}>
          {tagsForSection.map((tag: FastTag) => {
            return <TagPill tag={tag} tagState={'read-only'} key={tag.id} />
          })}
        </Row>
      )}
    </TagSectionArea>
  )
}

export const TagAreaInput = ({
  tags,
  onChangeTags,
  editing = true,
  dream,
}: TagAreaInputProps) => {
  // STATE
  const [loading, setLoading] = useState(false)
  const [optionLoading, setOptionLoading] = useState<string | null>(null)

  // VARS
  const screenWidth = Math.min(Dimensions.get('window').width, WEB_MAX_WIDTH)

  // HOOKS
  const { t } = useTranslation()

  // Dream type action sheet
  const {
    isOpen: dreamTypeSelectIsOpen,
    onOpen: onOpenDreamTypeSelect,
    onClose: onCloseDreamTypeSelect,
  } = useDisclose()
  // Theme action sheet
  const {
    isOpen: themeSelectIsOpen,
    onOpen: onOpenThemeSelect,
    onClose: onCloseThemeSelect,
  } = useDisclose()

  // MEMOIZED VALUES
  const navigation =
    useNavigation<MainStackNavigationProp<'PrivateDreamView'>>()
  const { regularTags, colorTags, dreamTypeTags, themeTags, symbolTags } =
    useMemo(() => {
      return separateTagsForDisplay(tags || [])
    }, [tags])

  const otherTags = regularTags.map((tag, index) => ({ ...tag, index }))

  // SELECTORS
  const themeOptions = useSelector(selectThemeOptions)

  const [userThemes, setUserThemes] = useState<UserTheme[]>([])
  const userThemeOptions: FormFieldOption[] = userThemes.map((theme) => {
    const actualUserThemeTag = themeTags.find((tag) => tag.name === theme.theme)
    return {
      label: theme.theme || '',
      value: (actualUserThemeTag ? actualUserThemeTag.id : theme.id) || '',
    }
  })

  const DREAM_TYPES = useSelector(selectDreamTypeOptions)

  // TRANSLATIONS
  const DREAM_TYPES_TRANSLATED = DREAM_TYPES.map((option) => ({
    ...option,
    label: t(`dreamType.${option.label.toLowerCase().replace(' ', '_')}`),
  }))
  const THEMES_TRANSLATED = themeOptions
    .map((option) => ({
      ...option,
      label: t(option.label),
    }))
    .concat(userThemeOptions)
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  // EFFECTS
  useEffect(() => {
    UserThemeService.getUserThemes(1, 1000).then((themes) => {
      setUserThemes(themes)
    })
  }, [])

  // FUNCTIONS
  const onAddTag = (newTag: FastTag) => {
    onChangeTags([...tags, newTag])
  }
  const onAddTags = (newTags: FastTag[]) => {
    onChangeTags([...tags, ...newTags])
  }
  const onRemoveTag = (tag: FastTag) => {
    onChangeTags(tags.filter((t) => t.id !== tag.id))
  }
  const onRemoveTags = (tagsToRemove: FastTag[]) => {
    onChangeTags(
      tags.filter((t) => !tagsToRemove.map((t) => t.id).includes(t.id)),
    )
  }

  return (
    <>
      <View width={'100%'}>
        <Box>
          {/* DREAMTYPES */}
          <TagSection
            heading={t('tagType.dreamType_plural', { count: 1 })}
            tagsForSection={dreamTypeTags}
            selectOpen={onOpenDreamTypeSelect}
            hasButton={true}
          />
          {/* THEMES */}
          <TagSection
            heading={t('tagType.theme_plural', { count: FEW })}
            tagsForSection={themeTags}
            selectOpen={onOpenThemeSelect}
            hasButton={true}
          />
          {/* SYMBOLS */}
          {symbolTags.length > 0 && (
            <TagSectionArea
              heading={t('tagType.symbol_plural', { count: FEW })}
            >
              <SymbolGrid
                tags={symbolTags.map((t) => ({
                  ...t,
                  count: 1,
                  sentiment: 0,
                  lastUsedDate: null,
                }))}
                width={screenWidth - PADDING_HORIZONTAL_PIXELS * 2}
                showNumUses={false}
                editable={editing}
                onDeleteSymbol={(tagId: string) => {
                  onChangeTags(tags.filter((t) => t.id !== tagId))
                }}
              />
            </TagSectionArea>
          )}
          {/* COLORS */}
          <TagSectionArea
            heading={t('tagType.color_plural', { count: FEW })}
            paddingBottom={4}
          >
            <TagColorPicker
              tags={colorTags}
              onAddTags={onAddTags}
              onRemoveTags={onRemoveTags}
            />
          </TagSectionArea>
          <TagSectionArea heading={t('common.tag_plural', { count: FEW })}>
            <Row flexWrap={'wrap'}>
              <Defer chunkSize={5}>
                {otherTags.map((tag, index) => {
                  return (
                    <TagPill
                      tag={tag}
                      tagState={editing ? 'editing' : 'passive'}
                      key={`tag-pill-${tag.id}`}
                      onPassiveTagPress={() => {
                        navigation.push('PrivateTagView', {
                          id: tag.id,
                        })
                      }}
                      onDeleteTag={() => {
                        onRemoveTag(tag)
                      }}
                      onChangeTagType={(newType: TagType) => {
                        onChangeTagType(newType, tag, tags, onChangeTags)
                      }}
                      onSaveTagName={(newName: string) => {
                        onSaveTagName(newName, tag, tags, onChangeTags)
                      }}
                      onChangeTagName={(newLabel) => {
                        // We need to update the tag name in the tags array
                        // And set 'unsavedChanges' to true
                        const tempTag: LocalTag = {
                          ...tag,
                          name: newLabel,
                          hasUnsavedChanges: true,
                        }
                        // Replace the old tag with the temp one
                        onChangeTags(
                          tags.map((t) => (t.id === tag.id ? tempTag : t)),
                        )
                      }}
                    />
                  )
                })}
              </Defer>
            </Row>
          </TagSectionArea>
        </Box>
        {editing && (
          <InputAddText
            loading={loading}
            placeholder={t('common.tag_plural', { count: 1 })}
            blurOnSubmit={false}
            onSubmit={(text) => {
              if (!text) {
                elsewhereToast.showToast({
                  description: 'toast.tagIsEmptyWarning.description',
                  status: 'warning',
                })
              } else if (findTagByText(tags, text)) {
                elsewhereToast.showToast({
                  description: 'toast.tagExistsWarning.description',
                  descriptionParam: text,
                  status: 'warning',
                })
              } else {
                setLoading(true)
                createTag({ name: text }, dream.languageCode)
                  .then((tag) => {
                    if (tag) {
                      onAddTag(tag)
                    }
                    setLoading(false)
                  })
                  .catch(() => {
                    console.log('Error creating tag')
                    setLoading(false)
                  })
              }
            }}
          />
        )}
      </View>
      {dreamTypeSelectIsOpen && (
        <ActionSheetOptionsMenuMulti
          options={DREAM_TYPES_TRANSLATED}
          isOpen={dreamTypeSelectIsOpen}
          onClose={onCloseDreamTypeSelect}
          selectedOptions={dreamTypeTags.map((tag) => tag.id)}
          onOptionSelect={async (option) => {
            setOptionLoading(option.value)
            if (dreamTypeTags.map((tag) => tag.id).includes(option.value)) {
              onChangeTags(tags.filter((t) => t.id !== option.value))
              setOptionLoading(null)
            } else {
              const tag = DREAM_TYPES.find((t) => t.value === option.value)
              if (tag) {
                await createTag({ name: tag.label, type: 'DREAM_TYPE' }, 'en')
                  .then((tag) => {
                    if (tag) {
                      onAddTag(tag)
                    }
                    setOptionLoading(null)
                  })
                  .catch(() => {
                    console.log('Error creating tag')
                    setOptionLoading(null)
                  })
              }
            }
          }}
          optionLoading={optionLoading}
          heading={t('tagType.dreamType_plural', { count: 1 })}
        />
      )}
      {themeSelectIsOpen && (
        <ActionSheetOptionsMenuMulti
          allowCreation={true}
          searchable={true}
          options={THEMES_TRANSLATED}
          isOpen={themeSelectIsOpen}
          onClose={onCloseThemeSelect}
          selectedOptions={themeTags.map((tag) => tag.id)}
          onOptionSelect={async (option) => {
            setOptionLoading(option.value)
            if (themeTags.map((tag) => tag.id).includes(option.value)) {
              onChangeTags(tags.filter((t) => t.id !== option.value))
              setOptionLoading(null)
            } else {
              const tag = themeOptions
                .concat(userThemeOptions)
                .find((t) => t.value === option.value)
              if (tag || option.creating) {
                const generateTag = async (tagName: string) => {
                  await createTag(
                    { name: tagName, type: 'THEME' },
                    undecorateId(dream.languageCode) || 'en',
                  )
                    .then((createdTag) => {
                      if (createdTag) {
                        onAddTag(createdTag)
                      }
                      setOptionLoading(null)
                    })
                    .catch(() => {
                      console.log('Error creating tag')
                      setOptionLoading(null)
                    })
                }

                if (option.creating) {
                  UserThemeService.createUserTheme({
                    theme: option.value,
                    languageCode: undecorateId(dream.languageCode),
                  }).then((userTheme) => {
                    if (
                      userTheme &&
                      userThemes.map((t) => t.id).includes(userTheme.id) ===
                        false
                    ) {
                      setUserThemes([...userThemes, userTheme])
                      if (userTheme.theme) {
                        generateTag(userTheme.theme)
                      }
                    }
                  })
                } else if (tag?.label) {
                  generateTag(tag?.label)
                }
              } else {
                setOptionLoading(null)
              }
            }
          }}
          optionLoading={optionLoading}
          heading={t('tagType.theme_plural', { count: FEW })}
        />
      )}
    </>
  )
}

// Helper functions
function findTagByText(tags: FastTag[], text: string): FastTag | undefined {
  return tags.find((t) => t.name === text)
}

function onChangeTagType(
  newType: TagType,
  tag: FastTag,
  tags: FastTag[],
  onChangeTags: (tags: FastTag[]) => void,
) {
  const tempNewTag = {
    name: tag.name,
    type: newType,
    languageCode: tag.languageCode,
  }
  // Create a new tag with the new type
  createTag(tempNewTag, tag.languageCode)
    .then((newTag) => {
      const exists = newTag && tags.find((t) => t.id === newTag.id)
      if (newTag && !exists) {
        // Replace the old tag with the new one
        onChangeTags(tags.map((t) => (t.id === tag.id ? newTag : t)))
      } else {
        elsewhereToast.showToast({
          description: 'toast.tagExistsWarning.description',
          descriptionParam: newTag?.name,
          status: 'warning',
        })
      }
    })
    .catch(() => {
      console.log('Error creating tag')
    })
}

function onSaveTagName(
  newName: string,
  tagToUpdate: FastTag,
  tags: FastTag[],
  onChangeTags: (tags: FastTag[]) => void,
) {
  const newTag = {
    name: newName,
    type: tagToUpdate.type,
    languageCode: tagToUpdate.languageCode,
  }
  // Create a new tag with the new name
  createTag(newTag, tagToUpdate.languageCode).then((tag) => {
    // Check if the new tag already exists in this set of tags
    const exists = tag && tags.find((t) => t.id === tag.id)

    // Replace the old tag with the new one, if that tag doesn't already exist
    if (tag && !exists) {
      const newTags: FastTag[] = tags.map((t) => (t.id === tag.id ? tag : t))
      onChangeTags(newTags)
    }
    // If the user is changing an existing tag into a new tag that already exists
    if (tag && exists && tag.id !== tagToUpdate.id) {
      elsewhereToast.showToast({
        description: 'toast.tagExistsWarning.description',
        descriptionParam: tag?.name,
        status: 'warning',
      })
    }
  })
}
