import React from 'react'
import {
  LINE_WIDTH,
  SANS_BOOK_FONT_WEIGHT,
  SMALL_FONT_SIZE,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import { TabViewProps } from './tab-view'
import { useColorModeValue } from 'native-base'
import { ScrollView } from 'react-native'
import {
  BLACK,
  DARK_GRAY,
  MID_GRAY,
  LIGHT_GRAY,
  WHITE,
} from '../../constants/ui-constants'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'
import i18n from '../../i18n/i18nnext'
import { getSansFont } from '../../modules/language-helpers/language-helpers'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      style={{ display: value === index ? 'flex' : 'none', height: '100%' }}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}

interface StyledTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  color: string
  backgroundColor: string
  borderBottomColor: string
}

const StyledTabs = styled((props: StyledTabsProps) => {
  const { color, backgroundColor, borderBottomColor, value, ...rest } = props
  return (
    <Tabs
      {...rest}
      value={value}
      sx={{
        backgroundColor,
      }}
      variant="fullWidth"
      TabIndicatorProps={{
        children: (
          <span
            className="MuiTabs-indicatorSpan"
            style={{
              backgroundColor: color,
            }}
          />
        ),
      }}
    />
  )
})({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
  },
})

interface StyledTabProps {
  label: string
  sx: any
}

export const TabView = ({
  tabs = [],
  scenes,
  header,
  hideTabHeader = false,
  initialTabName,
  onChangeTab,
}: TabViewProps) => {
  const [routes, setRoutes] = React.useState<{ key: string; title: string }[]>(
    tabs?.map((t) => ({
      key: t.name as string,
      title: t.label as string,
    })),
  )

  const initialIndex =
    (initialTabName && routes.map((r) => r.key).indexOf(initialTabName)) || 0

  // update routes if tabs change (eg after lang change)
  React.useEffect(() => {
    setRoutes(
      tabs?.map((t) => ({
        key: t.name as string,
        title: t.label as string,
      })),
    )
  }, [tabs])

  const [index, setIndex] = React.useState(initialIndex)

  const color = useColorModeValue(BLACK, WHITE)
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const disabledColor = useColorModeValue(MID_GRAY, DARK_GRAY)
  const borderBottomColor = useColorModeValue(LIGHT_GRAY, MID_GRAY)

  const StyledTab = styled((props: StyledTabProps) => {
    const { sx, ...rest } = props
    return <Tab disableRipple {...rest} sx={sx} />
  })(() => ({
    flex: 1,
    alignItems: 'center',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color,
    },
  }))

  const renderTabBar = (props: any) => {
    return props.navigationState.routes.map((route: any, i: number) => {
      const isSelected = props.navigationState.index === i
      const { borderBottomColor } = props

      const language = i18n.resolvedLanguage || 'en'
      const fontFamily = getSansFont(language)

      return (
        <StyledTab
          key={`tab-${i}`}
          label={route.title}
          {...a11yProps(0)}
          sx={{
            paddingHorizontal: isSelected ? 0 : 3,
            textAlign: 'center',
            fontFamily: fontFamily,
            fontWeight: SANS_BOOK_FONT_WEIGHT,
            fontSize: SMALL_FONT_SIZE,
            textTransform: 'uppercase',
            color: isSelected ? color : disabledColor,
            borderBottomWidth: LINE_WIDTH,
            borderBottomStyle: 'solid',
            borderBottomColor,
          }}
        />
      )
    })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTab && onChangeTab(newValue)
    setIndex(newValue)
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <ScrollView
      contentContainerStyle={{
        width: '100%',
        marginHorizontal: 'auto',
        maxWidth: WEB_MAX_WIDTH,
        flexGrow: 1,
      }}
      stickyHeaderIndices={[1]}
      // @ts-ignore
      dataSet={{ scrollbarTabsScrollViewAdjust: true }}
    >
      {header && header()}
      {hideTabHeader ? null : (
        <StyledTabs
          value={index}
          onChange={handleChange}
          color={color}
          backgroundColor={backgroundColor}
          borderBottomColor={borderBottomColor}
        >
          {renderTabBar({
            navigationState: {
              index,
              routes,
            },
          })}
        </StyledTabs>
      )}
      {routes.map((route, i) => {
        return (
          <TabPanel key={i} value={index} index={i}>
            {scenes[route.key]()}
          </TabPanel>
        )
      })}
    </ScrollView>
  )
}
