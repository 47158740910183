import React from 'react'
import { PressableFixed } from './PressableFixed'
import { useLinkProps } from './useLinkPropsFixed'
import { StyleProp, TextStyle, Platform } from 'react-native'
import { StackActions } from '@react-navigation/native'
import { RootStackParamList } from '../navigation/types'
import { linking } from '../navigation/linking'

type ConditionalLinkProps = {
  to?: {
    screen: keyof RootStackParamList
    params?: any
  }
  children: React.ReactNode
  condition: boolean
  style?: StyleProp<TextStyle>
}

export default function ConditionalLink({
  to,
  children,
  condition,
  style,
}: ConditionalLinkProps) {
  const conditionPasses = Boolean(condition)

  if (!conditionPasses || !to) {
    return <>{children}</>
  } else
    return (
      <ConditionalLinkInner
        to={to}
        children={children}
        condition={condition}
        style={style}
      />
    )
}

function ConditionalLinkInner({
  to,
  children,
  condition,
  style,
}: {
  to: {
    screen: keyof RootStackParamList
    params?: any
  }
  children: React.ReactNode
  condition: boolean
  style?: StyleProp<TextStyle>
}) {
  type RootStackScreen = keyof RootStackParamList
  const isRootStackScreen = (screen: any): screen is RootStackScreen => {
    return (
      screen !== 'MainTabs' &&
      Object.keys(linking?.config?.screens || {}).includes(screen)
    )
  }

  const screen = to?.screen
  to =
    !isRootStackScreen(screen) && Platform.OS === 'web'
      ? {
          screen: 'MainTabs',
          params: {
            screen: 'MainStack',
            params: {
              screen: screen,
              params: to?.params,
            },
          },
        }
      : to

  const linkProps = useLinkProps({
    to,
    action: StackActions.push(to?.screen, to?.params),
  })

  return !!condition && to ? (
    <PressableFixed
      toScreen={screen}
      {...linkProps}
      dataSet={{ webLink: true }}
      style={style}
    >
      {children}
    </PressableFixed>
  ) : (
    <>{children}</>
  )
}
