import * as React from 'react'
import {
  SELECTION_COLOR,
  SMALL_LINE_HEIGHT,
} from '../../../constants/constants'
import { useUserDate } from '../../../hooks/useUserDate'
import { SansText } from './text-sans'
import { AstrologicalData } from '../../composite/astrological/astrological-data'
import { Row } from '../row/row'

type DisplayDateProps = {
  date: string
  displayDate?: string | null
  mt?: number
  mb?: number
  includeLinkToMoonPhase?: boolean
}

// Probably worth making this a component
// Since dates and date formatting are a pain
// Only simple formatting for now
export const DisplayDate = ({
  date,
  displayDate,
  mt,
  mb,
  includeLinkToMoonPhase = false,
}: DisplayDateProps) => {
  const { dateString } = useUserDate(date)
  const dateToShow = displayDate || dateString
  return (
    <Row justifyContent={'center'} space={2} alignItems={'center'}>
      <SansText
        mt={mt || 0}
        mb={mb || 0}
        fontSize={'sm'}
        style={{ textAlign: 'center' }}
        selectionColor={SELECTION_COLOR}
      >
        {dateToShow}
      </SansText>
      {date && (
        <AstrologicalData
          date={date as string}
          size={SMALL_LINE_HEIGHT}
          includeLinkToMoonPhase={includeLinkToMoonPhase}
        />
      )}
    </Row>
  )
}
