// Ideal simple colours for reference:
// pink	hsl(351, 77%, 73%)
// red 	hsl(3, 67%, 47%)
// orange	hsl(24, 71%, 55%)
// brown	hsl(22, 20%, 34%)
// yellow:	hsl(53, 88%, 71%)
// green 	hsl(83, 30%, 43%)
// blue	hsl(199, 49%, 59%)
// purple	hsl(286, 32%, 38%)
// grey	hsl(42, 11%, 65%)
// black hsl(40, 4%, 16%)
// white 	hsl(60, 36%, 96%)
// metallic	hsl(42, 65%, 59%)

export const h = {
  MAGENTA: 300,
  DESAT_MAGENTA: 310,
  PINK: 343,
  DESAT_PINK: 345,
  RED: 2,
  DESAT_RED: 355,
  ORANGE_RED: 13,
  DESAT_ORANGE_RED: 15,
  ORANGE: 25,
  DESAT_ORANGE: 24,
  GOLD_YELLOW: 43,
  DESAT_GOLDEN_YELLOW: 43,
  YELLOW: 53,
  DESAT_YELLOW: 53,
  YELLOW_GREEN: 70,
  DESAT_YELLOW_GREEN: 83,
  GREEN: 115,
  DESAT_GREEN: 120,
  SPRING_GREEN: 153,
  DESAT_SPRING_GREEN: 153,
  TURQUOISE: 174,
  DESAT_TURQUOISE: 180,
  SKY_BLUE: 200,
  DESAT_SKY_BLUE: 200,
  BLUE: 230,
  DESAT_BLUE: 230,
  BLUE_PURPLE: 255,
  DESAT_BLUE_PURPLE: 245,
  PURPLE: 270,
  DESAT_PURPLE: 285,
  BLACK: 40,
  GRAY: 42,
  WHITE: 60,
  CREAM: 48,
  GOLD: 42,
  SILVER: 255,
  BRONZE: 25,
  CLEAR: 205,
}

export const s = {
  MAGENTA: 80,
  DESAT_MAGENTA: 40,
  PINK: 77,
  DESAT_PINK: 33,
  RED: 67,
  DESAT_RED: 45,
  ORANGE_RED: 78,
  DESAT_ORANGE_RED: 40,
  ORANGE: 75,
  DESAT_ORANGE: 20,
  GOLD_YELLOW: 90,
  DESAT_GOLDEN_YELLOW: 30,
  YELLOW: 87,
  DESATURATED_YELLOW: 48,
  YELLOW_GREEN: 90,
  DESAT_YELLOW_GREEN: 30,
  GREEN: 72,
  DESAT_GREEN: 35,
  SPRING_GREEN: 80,
  DESAT_SPRING_GREEN: 35,
  TURQUOISE: 90,
  DESAT_TURQUOISE: 40,
  SKY_BLUE: 60,
  DESAT_SKY_BLUE: 20,
  BLUE: 65,
  DESAT_BLUE: 32,
  BLUE_PURPLE: 80,
  DESAT_BLUE_PURPLE: 30,
  PURPLE: 80,
  DESAT_PURPLE: 32,
  BLACK: 4,
  GRAY: 5,
  WHITE: 30,
  CREAM: 65,
  GOLD: 64,
  SILVER: 3,
  BRONZE: 69,
  CLEAR: 30,
}

export const l = {
  // Regular
  LIGHT: 71,
  MID: 53,
  DARK: 38,

  LIGHTER_LIGHT: 79,
  LIGHTER_MID: 55,
  LIGHTER_DARK: 55,

  // Darker
  DARKER_LIGHT: 66,
  DARKER_MID: 47,
  DARKER_DARK: 32,

  // Darkest
  DARKEST_LIGHT: 62,
  DARKEST_MID: 45,
  DARKEST_DARK: 31,

  // Desaturated regular
  DESAT_LIGHT: 64,
  DESAT_MID: 50,
  DESAT_DARK: 36,

  // Custom values
  BLACK: 10,
  WHITE: 96,
  CREAM: 85,
  GOLD: 55,
  SILVER: 67,
  BRONZE: 45,
  CLEAR: 95,
}
