import React from 'react'
import { Platform, BackHandler, Linking } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AlertPopup } from './alert-dialog'
import { cap } from '../../../modules/strings/string-helpers'

type AlertPremiumFeatureProps = {
  isOpen: boolean
  onClose: () => void
}

export const AlertPremiumFeature = ({
  isOpen,
  onClose,
}: AlertPremiumFeatureProps) => {
  const { t } = useTranslation()

  return (
    <AlertPopup
      header={cap(t('common.premiumFeature'))}
      description={t('plz upgrade to premium to use this feature')}
      hideCancel={true}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        console.log('Upgrade to premium')
      }}
      confirmLabel={t('dialog.pleaseUpdate.buttonLabel')}
      hideClose={false}
    />
  )
}
