import { ColorDefinitionMap } from './color-types'
import { h, s, l } from './colorValues'

export const colorMap: ColorDefinitionMap = {
  // MAGENTA
  magentaPink: {
    name: 'magentaPink',
    colorTemperature: 'warm',
    hsl: [h.MAGENTA, s.MAGENTA, l.LIGHT],
    hslType: ['magenta', 'saturated', 'light'],
    associations: ['pink', 'purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  magenta: {
    name: 'magenta',
    colorTemperature: 'warm',
    hsl: [h.MAGENTA, s.MAGENTA, l.MID],
    hslType: ['magenta', 'saturated', 'mid'],
    associations: ['pink', 'purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkMagenta: {
    name: 'darkMagenta',
    colorTemperature: 'warm',
    hsl: [h.MAGENTA, s.MAGENTA, l.DARKER_DARK],
    hslType: ['magenta', 'saturated', 'dark'],
    associations: ['pink', 'purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED MAGENTA
  duskyMagentaPink: {
    name: 'duskyMagentaPink',
    colorTemperature: 'warm',
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_LIGHT],
    hslType: ['magenta', 'desaturated', 'light'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  duskyMagenta: {
    name: 'duskyMagenta',
    colorTemperature: 'warm',
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_MID],
    hslType: ['magenta', 'desaturated', 'mid'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  duskyDarkMagenta: {
    name: 'duskyDarkMagenta',
    colorTemperature: 'warm',
    hsl: [h.DESAT_MAGENTA, s.DESAT_MAGENTA, l.DESAT_DARK],
    hslType: ['magenta', 'desaturated', 'dark'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // PINK
  pink: {
    name: 'pink',
    colorTemperature: 'warm',
    associations: ['pink', 'red'],
    hsl: [h.PINK, s.PINK, l.LIGHT],
    hslType: ['pink', 'saturated', 'light'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'pink',
      simpleOrder: 0,
      showInSimpleModeForLayout: [],
    },
  },
  midPink: {
    name: 'midPink',
    colorTemperature: 'warm',
    hsl: [h.PINK, s.PINK, l.MID],
    associations: ['pink', 'red'],
    hslType: ['pink', 'saturated', 'mid'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkPink: {
    name: 'darkPink',
    colorTemperature: 'warm',
    hsl: [h.PINK, s.PINK, l.DARK],
    hslType: ['pink', 'saturated', 'dark'],
    associations: ['pink', 'red', 'purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED PINK
  purplePink: {
    name: 'purplePink',
    colorTemperature: 'warm',
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_LIGHT],
    hslType: ['pink', 'desaturated', 'light'],
    associations: ['purple', 'pink'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  midPurplePink: {
    name: 'midPurplePink',
    colorTemperature: 'warm',
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_MID],
    hslType: ['pink', 'desaturated', 'mid'],
    associations: ['purple', 'pink'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkPurplePink: {
    name: 'darkPurplePink',
    colorTemperature: 'warm',
    hsl: [h.DESAT_PINK, s.DESAT_PINK, l.DESAT_DARK],
    hslType: ['pink', 'desaturated', 'dark'],
    associations: ['purple', 'pink'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // RED
  salmon: {
    name: 'salmon',
    colorTemperature: 'warm',
    hsl: [h.RED, s.RED, l.LIGHT],
    hslType: ['red', 'saturated', 'light'],
    associations: ['red', 'pink'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  red: {
    name: 'red',
    colorTemperature: 'warm',
    hsl: [h.RED, s.RED, l.DARKER_MID],
    hslType: ['red', 'saturated', 'mid'],
    associations: ['red'],
    simpleOpts: {
      isSimpleColor: true,
      simpleOrder: 1,
      id: 'red',
      showInSimpleModeForLayout: [],
    },
  },
  darkRed: {
    name: 'darkRed',
    colorTemperature: 'warm',
    hsl: [h.RED, s.RED, l.DARK],
    hslType: ['red', 'saturated', 'dark'],
    associations: ['red'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // RED-BROWN (Desaturated red)
  duskySalmon: {
    name: 'duskySalmon',
    colorTemperature: 'warm',
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_LIGHT],
    hslType: ['red', 'desaturated', 'light'],
    associations: ['brown', 'pink', 'red'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  redBrown: {
    name: 'redBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_MID],
    hslType: ['red', 'desaturated', 'mid'],
    associations: ['brown', 'red'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkRedBrown: {
    name: 'darkRedBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_RED, s.DESAT_RED, l.DESAT_DARK],
    hslType: ['red', 'desaturated', 'dark'],
    associations: ['brown', 'red'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // ORANGE-RED
  apricot: {
    name: 'apricot',
    colorTemperature: 'warm',
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.LIGHT],
    hslType: ['orangeRed', 'saturated', 'light'],
    associations: ['orange', 'red', 'pink'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  orangeRed: {
    name: 'orangeRed',
    colorTemperature: 'warm',
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.MID],
    hslType: ['orangeRed', 'saturated', 'mid'],
    associations: ['orange', 'red'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkOrangeRed: {
    name: 'darkOrangeRed',
    colorTemperature: 'warm',
    hsl: [h.ORANGE_RED, s.ORANGE_RED, l.DARK],
    hslType: ['orangeRed', 'saturated', 'dark'],
    associations: ['orange', 'red', 'brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // CHOCOLATE BROWN
  lightChocolateBrown: {
    name: 'lightChocolateBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_LIGHT],
    hslType: ['orangeRed', 'desaturated', 'light'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  midChocolateBrown: {
    name: 'midChocolateBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_MID],
    hslType: ['orangeRed', 'desaturated', 'mid'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  chocolateBrown: {
    name: 'chocolateBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE_RED, s.DESAT_ORANGE_RED, l.DESAT_DARK],
    hslType: ['orangeRed', 'desaturated', 'dark'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // ORANGE
  lightOrange: {
    name: 'lightOrange',
    colorTemperature: 'warm',
    hsl: [h.ORANGE, s.ORANGE, l.LIGHT],
    hslType: ['orange', 'saturated', 'light'],
    associations: ['orange'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  orange: {
    name: 'orange',
    colorTemperature: 'warm',
    hsl: [h.ORANGE, s.ORANGE, l.LIGHTER_MID],
    hslType: ['orange', 'saturated', 'mid'],
    associations: ['orange'],
    simpleOpts: {
      id: 'orange',
      isSimpleColor: true,
      simpleOrder: 2,
      showInSimpleModeForLayout: [],
    },
  },
  darkOrange: {
    name: 'darkOrange',
    colorTemperature: 'warm',
    hsl: [h.ORANGE, s.ORANGE, l.DARK],
    hslType: ['orange', 'saturated', 'dark'],
    associations: ['orange', 'brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // BROWN (Desaturated orange)
  lightBrown: {
    name: 'lightBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_LIGHT],
    hslType: ['orange', 'desaturated', 'light'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  midBrown: {
    name: 'midBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_MID],
    hslType: ['orange', 'desaturated', 'mid'],
    associations: ['brown'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: [],
    },
  },
  brown: {
    name: 'brown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_ORANGE, s.DESAT_ORANGE, l.DESAT_DARK],
    hslType: ['orange', 'desaturated', 'dark'],
    associations: ['brown'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'brown',
      simpleOrder: 3,
      showInSimpleModeForLayout: [],
    },
  },
  // GOLDEN YELLOW
  lightGoldenYellow: {
    name: 'lightGoldenYellow',
    colorTemperature: 'warm',
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.LIGHT],
    hslType: ['goldenYellow', 'saturated', 'light'],
    associations: ['yellow', 'orange'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  goldenYellow: {
    name: 'goldenYellow',
    colorTemperature: 'warm',
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.MID],
    hslType: ['goldenYellow', 'saturated', 'mid'],
    associations: ['yellow', 'orange'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkGoldenYellow: {
    name: 'darkGoldenYellow',
    colorTemperature: 'warm',
    hsl: [h.GOLD_YELLOW, s.GOLD_YELLOW, l.DARK],
    hslType: ['goldenYellow', 'saturated', 'dark'],
    associations: ['yellow', 'orange', 'brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // GOLDEN BROWN
  lightGoldenBrown: {
    name: 'lightGoldenBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.DESAT_LIGHT],
    hslType: ['goldenYellow', 'desaturated', 'light'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  goldenBrown: {
    name: 'goldenBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.LIGHTER_MID],
    hslType: ['goldenYellow', 'desaturated', 'mid'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkGoldenBrown: {
    name: 'darkGoldenBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_GOLDEN_YELLOW, s.DESAT_GOLDEN_YELLOW, l.DESAT_DARK],
    hslType: ['goldenYellow', 'desaturated', 'dark'],
    associations: ['brown'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // YELLOW
  yellow: {
    name: 'yellow',
    colorTemperature: 'warm',
    hsl: [h.YELLOW, s.YELLOW, l.LIGHT],
    hslType: ['yellow', 'saturated', 'light'],
    associations: ['yellow'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'yellow',
      simpleOrder: 4,
      showInSimpleModeForLayout: [],
    },
  },
  midYellow: {
    name: 'midYellow',
    colorTemperature: 'warm',
    hsl: [h.YELLOW, s.YELLOW, l.MID],
    hslType: ['yellow', 'saturated', 'mid'],
    associations: ['yellow'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: [],
    },
  },
  darkYellow: {
    name: 'darkYellow',
    colorTemperature: 'warm',
    hsl: [h.YELLOW, s.YELLOW, l.DARK],
    hslType: ['yellow', 'saturated', 'dark'],
    associations: ['yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // YELLOW-BROWN
  lightYellowBrown: {
    name: 'lightYellowBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_LIGHT],
    hslType: ['yellow', 'desaturated', 'light'],
    associations: ['brown', 'yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  yellowBrown: {
    name: 'yellowBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_MID],
    hslType: ['yellow', 'desaturated', 'mid'],
    associations: ['brown', 'yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkYellowBrown: {
    name: 'darkYellowBrown',
    colorTemperature: 'warm',
    hsl: [h.DESAT_YELLOW, s.DESATURATED_YELLOW, l.DESAT_DARK],
    hslType: ['yellow', 'desaturated', 'dark'],
    associations: ['brown', 'yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // YELLOW-GREEN
  lightYellowGreen: {
    name: 'lightYellowGreen',
    colorTemperature: 'cool',
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_LIGHT],
    hslType: ['yellowGreen', 'saturated', 'light'],
    associations: ['green', 'yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  yellowGreen: {
    name: 'yellowGreen',
    colorTemperature: 'cool',
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_MID],
    hslType: ['yellowGreen', 'saturated', 'mid'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkYellowGreen: {
    name: 'darkYellowGreen',
    colorTemperature: 'cool',
    hsl: [h.YELLOW_GREEN, s.YELLOW_GREEN, l.DARKEST_DARK],
    hslType: ['yellowGreen', 'saturated', 'dark'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // OLIVE GREEN
  lightOliveGreen: {
    name: 'lightOliveGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DESAT_LIGHT],
    hslType: ['yellowGreen', 'desaturated', 'light'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  green: {
    name: 'green',
    colorTemperature: 'cool',
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DARKEST_MID],
    hslType: ['yellowGreen', 'desaturated', 'mid'],
    associations: ['green'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'green',
      simpleOrder: 5,
      showInSimpleModeForLayout: [],
    },
  },
  darkOliveGreen: {
    name: 'darkOliveGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_YELLOW_GREEN, s.DESAT_YELLOW_GREEN, l.DESAT_DARK],
    hslType: ['yellowGreen', 'desaturated', 'dark'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // LIME GREEN
  lightLimeGreen: {
    name: 'lightLimeGreen',
    colorTemperature: 'cool',
    hsl: [h.GREEN, s.GREEN, l.LIGHT],
    hslType: ['green', 'saturated', 'light'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  limeGreen: {
    name: 'limeGreen',
    colorTemperature: 'cool',
    hsl: [h.GREEN, s.GREEN, l.MID],
    hslType: ['green', 'saturated', 'mid'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkLimeGreen: {
    name: 'darkLimeGreen',
    colorTemperature: 'cool',
    hsl: [h.GREEN, s.GREEN, l.DARKER_DARK],
    hslType: ['green', 'saturated', 'dark'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED LIME GREEN
  lightForestGreen: {
    name: 'lightForestGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_LIGHT],
    hslType: ['green', 'desaturated', 'light'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  forestGreen: {
    name: 'forestGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_MID],
    hslType: ['green', 'desaturated', 'mid'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkForestGreen: {
    name: 'darkForestGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_GREEN, s.DESAT_GREEN, l.DESAT_DARK],
    hslType: ['green', 'desaturated', 'dark'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // SPRING GREEN
  lightSpringGreen: {
    name: 'lightSpringGreen',
    colorTemperature: 'cool',
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.LIGHT],
    hslType: ['springGreen', 'saturated', 'light'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  springGreen: {
    name: 'springGreen',
    colorTemperature: 'cool',
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.MID],
    hslType: ['springGreen', 'saturated', 'mid'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkSpringGreen: {
    name: 'darkSpringGreen',
    colorTemperature: 'cool',
    hsl: [h.SPRING_GREEN, s.SPRING_GREEN, l.DARK],
    hslType: ['springGreen', 'saturated', 'dark'],
    associations: ['green'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: [],
    },
  },
  // DESATURATED SPRING GREEN
  lightSeaGreen: {
    name: 'lightSeaGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_LIGHT],
    hslType: ['springGreen', 'desaturated', 'light'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  seaGreen: {
    name: 'seaGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_MID],
    hslType: ['springGreen', 'desaturated', 'mid'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkSeaGreen: {
    name: 'darkSeaGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SPRING_GREEN, s.DESAT_SPRING_GREEN, l.DESAT_DARK],
    hslType: ['springGreen', 'desaturated', 'dark'],
    associations: ['green'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // TURQUOISE
  lightTurquoise: {
    name: 'lightTurquoise',
    colorTemperature: 'cool',
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_LIGHT],
    hslType: ['turquoise', 'saturated', 'light'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  turquoise: {
    name: 'turquoise',
    colorTemperature: 'cool',
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_MID],
    hslType: ['turquoise', 'saturated', 'mid'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  teal: {
    name: 'teal',
    colorTemperature: 'cool',
    hsl: [h.TURQUOISE, s.TURQUOISE, l.DARKEST_DARK],
    hslType: ['turquoise', 'saturated', 'dark'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED TURQUOISE
  lightBlueGreen: {
    name: 'lightBlueGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_LIGHT],
    hslType: ['turquoise', 'desaturated', 'light'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  blueGreen: {
    name: 'blueGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_MID],
    hslType: ['turquoise', 'desaturated', 'mid'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkBlueGreen: {
    name: 'darkBlueGreen',
    colorTemperature: 'cool',
    hsl: [h.DESAT_TURQUOISE, s.DESAT_TURQUOISE, l.DESAT_DARK],
    hslType: ['turquoise', 'desaturated', 'dark'],
    associations: ['green', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // SKY BLUE / AZURE
  lightBlue: {
    name: 'lightBlue',
    colorTemperature: 'cool',
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.LIGHT],
    hslType: ['skyBlue', 'saturated', 'light'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  blue: {
    name: 'blue',
    colorTemperature: 'cool',
    associations: ['blue'],
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.MID],
    hslType: ['skyBlue', 'saturated', 'mid'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'blue',
      simpleOrder: 6,
      showInSimpleModeForLayout: ['blue-distinct'],
    },
  },
  prussianBlue: {
    name: 'prussianBlue',
    colorTemperature: 'cool',
    hsl: [h.SKY_BLUE, s.SKY_BLUE, l.DARKER_DARK],
    hslType: ['skyBlue', 'saturated', 'dark'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED SKY BLUE
  lightBlueGray: {
    name: 'lightBlueGray',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_LIGHT],
    hslType: ['skyBlue', 'desaturated', 'light'],
    associations: ['blue', 'gray'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  blueGray: {
    name: 'blueGray',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_MID],
    hslType: ['skyBlue', 'desaturated', 'mid'],
    associations: ['blue', 'gray'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkBlueGray: {
    name: 'darkBlueGray',
    colorTemperature: 'cool',
    hsl: [h.DESAT_SKY_BLUE, s.DESAT_SKY_BLUE, l.DESAT_DARK],
    hslType: ['skyBlue', 'desaturated', 'dark'],
    associations: ['blue', 'gray'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // ROYAL BLUE
  cornflowerBlue: {
    name: 'cornflowerBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE, s.BLUE, l.LIGHT],
    hslType: ['blue', 'saturated', 'light'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  royalBlue: {
    name: 'royalBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE, s.BLUE, l.MID],
    hslType: ['blue', 'saturated', 'mid'],
    associations: ['blue'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: ['blue-distinct'],
    },
  },
  darkRoyalBlue: {
    name: 'darkRoyalBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE, s.BLUE, l.DARK],
    hslType: ['blue', 'saturated', 'dark'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURATED ROYAL BLUE
  lightSlateBlue: {
    name: 'lightSlateBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DESAT_LIGHT],
    hslType: ['blue', 'desaturated', 'light'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  slateBlue: {
    name: 'slateBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DARKER_MID],
    hslType: ['blue', 'desaturated', 'mid'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkSlateBlue: {
    name: 'darkSlateBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE, s.DESAT_BLUE, l.DARKEST_DARK],
    hslType: ['blue', 'desaturated', 'dark'],
    associations: ['blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // BLUE_PURPLE
  lightPurpleBlue: {
    name: 'lightPurpleBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.LIGHT],
    hslType: ['bluePurple', 'saturated', 'light'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  purpleBlue: {
    name: 'purpleBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.MID],
    hslType: ['bluePurple', 'saturated', 'mid'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkPurpleBlue: {
    name: 'darkPurpleBlue',
    colorTemperature: 'cool',
    hsl: [h.BLUE_PURPLE, s.BLUE_PURPLE, l.DARK],
    hslType: ['bluePurple', 'saturated', 'dark'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESATURED PURPLE BLUE
  lightDuskyPurpleBlue: {
    name: 'lightDuskyPurpleBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_LIGHT],
    hslType: ['bluePurple', 'desaturated', 'light'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  duskyPurpleBlue: {
    name: 'duskyPurpleBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_MID],
    hslType: ['bluePurple', 'desaturated', 'mid'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  darkDuskyPurpleBlue: {
    name: 'darkDuskyPurpleBlue',
    colorTemperature: 'cool',
    hsl: [h.DESAT_BLUE_PURPLE, s.DESAT_BLUE_PURPLE, l.DESAT_DARK],
    hslType: ['bluePurple', 'desaturated', 'dark'],
    associations: ['purple', 'blue'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // PURPLE
  lightPurple: {
    name: 'lightPurple',
    colorTemperature: 'cool',
    hsl: [h.PURPLE, s.PURPLE, l.LIGHT],
    hslType: ['purple', 'saturated', 'light'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  midPurple: {
    name: 'midPurple',
    colorTemperature: 'cool',
    associations: ['purple'],
    hsl: [h.PURPLE, s.PURPLE, l.MID],
    hslType: ['purple', 'saturated', 'mid'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: [],
    },
  },
  darkPurple: {
    name: 'darkPurple',
    colorTemperature: 'cool',
    hsl: [h.PURPLE, s.PURPLE, l.DARK],
    hslType: ['purple', 'saturated', 'dark'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // DESAUTRATED PURPLE
  lightDuskyPurple: {
    name: 'lightDuskyPurple',
    colorTemperature: 'cool',
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DESAT_LIGHT],
    hslType: ['purple', 'desaturated', 'light'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  duskyPurple: {
    name: 'duskyPurple',
    colorTemperature: 'cool',
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DESAT_MID],
    hslType: ['purple', 'desaturated', 'mid'],
    associations: ['purple'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  purple: {
    name: 'purple',
    colorTemperature: 'cool',
    hsl: [h.DESAT_PURPLE, s.DESAT_PURPLE, l.DARK],
    hslType: ['purple', 'desaturated', 'dark'],
    associations: ['purple'],
    simpleOpts: {
      id: 'purple',
      isSimpleColor: true,
      simpleOrder: 7,
      showInSimpleModeForLayout: [],
    },
  },
  // GRAY
  gray: {
    name: 'gray',
    colorTemperature: 'neutral',
    hsl: [h.GRAY, s.GRAY, l.DESAT_LIGHT],
    hslType: ['gray', '', 'light'],
    associations: ['gray'],
    simpleOpts: {
      id: 'gray',
      isSimpleColor: true,
      simpleOrder: 8,
      showInSimpleModeForLayout: [],
    },
  },
  midGray: {
    name: 'midGray',
    colorTemperature: 'neutral',
    hsl: [h.GRAY, s.GRAY, l.DESAT_MID],
    hslType: ['gray', '', 'mid'],
    associations: ['gray'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: [],
    },
  },
  darkGray: {
    name: 'darkGray',
    colorTemperature: 'neutral',
    hsl: [h.GRAY, s.GRAY, l.DARKEST_DARK],
    hslType: ['gray', '', 'dark'],
    associations: ['gray', 'black'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // BLACK
  black: {
    name: 'black',
    colorTemperature: 'neutral',
    hsl: [h.BLACK, s.BLACK, l.BLACK],
    hslType: ['black', '', ''],
    associations: ['black'],
    simpleOpts: {
      id: 'black',
      isSimpleColor: true,
      simpleOrder: 9,
      showInSimpleModeForLayout: [],
    },
  },
  // WHITE
  white: {
    name: 'white',
    colorTemperature: 'neutral',
    hsl: [h.WHITE, s.WHITE, l.WHITE],
    hslType: ['white', '', ''],
    associations: ['white'],
    simpleOpts: {
      id: 'white',
      isSimpleColor: true,
      simpleOrder: 10,
      showInSimpleModeForLayout: [],
    },
  },
  // CREAM
  cream: {
    name: 'cream',
    colorTemperature: 'neutral',
    hsl: [h.CREAM, s.CREAM, l.CREAM],
    hslType: ['cream', '', ''],
    associations: ['white', 'yellow'],
    simpleOpts: { isSimpleColor: false, showInSimpleModeForLayout: [] },
  },
  // METALLIC
  metallic: {
    name: 'metallic',
    colorTemperature: 'metallic',
    hsl: [h.GOLD, s.GOLD, l.GOLD],
    hslType: ['gold', '', ''],
    associations: ['metallic'],
    simpleOpts: {
      isSimpleColor: true,
      id: 'metallic',
      simpleOrder: 11,
      showInSimpleModeForLayout: [],
    },
  },
  // GOLD
  gold: {
    name: 'gold',
    colorTemperature: 'metallic',
    hsl: [h.GOLD, s.GOLD, l.GOLD],
    hslType: ['gold', '', ''],
    associations: ['metallic'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: ['standard', 'blue-distinct'],
    },
  },
  // SILVER
  silver: {
    name: 'silver',
    colorTemperature: 'metallic',
    hsl: [h.SILVER, s.SILVER, l.SILVER],
    hslType: ['silver', '', ''],
    associations: ['metallic'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: ['standard', 'blue-distinct'],
    },
  },
  // BRONZE
  bronze: {
    name: 'bronze',
    colorTemperature: 'metallic',
    hsl: [h.BRONZE, s.BRONZE, l.BRONZE],
    hslType: ['bronze', '', ''],
    associations: ['metallic'],
    simpleOpts: {
      isSimpleColor: false,
      showInSimpleModeForLayout: ['standard', 'blue-distinct'],
    },
  },
}

export const huesList = Object.values(h)
export const colorsList = Object.values(colorMap)
export const simpleColorsList = colorsList.filter(
  (color) => color.simpleOpts.isSimpleColor,
)
