import {
  ColorMode,
  GlobalUserInfo,
  Hemisphere,
  InterpretationStyleKey,
  MoonPhase,
  PublicUserKey,
  TagType,
  TagTypeWithoutMoonPhase,
} from '../../../api/frontend-types'
import { ScrollViewProps } from '../components/common/scrollview/scrollview'
import { WEB_MAX_WIDTH } from './constants'

// IMAGES
// The max width an image renders at
// E.g. on web, this is the max width of the image in the feed
export const MAX_IMG_W = 370
export const IMG_RES = 700 // Images to view are smaller
export const DOWNLOAD_IMG_RES = 1024 // Images to download are full size

// FOR AVATAR CIRCLES ETC
export const SM_THUMB_RES = 100
export const MD_THUMB_RES = 150
export const LG_THUMB_RES = 200
export const XL_THUMB_RES = 400
// Images for cards
export const freudImg = require('../assets/images/user-freud.jpg')
export const freudCutoutImg = require('../assets/images/freud-cutout-sml.png')
export const spiritualImg = require('../assets/images/theme-religion_and_spirituality.png')
export const relationshipsImg = require('../assets/images/symbol-hug.png')
export const jungImg = require('../assets/images/user-jung.jpg')
export const jungCutoutImg = require('../assets/images/jung-cutout.png')
export const davidFontanaImg = require('../assets/images/users/user-david-fontana.jpg')
export const kellyBulkeleyImg = require('../assets/images/users/user-kelly-bulkeley.jpg')
export const bookImg = require('../assets/images/symbol-book-cream.png')
export const standardImg = require('../assets/images/moon-cream-bg.png')
export const flyingPaddedImg = require('../assets/images/flying-transparent-padded.png')
export const moonTransparentImg = require('../assets/images/moon-transparent.png')
export const elsewhereAiUserImg = require('../assets/images/moon-cream-bg.png')
export const moonImg = require('../assets/images/moon-transparent.png')
export const fireImg = require('../assets/images/fire-transparent.png')
export const flyingImg = require('../assets/images/flying-transparent.png')

// The width of the image frame
// (16px all around the image, min 16px padding to the left & right screen edges)
export const FRAME_PADDING_PX = 16

export const interpreters: {
  [key in InterpretationStyleKey]: GlobalUserInfo
} = {
  jungian: {
    img: jungImg,
    i18nKey: `interpretationStyles.jungian`,
    cutoutImg: jungCutoutImg,
  },
  biblical: {
    img: bookImg,
    i18nKey: `interpretationStyles.biblical`,
    cutoutImg: bookImg,
  },
  freudian: {
    img: freudImg,
    i18nKey: `interpretationStyles.freudian`,
    cutoutImg: freudCutoutImg,
  },
  standard: {
    img: standardImg,
    i18nKey: `globalUser.elsewhere-ai.name`,
    cutoutImg: flyingPaddedImg,
  },
  spiritual: {
    img: spiritualImg,
    i18nKey: `interpretationStyles.spiritual`,
    cutoutImg: spiritualImg,
  },
  relationship: {
    img: relationshipsImg,
    i18nKey: `interpretationStyles.relationship`,
    cutoutImg: relationshipsImg,
  },
  none: {
    img: standardImg,
    i18nKey: `globalUser.elsewhere-ai.name`,
    cutoutImg: flyingPaddedImg,
  },
}

export const PUBLIC_USER_INFO: {
  [key in PublicUserKey]: GlobalUserInfo
} = {
  'david-fontana': {
    img: davidFontanaImg,
    i18nKey: `globalUser.david-fontana.name`,
  },
  'kelly-bulkeley': {
    img: kellyBulkeleyImg,
    i18nKey: `globalUser.kelly-bulkeley.name`,
  },
  'elsewhere-ai': {
    img: elsewhereAiUserImg,
    i18nKey: `globalUser.elsewhere-ai.name`,
  },
}

// COLORS
export const BLACK = '#000000'
export const WHITE = '#FFFFFF'
export const CREAM = '#F6F5F1'
export const LIGHT_BLACK = '#21251D'

export const DARK_CREAM = '#e5e5e1' // If cream needs to appear on cream
export const MID_CREAM = '#e2dfcf' // Good as a 'highlight' color in a list of cream items
export const DARKER_CREAM = '#d6d1bb' // If cream needs to appear on cream
export const LIGHTER_BLACK = '#343b2e' // If light black needs to appear on light black
export const LIGHTEST_BLACK = '#525c48' // If light black needs to appear on light black
export const LIGHT_GRAY = '#C4C4C4'
export const LIGHTER_GRAY = '#E5E5E5'
export const LIGHT_MID_GRAY = '#B1B1BA' // A little darker than LIGHT_GRAY, looks good on both black and white
export const NEUTRAL_GRAY = '#B1B1B1' // Looks good on both black and white, color neutral.
export const DARK_GRAY = '#4F4F4F'
export const DARK_MID_GRAY = '#5F5F5F' // A little lighter than DARK_GRAY
export const MID_GRAY = '#92929b' // A little lighter than DARK_GRAY
export const OFFSET_WHITE = '#DDDDDD'
export const OFFSET_BLACK = '#282828'
export const HOVER_HIGHLIGHT_LIGHT = '#d7d3c1'

export const GOLD = 'hsl(49, 22%, 68%)'
export const MID_GOLD = 'hsl(49, 22%, 85%)'
export const DARK_GOLD = 'hsl(49, 22%, 55%)'
export const DARKER_GOLD = 'hsl(49, 22%, 45%)'
export const SILVER = 'hsl(250, 4%, 70%)'
export const DARKMODE_GOLD = 'hsl(49, 22%, 67%)'
export const DARKMODE_SILVER = 'hsl(250, 5%, 67%)'

export const PLACEHOLDER_COLOR_LIGHT = '#a3a3a3'
export const PLACEHOLDER_COLOR_DARK = '#525252'

export const MUTED_TEXT_COLOR_LIGHT = '#737373'
export const MUTED_TEXT_COLOR_DARK = '#a3a3a3'

// ICONS
export const TAGTYPE_ICON_MAP: {
  [key in TagTypeWithoutMoonPhase]: string
} = {
  CHARACTER: 'hug',
  SETTING: 'house',
  SYMBOL: 'cat',
  EMOTION: 'cat',
  ACTION: 'cat',
  ANIMAL: 'cat',
  OTHER: 'cat',
  THEME: 'cat',
  DREAM_TYPE: 'cat',
  COLOR: 'cat',
  NONE: 'cat',
}

export const DEFAULT_INSIGHT_ICON = 'letter'

// SPACING
export const PARAGRAPH_MARGIN = 3 // = 12px

export const PADDING_TO_TABS = 10

// IDS
export const DEFAULT_GENDER_ID = '0186c294-124d-7022-8c29-e523505a42e1' // 'Prefer not to answer'

// QUOTES
export const NUM_QUOTES = 13

// SCROLLVIEW PROPS
export const SCROLLVIEW_PROPS = {
  contentContainerStyle: {
    width: '100%',
    maxWidth: WEB_MAX_WIDTH,
    marginHorizontal: 'auto',
  },
  dataSet: { scrollbarTabsScrollViewAdjust: true },
} as ScrollViewProps

export const MOON_PHASE_MAP: {
  [key in ColorMode]: {
    [key in MoonPhase]: MoonPhase
  }
} = {
  light: {
    newMoon: 'newMoon',
    waxingCrescent: 'waxingCrescent',
    firstQuarter: 'firstQuarter',
    waxingGibbous: 'waxingGibbous',
    fullMoon: 'fullMoon',
    waningGibbous: 'waningGibbous',
    lastQuarter: 'lastQuarter',
    waningCrescent: 'waningCrescent',
  },
  dark: {
    newMoon: 'fullMoon',
    waxingCrescent: 'waningGibbous',
    firstQuarter: 'lastQuarter',
    waxingGibbous: 'waningCrescent',
    fullMoon: 'newMoon',
    waningGibbous: 'waxingCrescent',
    lastQuarter: 'firstQuarter',
    waningCrescent: 'waxingGibbous',
  },
}
