import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { HeaderTitle } from './shared'
import PrivateJournal from './Channels/Private/PrivateJournal'
import PrivateDrafts from './Channels/Private/PrivateDrafts'
import PrivateInsights from './Channels/Private/PrivateInsights'
import PrivateAsk from './Channels/Private/PrivateAsk'
import PrivateCharts from './Channels/Private/PrivateCharts'
import PrivateSearch from './Channels/Private/PrivateSearch'
import PrivateSymbols from './Channels/Private/PrivateSymbols'
import GroupJournal from './Channels/Group/GroupJournal'
import { MainStackParamList } from './types'
import PrivateFriends from './Channels/Private/PrivateFriends'
import PrivateFriend from './Channels/Private/PrivateFriend'
import PrivateFriendSharedDreams from './Channels/Private/PrivateFriendSharedDreams'
import PrivateDiary from './Channels/Private/PrivateDiary'
import PrivateFavoritesFeed from './Channels/Private/PrivateFavoritesFeed'
import PrivateCharacters from './Channels/Private/PrivateCharacters'

const MainStack = createNativeStackNavigator<MainStackParamList>()

export type MainStackType = ReturnType<
  typeof createNativeStackNavigator<MainStackParamList>
>
type MainStackProps = {
  defaultScreenOptions: any
}

export default function ({ defaultScreenOptions }: MainStackProps) {
  return (
    <>
      <MainStack.Navigator
        screenOptions={{
          ...defaultScreenOptions,
          headerTitle: HeaderTitle,
          headerBackVisible: false,
        }}
        initialRouteName="PrivateFeed"
      >
        {/* Private channels */}
        {PrivateJournal(MainStack)}
        {PrivateDrafts(MainStack)}
        {PrivateDiary(MainStack)}
        {PrivateInsights(MainStack)}
        {PrivateAsk(MainStack)}
        {PrivateCharts(MainStack)}
        {PrivateSearch(MainStack)}
        {PrivateSymbols(MainStack)}
        {PrivateCharacters(MainStack)}
        {PrivateFriends(MainStack)}
        {PrivateFavoritesFeed(MainStack)}
        {PrivateFriend(MainStack)}
        {PrivateFriendSharedDreams(MainStack)}
        {/* Group channels */}
        {GroupJournal(MainStack)}
      </MainStack.Navigator>
    </>
  )
}
