import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Form } from '../../../components/common/form/form'
import { useSelector, useDispatch } from 'react-redux'
import { editDraftForm } from '../../../forms/dream-form'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import {
  formatDateTimeForDatabase,
  isUuidv7,
} from '../../../modules/strings/string-helpers'
import {
  dreamifyDraft,
  updateDream,
} from '../../../ducks/dream-tag/thunks/dream-thunks'
import {
  selectDefaultArtStyle,
  selectNoImageArtStyleId,
  selectUser,
} from '../../../ducks/user/user'
import { RootStackScreenProps } from '../../../navigation/types'
import { FastDream } from '../../../../../api/_openapi'
import { ArtStyleKey } from '../../../../../api/frontend-types'

export default function DiaryEdit({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDiaryEdit'>) {
  // HOOKS
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const { id } = route.params

  // SELECTORS
  const dream = useSelector(selectFastDreamById(id))
  const defaultArtStyleId = useSelector(selectDefaultArtStyle)
  const noArtStyleId = useSelector(selectNoImageArtStyleId)
  const user = useSelector(selectUser)

  // ON SUBMIT
  const onSubmitForm = (data: any) => {
    if (!user?.userGroupId) return

    // Art style
    // If the user has entered a custom prompt, this will be free text
    // If the user has selected a prompt, this will be the id of the prompt
    const artStyle: ArtStyleKey = data.artStyle || defaultArtStyleId
    const isDraft = Boolean(data.isDraft)
    const isCustomPrompt = !isUuidv7(artStyle)
    const shouldGenerateImage = !(artStyle === noArtStyleId) && !isDraft
    const customPrompt = isCustomPrompt ? artStyle : undefined
    const artStyleId = isCustomPrompt ? defaultArtStyleId : artStyle

    const updatedDraft: FastDream = {
      ...dream,
      title: data.title || '',
      description: data.description || '',
      date: data.date,
      displayDate: data.displayDate || '',
      note: data.note || '',
      updatedAt: formatDateTimeForDatabase(new Date()),
      isDraft: isDraft,
      ...data.place,
    }

    // If 'draft' is toggled off, save the dream
    if (!isDraft) {
      dispatch(dreamifyDraft(user.userGroupId, data, defaultArtStyleId))
      navigation.replace('MainTabs', {
        screen: 'MainStack',
        params: {
          screen: 'PrivateDiaryView',
          params: {
            id,
          },
        },
      })
    }
    // Otherwise, save the draft
    else {
      dispatch(updateDream(user.userGroupId, id, updatedDraft))
      navigation.replace('MainTabs', {
        screen: 'MainStack',
        params: { screen: 'PrivateDiaryFeed' },
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <Form
        name={'newDreamForm'}
        form={editDraftForm}
        onSubmit={onSubmitForm}
        headerTitle={t('Edit Entry')}
        prepopulateValues={dream}
        confirmCancelMessage={t('editDraftPage.exitWarning')}
        checkOnCancelFields={['description', 'title', 'note']}
      />
    </View>
  )
}
