import React, { useEffect, useState } from 'react'
import { SymbolsList } from './mysymbols'
import { useSelector } from '../../ducks/root-reducer'
import { SymbolDex } from './symboldex'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { TabView } from '../../components/layout/tab-view'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { SansText } from '../../components/common/copy/text-sans'
import { NoContentMessage } from '../../components/layout/no-content-message'
import { useTranslation } from 'react-i18next'
import { PADDING_TO_TABS } from '../../constants/ui-constants'
import { CountedFastTag, CountedFastTagService } from '../../../../api/_openapi'
import { selectUser } from '../../ducks/user/user'
import { Loading } from '../../components/layout/loading'
import { Row } from '../../components/common/row/row'
import { Column } from '../../components/common/column/column'

const SymbolPageDescription = ({ text }: { text: string }) => {
  return (
    <PaddedContentArea pt={0} pb={PADDING_TO_TABS - 5}>
      <SansText textAlign={'center'}>{text}</SansText>
    </PaddedContentArea>
  )
}

export default function Symbols() {
  const { t } = useTranslation()
  const [topSymbols, setTopSymbols] = React.useState<CountedFastTag[]>([])
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(Boolean(user && user.userGroupId))

  useEffect(() => {
    if (user && user.userGroupId) {
      CountedFastTagService.countedTypeTags(
        user.userGroupId,
        'SYMBOL',
        1000,
      ).then((countedTags) => {
        setTopSymbols(countedTags.filter((tag) => tag.type === 'SYMBOL'))
        setLoading(false)
      })
    }
  }, [user])

  const SymbolListComponent = () => (
    <Tabs.ScrollView>
      <Column w={'100%'} py={PADDING_TO_TABS}>
        <SymbolPageDescription text={t('symbolPage.topSymbols.description')} />
        {loading && <Loading />}
        {!loading && topSymbols.length === 0 && (
          <Row justifyContent={'center'} mt={12}>
            <NoContentMessage message={t('symbolPage.noSymbols')} />
          </Row>
        )}
        <SymbolsList tags={topSymbols} canSearch={false} />
      </Column>
    </Tabs.ScrollView>
  )

  const SymbolDexComponent = () => (
    <Tabs.ScrollView>
      <Column w={'100%'} py={PADDING_TO_TABS}>
        <SymbolPageDescription text={t('symbolPage.collection.description')} />
        <SymbolDex userSymbols={topSymbols} loading={loading} />
      </Column>
    </Tabs.ScrollView>
  )

  const tabs: TabProps<string>[] = [
    {
      name: 'TopSymbols',
      children: <SymbolListComponent />,
      label: t('symbolPage.topSymbols'),
    },
    {
      name: 'Collection',
      children: <SymbolDexComponent />,
      label: t('symbolPage.collection'),
    },
  ]

  return (
    <TabView
      tabs={tabs}
      scenes={{
        TopSymbols: SymbolListComponent,
        Collection: SymbolDexComponent,
      }}
    />
  )
}
