import React, { useMemo, useEffect } from 'react'
import { Pressable, View } from 'react-native'
import { Box } from '../../common/box/box'
import { Row } from '../../common/row/row'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'
import { SansText } from '../../common/copy/text-sans'
import { Column } from '../../common/column/column'
import { colorNameIsShiny } from '../../../modules/color/color-string-helpers'
import { colorDefinitionToColor } from '../../../modules/color/color-helpers'
import { getAssociatedColors } from '../../../modules/color/flat-colors'
import { ColorCircle } from './color-circle'
import { LINE_WIDTH } from '../../../constants/constants'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { PickerColors } from './color-picker'
import {
  Color,
  ColorWithChildren,
  PickerI18nLayout,
  SimpleColor,
  TintGrouping,
} from '../../../modules/color/color-types'
import {
  BLACK,
  CREAM,
  DARKER_CREAM,
  LIGHT_BLACK,
  LIGHTER_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'

type PaletteSectionProps = {
  isWideLayout: boolean
  selectedColors: PickerColors
  height: number
  width: number
  backButtonWidth: number
  numColsSimpleView: number
  numColsAdvancedView: number
  rowHeight: number
  circleDiameter: number
  paddingPx?: number
  onAddColor: (newColor: Color, existingColors: PickerColors) => void
  maxColors?: number
  maxColorsReached: boolean
  advancedModeOn: boolean
  selectedBasicColor: Color | undefined
  onAlertColorAlreadyAdded: () => void
  onAlertMaxColorsReached: () => void
  setSelectedBasicColor: (color: Color | undefined) => void
  basicColorsWithChildren: ColorWithChildren[]
  pickerLayout: PickerI18nLayout
}

export const PaletteSection = ({
  isWideLayout,
  selectedColors,
  onAddColor,
  rowHeight,
  height,
  width,
  backButtonWidth,
  numColsSimpleView,
  numColsAdvancedView,
  paddingPx = 4,
  circleDiameter,
  maxColorsReached,
  selectedBasicColor,
  advancedModeOn,
  onAlertColorAlreadyAdded,
  onAlertMaxColorsReached,
  basicColorsWithChildren,
  setSelectedBasicColor,
  pickerLayout,
}: PaletteSectionProps) => {
  const colorRowHeight = rowHeight

  // HOOKS
  const { t } = useTranslation()
  const bgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const borderColor = useColorModeValue(BLACK, WHITE)
  const backCaretBarColor = useColorModeValue(DARKER_CREAM, LIGHTER_BLACK)

  // VARS
  const hasSelectedBasicColor = Boolean(selectedBasicColor) // If basic color select, show advanced palette
  const basicColorName = selectedBasicColor?.simpleColor || ''
  const colWidthPercent = 100 / numColsSimpleView
  const advancedColWidthPercent = 100 / numColsAdvancedView
  const selectedSimpleColorName: SimpleColor | undefined =
    selectedBasicColor?.simpleColor as SimpleColor | undefined

  // I18N
  // We have a special string if choosing within the simple picker
  // E.g. between light and dark blue in some languages
  const useAdvancedStr = advancedModeOn || basicColorName === 'metallic'
  const chooseStr = useAdvancedStr
    ? t(`colorPicker.chooseShade.${basicColorName}`)
    : t(`colorPicker.chooseShade.${basicColorName}.simple`)

  // MEMOS
  // If a simple color is selected, get the associated colors
  const associatedColors: TintGrouping[] = useMemo(() => {
    if (!selectedBasicColor) {
      return []
    } else {
      const associatedColors = getAssociatedColors(
        selectedBasicColor.simpleColor,
        advancedModeOn,
        pickerLayout,
      )
      // Sort single color palettes to the end
      const sortedColors = associatedColors.sort((a, b) => {
        if (a.tints.length === 1) {
          return 1
        } else if (b.tints.length === 1) {
          return -1
        } else {
          return 0
        }
      })
      return sortedColors
    }
  }, [selectedBasicColor, advancedModeOn, pickerLayout])

  // ANIMATION
  const translateX = useSharedValue(0)

  useEffect(() => {
    if (selectedBasicColor) {
      translateX.value = withTiming(-width, { duration: 400 })
    } else {
      translateX.value = withTiming(0, { duration: 400 })
    }
  }, [selectedBasicColor, translateX, width])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateX.value }],
    }
  })

  const onColorClick = (
    color: Color,
    hasChildColors: Boolean,
    colors: PickerColors,
    advancedModeOn: Boolean,
    hasSelectedBasicColor: Boolean,
    hasCaret: Boolean,
    maxColorsReached: Boolean,
  ) => {
    const { complexColors } = colors

    const shouldSetBasicColor =
      (advancedModeOn && hasChildColors) || (hasCaret && !advancedModeOn)

    // CASE 1) If max colors reached, alert
    if (maxColorsReached) {
      onAlertMaxColorsReached()
    }
    // CASE 2) If color is already selected, alert
    else if (
      colorIsAlreadySelected(
        color,
        hasChildColors,
        complexColors,
        advancedModeOn,
        hasSelectedBasicColor,
        hasCaret,
      )
    ) {
      onAlertColorAlreadyAdded()
    } else if (shouldSetBasicColor) {
      setSelectedBasicColor(color)
    } else {
      onAddColor(color, selectedColors)
    }
  }

  return (
    <View>
      {/* FRAME */}
      <Box width={width} backgroundColor={'blue.200'} overflow={'hidden'}>
        {/* BOTH PALETTES */}
        <Animated.View
          style={[{ width: width * 2, flexDirection: 'row' }, animatedStyle]}
        >
          {/* SIMPLE PALETTE */}
          <Box
            height={height}
            width={width}
            borderWidth={LINE_WIDTH}
            bgColor={bgColor}
            borderColor={borderColor}
            flexDirection={'row'}
            flexWrap={'wrap'}
            padding={`${paddingPx}px`}
          >
            {basicColorsWithChildren.map((color, index) => {
              const { colorName, hasChildColors, hasChildColorsInSimpleMode } =
                color
              const isShiny = colorNameIsShiny(colorName)
              const hasCaret =
                (hasChildColors && advancedModeOn) || hasChildColorsInSimpleMode
              return (
                <Box
                  key={index}
                  width={`${colWidthPercent}%`}
                  height={colorRowHeight}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <ColorCircle
                    diameter={circleDiameter}
                    isShiny={isShiny}
                    onColorClick={(c) => {
                      onColorClick(
                        c,
                        hasChildColors,
                        selectedColors,
                        advancedModeOn,
                        hasSelectedBasicColor,
                        hasCaret,
                        maxColorsReached,
                      )
                    }}
                    color1={color}
                    centreIcon={hasCaret ? 'caret-right' : undefined}
                  />
                </Box>
              )
            })}
          </Box>
          {/* ADVANCED PALETTE */}
          <Box
            height={height}
            width={width}
            borderWidth={LINE_WIDTH}
            bgColor={bgColor}
            borderColor={borderColor}
            flexDirection={'row'}
          >
            {/* BACK CARET BAR */}
            <Pressable
              onPress={() => setSelectedBasicColor(undefined)}
              style={{
                width: backButtonWidth,
                height: '100%',
                backgroundColor: backCaretBarColor,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ElsewhereIcon iconKey="caret-left" />
            </Pressable>

            <Column
              width={width - backButtonWidth}
              height={'100%'}
              // justifyContent={'center'}
            >
              {/* EXPLANATION BAR */}
              {hasSelectedBasicColor && (
                <Row
                  width={'100%'}
                  padding={4}
                  height={colorRowHeight}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <SansText>{chooseStr}</SansText>
                </Row>
              )}
              {/* COLOR PIES */}
              <Row
                width={'100%'}
                flexWrap={'wrap'}
                paddingY={isWideLayout ? undefined : `${paddingPx}px`}
                paddingX={`${paddingPx}px`}
                justifyContent={'center'}
                alignItems={'flex-start'}
              >
                {associatedColors.map((group: TintGrouping, index) => {
                  const { tints } = group
                  const [color1, color2, color3] = tints
                  const isShiny = color1.colorTemperature === 'metallic'
                  return (
                    <Box
                      key={index}
                      width={`${advancedColWidthPercent}%`}
                      height={colorRowHeight}
                      justifyContent={isWideLayout ? undefined : 'center'}
                      alignItems={'center'}
                    >
                      <ColorCircle
                        color1={colorDefinitionToColor(
                          color1,
                          selectedSimpleColorName,
                        )}
                        color2={colorDefinitionToColor(
                          color2,
                          selectedSimpleColorName,
                        )}
                        color3={colorDefinitionToColor(
                          color3,
                          selectedSimpleColorName,
                        )}
                        diameter={circleDiameter}
                        isShiny={isShiny}
                        onColorClick={(color) => {
                          onColorClick(
                            color,
                            false,
                            selectedColors,
                            advancedModeOn,
                            hasSelectedBasicColor,
                            false,
                            maxColorsReached,
                          )
                        }}
                      />
                    </Box>
                  )
                })}
              </Row>
            </Column>
          </Box>
        </Animated.View>
      </Box>
    </View>
  )
}

// HELPER FUNCTIONS
function colorIsAlreadySelected(
  color: Color,
  hasChildColors: Boolean,
  colors: Color[],
  advancedModeOn: Boolean,
  hasSelectedBasicColor: Boolean,
  hasCaret?: Boolean,
): Boolean {
  // If the color has a caret, we let the user click into it
  // Even if all options within it are selected
  if (hasCaret) {
    return false
  }
  // If a) advanced mode is on, b) the color has children,
  // and c) a basic color has not been selected,
  // We don't regard it as already selected,
  // Since the user can still select a child color
  else if (advancedModeOn && hasChildColors && !hasSelectedBasicColor) {
    return false
  } else {
    return colors.some((c) => c.colorName === color.colorName)
  }
}
