import React from 'react'
import { Row } from '../../common/row/row'
import { Color } from '../../../modules/color/color-types'
import { PeaceSignTriangle } from './base-triangle'
import { Pressable } from '../../common/pressable/pressable'
import { Box, InterfaceBoxProps } from '../../common/box/box'
import { MID_GRAY, NEUTRAL_GRAY, WHITE } from '../../../constants/ui-constants'
import { getContrastColor } from '../../../modules/color/color-helpers'
import { LINE_WIDTH } from '../../../constants/constants'
import {
  IconCaretRight,
  IconCross,
  IconSparkle,
} from '../../../assets/react-native-svg/icons/icons-ui'

const BORDER_WIDTH_PX = LINE_WIDTH

const defaultColor: Color = {
  colorName: 'black',
  simpleColor: 'black',
  hex: '#000000',
}

type ColorCircleProps = InterfaceBoxProps & {
  color1?: Color
  color2?: Color
  color3?: Color
  isShiny?: boolean
  centreIcon?: 'caret-right' | 'close'
  diameter: number
  onColorClick: (color: Color) => void
}

export const ColorCircle = ({
  diameter = 300,
  color1,
  color2,
  color3,
  isShiny,
  centreIcon,
  onColorClick,
  ...rest
}: ColorCircleProps) => {
  // VARS
  const totalBorderWidth = BORDER_WIDTH_PX * 2
  const firstColor = color1 || defaultColor
  const isSingleColorPie = !color2
  const halfDiameter = diameter / 2

  const iconSize = 5
  const sparkleIconSize = getSparkleIconSize(diameter)
  const centreIconOffset = halfDiameter - 2 * iconSize - BORDER_WIDTH_PX

  // Icon color
  const iconColor = getContrastColor(firstColor)

  // We know the base triangle needs a top angle of 120 degrees
  // So half of it is 60 degrees
  // i.e. tan(60) = x / halfDiameter, where x is half the triangle's base width
  // x = halfDiameter * tan(60)
  const halfTriangleBase = halfDiameter * Math.tan((60 * Math.PI) / 180)
  const triangleBaseWidth = halfTriangleBase * 2

  // Now we need to decide how far left the triangle should be
  // We just need to shift it left a little so the middle of the triangle (wider)
  // is in the middle of the diameter (smaller)
  // i.e. 1) Get the difference between halfTriangleBase and halfDiameter
  // then 2) Get that as a percentage of the diameter
  const diff = halfTriangleBase - halfDiameter
  const leftPercent = (100 / diameter) * diff

  return (
    <Box
      width={`${diameter + totalBorderWidth}px`}
      height={`${diameter + totalBorderWidth}px`}
      position={'relative'}
      borderRadius={'full'}
      borderWidth={`${totalBorderWidth}px`}
      borderColor={NEUTRAL_GRAY}
      justifyContent={'center'}
      alignItems={'center'}
      {...rest}
    >
      <Row
        width={`${diameter}px`}
        height={`${diameter}px`}
        borderRadius={'full'}
        overflow={'hidden'}
      >
        {/* COLOR 1 (LEFT SIDE RECTANGLE) */}
        <Pressable onPress={() => onColorClick(firstColor)}>
          <Row
            backgroundColor={firstColor.hex}
            width={isSingleColorPie ? diameter : halfDiameter}
            height={diameter}
          />
        </Pressable>
        {/* COLOR 2 (RIGHT SIDE RECTANGLE) */}
        {color2 && (
          <Pressable onPress={() => onColorClick(color2)}>
            <Row
              backgroundColor={color2.hex}
              width={halfDiameter}
              height={diameter}
            />
          </Pressable>
        )}
        {/* COLOR 3 (BOTTOM TRIANGLE) */}
        {color3 && (
          <PeaceSignTriangle
            backgroundColor={color3.hex}
            height={halfDiameter}
            width={triangleBaseWidth}
            bottom={0}
            left={`-${leftPercent}%`}
            onPress={() => onColorClick(color3)}
          />
        )}
      </Row>
      {/* SPARKLE */}
      {isShiny && (
        <Box position={'absolute'} right={-7} top={-7}>
          <IconSparkle fill={WHITE} color={MID_GRAY} size={sparkleIconSize} />
        </Box>
      )}
      {/* ICON */}
      {centreIcon && (
        <Pressable
          onPress={() => onColorClick(firstColor)}
          position={'absolute'}
          left={`${centreIconOffset}px`}
          top={`${centreIconOffset}px`}
        >
          {centreIcon === 'caret-right' && (
            <IconCaretRight color={iconColor} size={iconSize} />
          )}
          {centreIcon === 'close' && (
            <IconCross color={iconColor} size={iconSize} />
          )}
        </Pressable>
      )}
    </Box>
  )
}

function getSparkleIconSize(diameter: number) {
  if (diameter < 40) {
    return 'xs'
  }
  if (diameter < 100) {
    return 'sm'
  }
  return 'md'
}
