import React, { useState } from 'react'
import i18n from 'i18next'
import { useColorModeValue } from 'native-base'
import { BLACK, MID_GRAY, WHITE } from '../../../constants/ui-constants'
import { Pressable } from 'react-native'
import { LINE_WIDTH } from '../../../constants/constants'
import { SansText } from '../copy/text-sans'
import { useTranslation } from 'react-i18next'
import { cap } from '../../../modules/strings/string-helpers'
import { Row } from '../row/row'
import { Box } from '../box/box'
import { ElsewhereIcon } from '../elsewhere-icon/elsewhere-icon'
import { AlertPremiumFeature } from '../dialog/dialog-premium'

type InputToggleProps = {
  label: string
  value: boolean | undefined
  onValueChange: (value: boolean | undefined) => void
  disabled?: boolean
  isPremiumFeature?: boolean
  lockedBecausePremium?: boolean
}

export const InputToggle = ({
  label,
  value,
  onValueChange,
  disabled,
  isPremiumFeature,
  lockedBecausePremium,
}: InputToggleProps) => {
  // STATE
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false)

  // HOOKS
  const { t } = useTranslation()
  const toggleBgColorActive = useColorModeValue(BLACK, WHITE)
  const inactiveColor = useColorModeValue(MID_GRAY, MID_GRAY)
  const toggleColor = useColorModeValue(WHITE, BLACK)

  // VARS
  const toggleBgColor = value ? toggleBgColorActive : inactiveColor
  const isRtl = i18n.dir() === 'rtl'
  const paddingLeftLtr = value ? 20 : 2
  const paddingRightLtr = value ? 2 : 20
  const paddingLeftRtl = value ? 2 : 20
  const paddingRightRtl = value ? 20 : 2
  const paddingLeft = isRtl ? paddingLeftRtl : paddingLeftLtr
  const paddingRight = isRtl ? paddingRightRtl : paddingRightLtr
  // Don't disable if locked because premium, we have a special dialog for that
  const shouldDisable = disabled && !lockedBecausePremium
  const showGem = isPremiumFeature || lockedBecausePremium
  const gemColor = lockedBecausePremium ? inactiveColor : toggleBgColor

  return (
    <>
      <Row alignItems="center">
        <Pressable
          onPress={() => {
            if (lockedBecausePremium) {
              setPremiumDialogOpen(true)
            } else {
              onValueChange(!value)
            }
          }}
          disabled={shouldDisable}
          role="button"
        >
          <Row
            style={{
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
              borderWidth: LINE_WIDTH,
              borderColor: toggleColor,
              borderRadius: 20,
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor: toggleBgColor,
            }}
          >
            <Box
              style={{
                height: 16,
                width: 16,
                backgroundColor: toggleColor,
                borderRadius: 20,
              }}
            />
          </Row>
        </Pressable>
        <Row alignItems={'center'}>
          <SansText paddingX={2} color={toggleBgColor}>
            {cap(t(label))}
          </SansText>
          {showGem && (
            <ElsewhereIcon iconKey="gem" size={'xs'} color={gemColor} />
          )}
        </Row>
      </Row>
      {lockedBecausePremium && (
        <AlertPremiumFeature
          isOpen={premiumDialogOpen}
          onClose={() => setPremiumDialogOpen(false)}
        />
      )}
    </>
  )
}
