import React, { useMemo } from 'react'
import { FastTag } from '../../../../../api/_openapi'
import { Row } from '../../common/row/row'
import { useNavigation } from '@react-navigation/native'
import { MainStackNavigationProp } from '../../../navigation/types'
import { SIMPLE_COLOR_LABEL } from '../../../modules/color/colorConstants'
import { ColorCircle } from '../colorpicker/color-circle'
import { Color, ColorTagSubType } from '../../../modules/color/color-types'
import { getColorFromTag } from '../colorpicker/picker-helpers'
import { colorNameIsShiny } from '../../../modules/color/color-string-helpers'

type ColorWithIdAndShininess = Color & { id: string; isShiny: boolean }

type ColorNameMap = {
  [key: string]: string
}

type ColorTagAreaProps = {
  tags: FastTag[]
}

export const ColorTagArea = ({ tags }: ColorTagAreaProps) => {
  const navigation =
    useNavigation<MainStackNavigationProp<'PrivateDreamView'>>()

  // We only show the top level color tags,
  // not the 'simple colors' behind them
  // BUT when people click on them, we navigate to the
  const colorsWithIds: ColorWithIdAndShininess[] = useMemo(() => {
    // Make a map of simple colors to we can use their ids
    const simpleColors = tags.filter((t) => t.subType === SIMPLE_COLOR_LABEL)
    const simpleColorMap: ColorNameMap = simpleColors.reduce((acc, tag) => {
      return {
        ...acc,
        [tag.name]: tag.id,
      }
    }, {})
    return tags
      .filter((tag) => tag.subType !== SIMPLE_COLOR_LABEL)
      .map((tag) => {
        const subType = tag.subType as ColorTagSubType
        const color = getColorFromTag(tag.name, subType)
        const isShiny = colorNameIsShiny(color.colorName)
        // Get simple color id if it exists
        // Don't do this for shiny colors
        let navId = tag.id
        if (simpleColorMap[color.simpleColor] && !isShiny) {
          navId = simpleColorMap[color.simpleColor]
        }
        return {
          ...color,
          id: navId,
          isShiny,
        }
      })
  }, [tags])

  return (
    <Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
      {colorsWithIds.map((color) => {
        return (
          <ColorCircle
            key={`${color.colorName}-${color.id}`}
            color1={color}
            diameter={32}
            isShiny={color.isShiny}
            onColorClick={() => {
              navigation.push('PrivateTagView', {
                id: color.id,
              })
            }}
            ml={1}
            mr={1}
          />
        )
      })}
    </Row>
  )
}
