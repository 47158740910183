import React, { useEffect, useState } from 'react'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { Row } from '../../common/row/row'
import {
  CountedFastTag,
  CountedFastTagService,
  FastTag,
} from '../../../../../api/_openapi'
import { Hemisphere, MoonPhase } from '../../../../../api/frontend-types'
import { Moon, Hemisphere as LunarHemisphere, LunarPhase } from 'lunarphase-js'
import { useColorMode, ColorMode } from 'native-base'
import { camelCase } from '../../../modules/strings/string-helpers'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUserIsPremium } from '../../../ducks/user/user'
import { useHemisphere } from '../../../hooks/useHemisphere'
import { Moon as MoonIcon } from '../moon/moon'
import { dateStringToUTCMidnight } from '../../../modules/strings/string-helpers'
import { StyleProp, ViewStyle } from 'react-native'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { idEquals } from '../../../ducks/helpers'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { PressableFixed } from '../../../utilities/PressableFixed'
import { MainStackNavigationProp } from '../../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { Box } from 'native-base'
import { ColorMode as FrontendColorMode } from '../../../../../api/frontend-types'

const getMoonPhase = (date: Date, hemisphere: Hemisphere) => {
  const phase = Moon.lunarPhase(date, {
    hemisphere: hemisphere as LunarHemisphere,
  })
  return camelCase(
    phase === LunarPhase.NEW
      ? 'New Moon'
      : phase === LunarPhase.FULL
      ? 'Full Moon'
      : phase,
  ) as MoonPhase
}

type MoonPhaseTagProps = {
  moonPhaseTag: (Omit<FastTag, 'type'> & { type: 'MOON_PHASE' }) | null
  date?: never
}

type HemisphereDateProps = {
  moonPhaseTag?: never
  date: string
}

type AstrologicalDataProps = (MoonPhaseTagProps | HemisphereDateProps) & {
  mt?: number
  size?: number
  style?: StyleProp<ViewStyle>
  includeLinkToMoonPhase?: boolean
}

type MoonPhaseRendererProps = {
  moonPhase: MoonPhase
  size: number
  moonColor: string
  moonShadowColor: string
  hemisphere: Hemisphere
  colorMode: ColorMode
  isClickable?: boolean
  moonPhaseId?: string
}

const MoonPhaseRenderer = ({
  moonPhase,
  size,
  moonColor,
  moonShadowColor,
  hemisphere,
  colorMode,
  isClickable = false,
  moonPhaseId,
}: MoonPhaseRendererProps) => {
  const navigation = useNavigation<MainStackNavigationProp<any>>()

  const moonIconProps = {
    phase: moonPhase,
    moonColor,
    shadowColor: moonShadowColor,
    hemisphere,
    size,
    colorMode: colorMode as FrontendColorMode,
    applyAnimation: true,
  }

  if (!isClickable) {
    return <MoonIcon {...moonIconProps} />
  }

  return (
    <PressableFixed
      onPress={() => {
        if (!moonPhaseId) return
        navigation.push('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateTagView',
            params: { id: moonPhaseId },
          },
        })
      }}
      _hover={{ opacity: 0.5 }}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
    >
      <Box
        width={size}
        height={size}
        alignItems="center"
        justifyContent="center"
      >
        <MoonIcon {...moonIconProps} />
      </Box>
    </PressableFixed>
  )
}

const AstrologicalData = ({
  date,
  mt = 2,
  size = 30,
  style,
  includeLinkToMoonPhase = false,
}: AstrologicalDataProps) => {
  const [loading, setLoading] = useState(false)
  const [moonPhaseId, setMoonPhaseId] = useState<string | undefined>(undefined)

  // HOOKS
  const hemisphere = useHemisphere()
  const { colorMode } = useColorMode()
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = useActiveGroupId()
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)

  // SELECTORS
  const userIsPremium = useSelector(selectUserIsPremium)

  // VARS
  const moonColor = WHITE
  const moonShadowColor = BLACK
  let moonPhase: MoonPhase | undefined
  if (hemisphere && date) {
    moonPhase = getMoonPhase(dateStringToUTCMidnight(date), hemisphere)
  }

  useEffect(() => {
    if (activeGroupId && includeLinkToMoonPhase && moonPhase) {
      setLoading(true)
      CountedFastTagService.countedTypeTags(activeGroupId, 'MOON_PHASE', 8)
        .then((data: CountedFastTag[]) => {
          const phaseMap = data.reduce((acc, tag) => {
            const phaseName = tag.name.replace('moonphase.', '') as MoonPhase
            acc[phaseName] = tag.id
            return acc
          }, {} as Record<MoonPhase, string>)

          if (moonPhase && phaseMap[moonPhase]) {
            setMoonPhaseId(phaseMap[moonPhase])
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching moon phase tags:', error)
          setLoading(false)
        })
    }
  }, [activeGroupId, includeLinkToMoonPhase, moonPhase])

  // EARLY RETURN
  if (!userIsPremium || !moonPhase) {
    return null
  }

  return (
    <Row
      justifyContent={'center'}
      marginTop={mt || 0}
      style={style}
      pointerEvents="box-none"
    >
      <MoonPhaseRenderer
        moonPhase={moonPhase}
        size={size}
        moonColor={moonColor}
        moonShadowColor={moonShadowColor}
        hemisphere={hemisphere}
        colorMode={colorMode}
        isClickable={includeLinkToMoonPhase && isUserPrivateGroup && !loading}
        moonPhaseId={moonPhaseId}
      />
    </Row>
  )
}

export { AstrologicalData }
