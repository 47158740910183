/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagHighlight } from '../models/TagHighlight';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagHighlightService {
  /**
   * Retrieves the collection of TagHighlight resources.
   * Retrieves the collection of TagHighlight resources.
   * @param id TagHighlight identifier
   * @param count
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns TagHighlight TagHighlight collection
   * @throws ApiError
   */
  public static tagHighlights(
    id: string,
    count?: number,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<TagHighlight>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tags/{id}/highlights',
      path: {
        'id': id,
      },
      query: {
        'count': count,
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
}
