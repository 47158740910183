import formatISO from 'date-fns/formatISO'
import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { SupportedLanguage } from '../../../../api/frontend-types'

// Turn username into initials
// e.g. "John Doe" => "JD"
// If they don't have a space in their name, just use the first letter
// Used for the avatar image fallback
export function fallbackInitialsFromName(name: string | null | undefined) {
  if (!name) {
    return '?'
  } else if (name.includes(' ')) {
    const names = name.split(' ')
    const initials = names.map((n) => n[0]).join('')
    return initials.toUpperCase()
  } else {
    return name[0].toUpperCase()
  }
}

export function truncateString(str: string | undefined, maxLength: number) {
  if (!str) {
    return ''
  } else if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...'
  } else {
    return str
  }
}

export function formatDateForDatabase(date: Date) {
  if (typeof date !== 'object') {
    throw new Error('formatDateForDatabase: date must be a Date object')
  }
  return formatISO(date, { representation: 'date' })
}

export function formatTimeForDatabase(date: Date) {
  if (typeof date !== 'object') {
    throw new Error('formatDateForDatabase: date must be a Date object')
  }
  return format(date, 'HH:mm:ss')
}

export function formatTimeForDisplay(date: Date) {
  if (typeof date !== 'object') {
    throw new Error('formatDateForDatabase: date must be a Date object')
  }
  return format(date, 'KK:mm a')
}

export const formatInTimeZone = (date: Date, fmt: string, tz: string) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

export function formatDateTimeForDatabase(date: Date) {
  if (typeof date !== 'object') {
    throw new Error('formatDateForDatabase: date must be a Date object')
  }
  const isoTime = formatISO(date, { representation: 'complete' })
  const parsedTime = parseISO(isoTime)
  const formattedTime = formatInTimeZone(
    parsedTime,
    "yyyy-MM-dd'T'kk:mm:ssxxx",
    'UTC',
  )
  return formattedTime
}

// Uppercase the first letter of a string
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isWholeNumber(n: number): boolean {
  return n % 1 === 0
}

export function dateFromString(date: string | undefined) {
  if (typeof date !== 'string' && date !== undefined) {
    throw new Error('dateFromString: date must be a string')
  }
  const dateStr = date ? date.substr(0, 10) : undefined
  const dateArr = dateStr ? dateStr.split('-') : undefined
  return dateArr
    ? new Date(
        parseInt(dateArr[0], 10),
        parseInt(dateArr[1], 10) - 1,
        parseInt(dateArr[2], 10),
      )
    : undefined
}

// The backend sends us strings in the form {{key:languageKey}}
// We need to extract the language key and use it to look up the string in the language file
export function unpackLanguageKeyString(key: string) {
  const match = key.match(/{{key:(.*)}}/)
  if (match) {
    return match[1]
  } else {
    return key
  }
}

// Capitalize the first letter of a string
// (e.g. "hello" -> "Hello")
// (e.g. ”クマ" -> "クマ")
export function cap(string: string | undefined | null) {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getSearchParamFromURL = (url: string, param: string) => {
  const include = url?.includes(param)

  if (!include) return null

  const params = url.split(/([&,?,=])/)
  const index = params.indexOf(param)
  const value = params[index + 2]
  return value
}

export const snakeCase = (str: string | null | undefined): string => {
  if (!str) {
    return ''
  } else {
    return str
      .replace(/[\s]/g, '_')
      .replace(/[^a-zA-Z0-9_]/g, '')
      .toLowerCase()
  }
}

export const camelCase = (str: string | null | undefined): string => {
  // Check if word is already camelCase
  // We assume this if the first letter is lowercase,
  // there are no space characters or underscores,
  // and there is at least one uppercase letter
  const isAlreadyCamelCase =
    str &&
    str[0] === str[0].toLowerCase() &&
    !str.includes(' ') &&
    !str.includes('_') &&
    str !== str.toLowerCase()

  if (!str) {
    return ''
  } else if (isAlreadyCamelCase) {
    return str
  } else {
    // Replace underscores and dashes with spaces
    // Replace multiple spaces with single spaces
    // Then split, trim and capitalize the first letter of each word
    // Then remove spaces
    // Then lowercase the first letter
    // Then join the words together
    return str
      .replace(/[_-]/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .split(' ')
      .map((w) => w.trim())
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : cap(word.toLowerCase()),
      )
      .join('')
  }
}

export function i18nNumber(
  number: number,
  languageCode: SupportedLanguage,
  options?: Intl.NumberFormatOptions,
): string {
  return new Intl.NumberFormat(languageCode, options).format(number)
}

// Naive check for whether a string is a uuidv7
// E.g. 24ad0254-e91a-425d-bb64-925f8235f63a
// 36 characters, 32 hex characters and 4 hyphens
export function isUuidv7(str: string | undefined): boolean {
  if (!str) {
    return false
  } else {
    const match = str.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    )
    return match !== null
  }
}

export function dateStringToUTCMidnight(dateString: string): Date {
  const date = new Date(
    parseInt(dateString.split('-')[0], 10),
    parseInt(dateString.split('-')[1], 10) - 1,
    parseInt(dateString.split('-')[2], 10),
    0,
    0,
    0,
  )

  return new Date(date)
}
