import React, { useState, useMemo } from 'react'
import i18n from '../../../../i18n/i18nnext'
import { useTranslation } from 'react-i18next'
import { useColorModeValue } from 'native-base'
import { useSelector } from '../../../../ducks/root-reducer'
import { selectAllArtStyleOptions } from '../../../../ducks/ui/ui'
import { ImageGridSelect } from './image-grid-select'
import { Column } from '../../column/column'
import { TextArea } from '../input-textarea'
import { Row } from '../../row/row'
import { CheckboxField } from '../checkbox/field-checkbox'
import { BLACK, WHITE } from '../../../../constants/ui-constants'
import { isUuidv7 } from '../../../../modules/strings/string-helpers'
import { AlertPopup } from '../../dialog/alert-dialog'
import { titleCase } from '../../../../modules/strings/title-case'
import { Dimensions } from 'react-native'
import {
  selectDefaultArtStyle,
  selectUserIsPremium,
} from '../../../../ducks/user/user'
import { PremiumBadge } from '../../badge/premium-badge'
import {
  ArtStyleKey,
  FormFieldOption,
  SupportedLanguage,
} from '../../../../../../api/frontend-types'
import {
  MEDIUM_FONT_SIZE,
  SMALL_FONT_SIZE,
} from '../../../../constants/constants'
import useContentWidth from '../../../../hooks/useContentWidth'

const MODERN_ILLUSTRATION = require('../../../../assets/images/art-styles/modern-illustration.jpg')
const OLD_ILLUSTRATION = require('../../../../assets/images/art-styles/old-illustration.jpg')
const STORYBOOK = require('../../../../assets/images/art-styles/storybook.jpg')
const WOODBLOCK = require('../../../../assets/images/art-styles/woodblock.jpg')
const COLLAGE = require('../../../../assets/images/art-styles/surrealist-collage.jpg')
const COMIC = require('../../../../assets/images/art-styles/comic-book.jpg')
const SURPRISE_ME = require('../../../../assets/images/art-styles/surprise-me.jpg')
const RETRO_CAMERA = require('../../../../assets/images/art-styles/retro-camera.jpg')
const NO_ILLUSTRATION = require('../../../../assets/images/art-styles/no-illustration.jpg')

// Dictionary keyed by ArtStyleKey
const images: Record<ArtStyleKey, any> = {
  default: MODERN_ILLUSTRATION,
  old_illustration: OLD_ILLUSTRATION,
  storybook: STORYBOOK,
  surrealist_collage: COLLAGE,
  comic_book: COMIC,
  woodblock: WOODBLOCK,
  custom: SURPRISE_ME,
  none: NO_ILLUSTRATION,
  holga: RETRO_CAMERA,
  dalle3_plain: SURPRISE_ME,
}

type SelectWithActionSheetProps = {
  onChange: (value: FormFieldOption) => void
  value: string | undefined
  fieldName: string
  premiumAlertTitle?: string
  premiumAlertMsg?: string
  showCustomCheckbox?: boolean
}

export const ImageGridSelectArtStyle = ({
  onChange,
  value,
  fieldName,
  premiumAlertTitle,
  premiumAlertMsg,
  showCustomCheckbox = true,
}: SelectWithActionSheetProps) => {
  // STATE
  // Keep track of the last selected art style (at least for one click)
  // For if user interacts with the custom check box
  // and then goes back to selecting an art style, or vice versa
  const [prevStyle, setPrevStyle] = useState<string | undefined>(value)
  const [premiumWarningDialogOpen, setPremiumWarningDialogOpen] =
    useState(false)

  // SELECTORS
  const artStylesNoImages = useSelector(selectAllArtStyleOptions)
  const defaultArtStyleId = useSelector(selectDefaultArtStyle)
  const userIsPremium = useSelector(selectUserIsPremium)

  // HOOKS
  const { t } = useTranslation()
  const color = useColorModeValue(BLACK, WHITE)

  // VARS
  const userLanguage = (i18n.resolvedLanguage || 'en') as SupportedLanguage
  const isCustomInput = isCustomValue(value) // No art style id
  const defaultAlertMsg = t('addDreamPage.upgradeForArtStyleWarning')
  const defaultAlertTitle = t('common.premiumFeature')
  const alertTitle = premiumAlertTitle || defaultAlertTitle
  const alertMsg = premiumAlertMsg || defaultAlertMsg
  const screenWidth = Dimensions.get('window').width
  const { paddedContentWidth } = useContentWidth()
  const premiumBadgeWidth = 30
  const checkboxWidth = paddedContentWidth - premiumBadgeWidth
  const captionFontSize = getCaptionFontSize(userLanguage, screenWidth)
  const isRtl = i18n.dir() === 'rtl'

  // MEMOS
  const artStylesWithImages: FormFieldOption[] = useMemo(() => {
    return artStylesNoImages.map((artStyle) => {
      const key = getArtStyleKeyFromLanguageKey(artStyle.languageKey || '')
      return {
        ...artStyle,
        imageObject: images[key],
      }
    })
  }, [artStylesNoImages])

  return (
    <>
      <Column>
        <ImageGridSelect
          options={artStylesWithImages}
          onChange={(newValue: FormFieldOption) => {
            // Don't do anything if value and newValue are the same
            if (value === newValue.value) {
              return
            }
            setPrevStyle(value)
            onChange(newValue)
          }}
          value={value}
          premiumAlertTitle={titleCase(alertTitle, userLanguage)}
          premiumAlertMsg={alertMsg}
          fontSize={captionFontSize}
        />
        {showCustomCheckbox && (
          <>
            <Row mt={8} mb={6} alignItems={'center'}>
              <CheckboxField
                _checked={{
                  bg: color,
                  borderColor: color,
                }}
                width={checkboxWidth}
                onChange={(checked) => {
                  // User is not premium, show alert
                  if (!userIsPremium) {
                    setPremiumWarningDialogOpen(true)
                  }
                  // Unchecking the box
                  else if (!checked) {
                    setPrevStyle(value)
                    onChange({
                      label: '',
                      value: prevStyle || defaultArtStyleId,
                    })
                  }
                  // Checking the box
                  else {
                    const valueToPopulate = isCustomValue(prevStyle)
                      ? prevStyle
                      : ''
                    setPrevStyle(value)
                    onChange({
                      label: 'artStyles.custom',
                      value: valueToPopulate || '',
                    })
                  }
                }}
                value="custom"
                isChecked={isCustomInput}
              >
                {t('addDreamPage.writeMyOwnPrompt')}
              </CheckboxField>
              <PremiumBadge
                ml={isRtl ? undefined : 2}
                mr={isRtl ? 2 : undefined}
              />
            </Row>
            {isCustomInput && (
              <>
                <TextArea
                  fieldName={fieldName}
                  minRows={2}
                  minHeight={100}
                  placeholder={`${t('common.forExample')} ${t(
                    'artStyles.examplePrompt',
                  )}`}
                  value={value}
                  onChangeText={(text: string) => {
                    onChange({
                      value: text,
                      label: 'artStyles.custom',
                    })
                  }}
                />
              </>
            )}
          </>
        )}
      </Column>
      <AlertPopup
        isOpen={premiumWarningDialogOpen}
        header={titleCase(alertTitle, userLanguage)}
        description={alertMsg}
        onClose={() => {
          setPremiumWarningDialogOpen(false)
        }}
        onConfirm={() => {
          setPremiumWarningDialogOpen(false)
        }}
        hideCancel={true}
        confirmLabel={t('common.ok')}
      />
    </>
  )
}

// "artStyles.old_illustration" -> "old_illustration"
function getArtStyleKeyFromLanguageKey(languageKey: string): ArtStyleKey {
  const validKey = languageKey && languageKey.split('.')[1]
  if (!validKey) {
    return 'default'
  }
  return languageKey.split('.')[1] as ArtStyleKey
}

function isCustomValue(value: string | undefined) {
  return !isUuidv7(value)
}

function getCaptionFontSize(
  userLanguage: SupportedLanguage,
  screenWidth: number,
): number {
  const smallerIfMobile = screenWidth < 500 ? SMALL_FONT_SIZE : MEDIUM_FONT_SIZE

  switch (userLanguage) {
    case 'el':
      return smallerIfMobile
    case 'ru':
      return smallerIfMobile
    case 'uk':
      return smallerIfMobile
    case 'pl':
      return smallerIfMobile
    default:
      return MEDIUM_FONT_SIZE
  }
}
