import { SupportedLanguage } from '../../../../../api/frontend-types'
import {
  ColorTagSubType,
  PickerI18nLayout,
} from '../../../modules/color/color-types'

// Languages that see light and dark blue as distinct colors
export const LANGUAGES_WITH_BLUE_DISTINCTION: SupportedLanguage[] = [
  'ru',
  'uk',
  'pl',
  'el',
  'he',
  'it',
]

// Different layouts have different color orders
export const COLOR_ORDER: { [key in PickerI18nLayout]: ColorTagSubType[] } = {
  standard: [
    'pink',
    'red',
    'orange',
    'yellow',
    'brown',
    'green',
    'blue',
    'purple',
    'black',
    'gray',
    'white',
    'metallic',
    'gold',
    'silver',
    'bronze',
  ],
  'blue-distinct': [
    'black',
    'gray',
    'white',
    'brown',
    'purple',
    'pink',
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'metallic',
    'gold',
    'silver',
    'bronze',
  ],
}
