import { FastTag } from '../../../../../api/_openapi'
import { createTag } from '../../../ducks/dream-tag/functions/tag-functions'
import {
  extractColorParts,
  isSimpleColor,
} from '../../../modules/color/color-helpers'
import { SIMPLE_COLOR_LABEL } from '../../../modules/color/colorConstants'
import { colorMap } from '../../../modules/color/colorList'
import { camelCase } from '../../../modules/strings/string-helpers'
import { PickerColors } from './color-picker'
import {
  colorToOldColorString,
  hslToHexString,
} from '../../../modules/color/color-string-helpers'
import {
  Color,
  ColorDefinition,
  ColorTagSubType,
  ComplexColor,
  PickerI18nLayout,
  SimpleColor,
} from '../../../modules/color/color-types'
import { LANGUAGES_WITH_BLUE_DISTINCTION } from './color-picker-config'
import { SupportedLanguage } from '../../../../../api/frontend-types'

export function getPickerLayoutForLanguage(
  languageCode: SupportedLanguage,
): PickerI18nLayout {
  if (LANGUAGES_WITH_BLUE_DISTINCTION.includes(languageCode)) {
    return 'blue-distinct'
  } else {
    return 'standard'
  }
}

// The front end uses 'metallic' as a simple color
// But the backend only recognises gold, silver and bronze
// So we need to convert FE to BE colors
export function simpleColorValidify(
  complexColor: ComplexColor,
  simpleColor: SimpleColor,
): SimpleColor {
  switch (complexColor) {
    case 'gold':
      return 'gold'
    case 'silver':
      return 'silver'
    case 'bronze':
      return 'bronze'
    default:
      return simpleColor
  }
}

// Extracts the color from a tag
// We maintain backwards compatibility with the old color tags just in case
// Which are in the form: { name: `simpleColor (complexColor) hex`, subType: '' }
// And new tags which are in the form: { name: complexColor, subType: simpleColor } (no hex)
export function getColorFromTag(name: string, subType: ColorTagSubType): Color {
  let colorName = 'black'
  let simpleColor: ColorTagSubType = 'black'
  let hex = '#000'

  // We make sure the subtype is a simple color
  let simpleColorValid: ColorTagSubType = subType
  if (isSimpleColor(subType)) {
    simpleColorValid = subType
  }

  // 1) If it's an old tag, extract the color parts from the name
  if (isOldTagNameFormat(name)) {
    const [specificColor, generalColor, hexColor] = extractColorParts(name)
    colorName = camelCase(specificColor)
    simpleColor = generalColor
    hex = hexColor
  }
  // 2) Otherwise, assume it's in the new format
  else {
    const n = camelCase(name)
    const colorDef: ColorDefinition | undefined = colorMap[n as ComplexColor]
    if (colorDef) {
      colorName = n
      simpleColor = simpleColorValid
      hex = hslToHexString(...colorDef.hsl)
    } else {
    }
  }
  const color = {
    colorName,
    simpleColor,
    hex,
  }

  return color
}

// If the color name contains a hex code, it's in the old format
export function isOldTagNameFormat(tagName: string) {
  return tagName.includes('#')
}

// Given:
// 1) A color
// 2) The existing colors
// 3) The tags
// Find the tags to remove when removing a color
export function findTagsToRemove(
  color: Color,
  existingColors: PickerColors,
  tags: FastTag[],
): FastTag[] {
  const { colorName, simpleColor } = color
  const { simpleColors, complexColors } = existingColors

  const colorTagLabel1 = colorName
  const colorTagLabel2 = colorToOldColorString(color)

  let tagsToRemove: FastTag[] = []

  // 1) Check if we're removing the last tag of a given color type
  // In that case, remove the simple color tag as well
  const colorsOfType = complexColors.filter(
    (c) => c.simpleColor === simpleColor,
  )
  const removingLastTagOfType = colorsOfType.length === 1

  if (removingLastTagOfType) {
    // Find the corresponding simple color
    const simpleColorObj = simpleColors.find((c) => c.colorName === simpleColor)
    // Find the simple color tag
    if (simpleColorObj) {
      const simpleColorTag = tags.find((t) => {
        return (
          t.name === simpleColorObj.colorName &&
          t.subType === SIMPLE_COLOR_LABEL
        )
      })
      if (simpleColorTag) {
        tagsToRemove.push(simpleColorTag)
      } else {
        console.log(
          'Simple color tag not found for simpleColorObj:',
          simpleColorObj,
        )
      }
    }
  }

  const tag = tags.find((t) => {
    const ccName = camelCase(t.name)
    const match1 = ccName === colorTagLabel1 || ccName === colorTagLabel2
    const match2 = t.name === colorTagLabel1 || t.name === colorTagLabel2
    return match1 || match2
  })
  if (tag) {
    tagsToRemove.push(tag)
  }

  return tagsToRemove
}

// If a user adds a colour, we
// 1) Create a complex color tag and add it to the backend
// 2) Check if the simple colour is already in the list, and if not, add it
export function addColorTag(
  newColor: Color,
  existingColors: PickerColors,
  languageCode: string,
  onSuccessCallback: (tag: FastTag[]) => void,
  onErrorCallback: () => void,
) {
  const { colorName, simpleColor } = newColor

  console.log('adding color: ', newColor)

  const complexColorTag: Partial<FastTag> = {
    name: colorName,
    type: 'COLOR',
    subType: simpleColor,
  }

  const simpleColorTag: Partial<FastTag> = {
    name: simpleColor,
    type: 'COLOR',
    subType: SIMPLE_COLOR_LABEL,
  }

  const simpleColorTagExists = existingColors.simpleColors.some(
    (c: Color) => c.colorName === simpleColor,
  )

  createTag(complexColorTag, languageCode).then((tag) => {
    // 1) If no tag created, error
    if (!tag) {
      onErrorCallback()
    }
    // 2) If the simple color tag already exists, return the tag
    else if (tag && simpleColorTagExists) {
      onSuccessCallback([tag])
    }
    // 3) If simple color tag doesn't exist, create it and return both tags
    else {
      createTag(simpleColorTag, languageCode).then((simpleTag) => {
        if (!simpleTag) {
          onErrorCallback()
        } else {
          onSuccessCallback([tag, simpleTag])
        }
      })
    }
  })
}
