import React, { useMemo, useState } from 'react'
import { FastTag } from '../../../../../api/_openapi'
import { ColorPicker, PickerColors } from './color-picker'
import { elsewhereToast } from '../../common/toast/toast'
import { SIMPLE_COLOR_LABEL } from '../../../modules/color/colorConstants'
import { Color, ColorTagSubType } from '../../../modules/color/color-types'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUserIsPremium } from '../../../ducks/user/user'
import {
  addColorTag,
  findTagsToRemove,
  getColorFromTag,
  getPickerLayoutForLanguage,
} from './picker-helpers'
import i18n from '../../../i18n/i18nnext'
import { SupportedLanguage } from '../../../../../api/frontend-types'

type TagColorPickerProps = {
  tags: FastTag[]
  onAddTags: (tags: FastTag[]) => void
  onRemoveTags: (tags: FastTag[]) => void
}

// Wraps the simple ColorPicker component
// by extracting the necessary parts from the tags
export const TagColorPicker = ({
  tags,
  onAddTags,
  onRemoveTags,
}: TagColorPickerProps) => {
  // STATE
  const [loading, setLoading] = useState(false)

  // SELECTORS
  const userIsPremium = useSelector(selectUserIsPremium)

  // VARS
  const userLanguage = (i18n.resolvedLanguage || 'en') as SupportedLanguage
  const pickerLayout = getPickerLayoutForLanguage(userLanguage)

  // FUNCTIONS
  const onAddColor = (newColor: Color, colors: PickerColors) => {
    setLoading(true)
    const onTagsAddSuccess = (tags: FastTag[]) => {
      onAddTags(tags)
      setLoading(false)
    }
    const onTagsAddError = () => {
      elsewhereToast.showToast({
        title: 'toast.errorCreatingTag.title',
        description: 'toast.errorGeneric.description',
      })
      setLoading(false)
    }
    addColorTag(newColor, colors, 'en', onTagsAddSuccess, onTagsAddError)
  }

  const onRemoveColor = (color: Color, existingColors: PickerColors) => {
    const tagsToRemove = findTagsToRemove(color, existingColors, tags)
    onRemoveTags(tagsToRemove)
  }

  // Extract necessary parts from the tag name
  const colors: PickerColors = useMemo(() => {
    const allColors: PickerColors = tags.reduce(
      (acc: PickerColors, curr: FastTag) => {
        const subType = curr.subType as ColorTagSubType
        const isSimpleColor = curr.subType === SIMPLE_COLOR_LABEL
        const color: Color = getColorFromTag(curr.name, subType)
        if (isSimpleColor) {
          return {
            ...acc,
            simpleColors: [...acc.simpleColors, color],
          }
        } else {
          return {
            ...acc,
            complexColors: [...acc.complexColors, color],
          }
        }
      },
      { simpleColors: [], complexColors: [] } as PickerColors,
    )
    return allColors
  }, [tags])

  return (
    <ColorPicker
      colors={colors}
      loading={loading}
      showAdvancedToggle={userIsPremium}
      onAddColor={onAddColor}
      onRemoveColor={onRemoveColor}
      pickerLayout={pickerLayout}
    />
  )
}
