import React from 'react'
import { BodyText } from '../common/copy/text-body'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Box, InterfaceBoxProps } from '../common/box/box'
import ConditionalLink from '../../utilities/ConditionalLink'

type QuoteProps = InterfaceBoxProps & {
  quote: string
  author?: string
  source?: string
}

export const Quote = ({
  quote,
  author,
  source,
  mt = 2,
  mb = 2,
  fontSize = 'md',
  maxWidth = WEB_MAX_WIDTH,
}: QuoteProps) => {
  return (
    <Box
      mt={mt}
      mb={mb}
      paddingLeft={4}
      paddingRight={4}
      flexDirection={'column'}
      alignItems={'center'}
      maxWidth={maxWidth}
      mx="auto"
    >
      <BodyText
        type="gray"
        mb={0}
        fontSize={fontSize}
        textAlign="center"
      >{`“${quote}”`}</BodyText>
      {author && (
        <BodyText type="gray" fontSize={fontSize} fontStyle={'italic'}>
          {`${author}${source ? ',' : ''}`}
        </BodyText>
      )}
      <BodyText mt={1} type="gray" fontSize={fontSize} fontStyle={'italic'}>
        {source}
      </BodyText>
    </Box>
  )
}
