import React from 'react'
import { Pressable } from 'react-native'
import { Row } from '../../common/row/row'

type TriangleProps = {
  backgroundColor?: string
  height?: number
  width?: number
  left?: number | string
  bottom?: number
  onPress?: () => void
}

export const PeaceSignTriangle = ({
  backgroundColor = 'red',
  height = 100,
  width = 100,
  left,
  bottom,
  onPress,
}: TriangleProps) => {
  const halfWidth = width / 2

  return (
    <Pressable
      onPress={onPress}
      style={{ position: 'absolute', width: '100%', bottom: 0 }}
    >
      <Row
        left={left}
        bottom={bottom}
        position={'absolute'}
        style={{
          borderBottomColor: backgroundColor,
          width: 0,
          height: 0,
          borderLeftWidth: halfWidth, // Half of the triangle's base
          borderRightWidth: halfWidth, // Half of the triangle's base
          borderBottomWidth: height, // Triangle's height
          borderLeftColor: 'transparent',
          borderRightColor: 'transparent',
        }}
      />
    </Pressable>
  )
}
