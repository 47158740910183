import React, { useEffect, useMemo, useState } from 'react'
import { useColorModeValue, useColorMode } from 'native-base'
import { Dimensions } from 'react-native'
import {
  HorizontalBarChart,
  HorizontalBarChartDatum,
} from '../../components/composite/stats/horizontal-bar-chart'
import { NoContentMessage } from '../../components/layout/no-content-message'
import { TagIcon } from '../../components/composite/tags/tag-icon'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { dateRangeFromKey } from './chart-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { ChartTitleText } from '../../components/composite/chart/chart-title'
import { FEW } from '../../i18n/config'
import { useRoute } from '@react-navigation/core'
import { RouteProp } from '@react-navigation/native'
import {
  DateRangeWithKey,
  TagType,
  TagTypeBuckets,
  TagTypeWithoutMoonPhase,
} from '../../../../api/frontend-types'
import { MainStackParamList, RootStackParamList } from '../../navigation/types'
import { CountedFastTag, CountedFastTagService } from '../../../../api/_openapi'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { useActiveGroupId } from '../../hooks/useActiveGroupId'
import { Row } from '../../components/common/row/row'
import { Box } from '../../components/common/box/box'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import { getTagI18nKey } from '../../modules/tag-helpers/tag-helpers'

type TagChartProps = {
  dateRangeWithKey: DateRangeWithKey
}

const TOTAL_PAGE_MARGIN = 32
const NUM_TAGS_TO_SHOW = 10

export const TagCharts = ({ dateRangeWithKey }: TagChartProps) => {
  // SELECTORS
  const route = useRoute<
    | RouteProp<MainStackParamList, 'PrivateCharts' | 'PrivateTagView'>
    | RouteProp<
        RootStackParamList,
        'PrivateDreamerView' | 'GroupDreamerView' | 'LibraryDreamerView'
      >
  >()
  const activeGroupId = useActiveGroupId()

  const emptyTagBuckets = {
    CHARACTER: [],
    SETTING: [],
    EMOTION: [],
    ACTION: [],
    ANIMAL: [],
    THEME: [],
    DREAM_TYPE: [],
    OTHER: [],
    COLOR: [],
    SYMBOL: [],
    NONE: [],
  }

  const [topTagBuckets, setTopTagBuckets] = useState<TagTypeBuckets>({
    ...emptyTagBuckets,
  })

  // HOOKS
  const { t } = useTranslation()
  const { colorMode } = useColorMode()
  const textAndIconColor = useColorModeValue(BLACK, WHITE)

  // PAGE VARIABLES
  const pageWidth = Dimensions.get('window').width

  const chartLabels: { [key in TagTypeWithoutMoonPhase]: string } = {
    CHARACTER: t('tagType.character_plural', { count: FEW }),
    SETTING: t('tagType.setting_plural', { count: FEW }),
    EMOTION: t('tagType.emotion_plural', { count: FEW }),
    ACTION: t('tagType.action_plural', { count: FEW }),
    ANIMAL: t('tagType.animal_plural', { count: FEW }),
    OTHER: t('tagType.other_plural', { count: FEW }),
    THEME: t('tagType.theme_plural', { count: FEW }),
    DREAM_TYPE: t('tagType.dreamType_plural', { count: FEW }),
    COLOR: t('tagType.color_plural', { count: FEW }),
    SYMBOL: t('tagType.symbol_plural', { count: FEW }),
    NONE: 'No Tags',
  }

  // MEMOIZED VALUES
  const dateRange = useMemo(() => {
    return dateRangeFromKey(dateRangeWithKey.key)
  }, [dateRangeWithKey])

  useEffect(() => {
    if (activeGroupId) {
      const startDate = dateRange.start
        ? formatDateForDatabase(dateRange.start)
        : undefined
      const endDate = dateRange.end
        ? formatDateForDatabase(dateRange.end)
        : undefined

      CountedFastTagService.countedTypeTags(
        activeGroupId,
        undefined,
        NUM_TAGS_TO_SHOW,
        startDate,
        endDate,
      ).then((data: CountedFastTag[]) => {
        //format data
        const tagBuckets: TagTypeBuckets = { ...emptyTagBuckets }
        data.forEach((tag) => {
          // May not exist if the backend adds a new tag type
          if (tagBuckets[tag.type as TagTypeWithoutMoonPhase]) {
            tagBuckets[tag.type as TagTypeWithoutMoonPhase].push({
              tag: { ...tag },
              count: tag.count,
            })
          }
        })
        setTopTagBuckets(tagBuckets)
      })
    }
  }, [activeGroupId, dateRange])

  const topTagBucketsValues = Object.entries(topTagBuckets)
  const topTagBucketsKey = topTagBucketsValues
    .map(([key, value]) => {
      return key + value.map((tag) => tag.tag.id).join('')
    })
    .join('')

  const allData = useMemo(() => {
    const data = Object.entries(topTagBuckets)
      .filter(
        ([key]) => key !== 'NONE' && key !== 'SYMBOL' && key !== 'MOON_PHASE',
      )
      .map(([key, value]) => {
        const chartTitle = chartLabels[key as TagTypeWithoutMoonPhase]
        const bars = value.map((countedTag) => {
          const { tag, count } = countedTag
          const { type, name } = tag

          // Themes and dreamtypes should be translated
          const barLabel =
            type === 'DREAM_TYPE' || type === 'THEME'
              ? t(getTagI18nKey(tag))
              : name

          return {
            x: barLabel,
            y: count,
            tag: tag,
          }
        })
        return { key, chartTitle, bars }
      })
    return data.filter(({ bars }) => bars.length > 0)
  }, [topTagBuckets, topTagBucketsKey])

  return (
    <Box>
      <PaddedContentAreaConditional alignItems={'center'}>
        {allData.length === 0 && (
          <NoContentMessage
            message={t('chartsPage.noDataInRangeMessage')}
            mt={16}
          />
        )}
      </PaddedContentAreaConditional>
      {allData.length > 0 && (
        <PaddedContentAreaConditional>
          {allData.map(({ key, chartTitle, bars }) => {
            return (
              <Box key={`${activeGroupId}${chartTitle}`} pl={3}>
                <Row
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                  mb={-2}
                  mt={2}
                >
                  {/* Tag icon */}
                  <TagIcon
                    friend={undefined}
                    iconKey={key as Exclude<TagType, 'MOON_PHASE'>}
                    iconStroke={textAndIconColor}
                    ml={0}
                  />
                  <ChartTitleText color={textAndIconColor} width="100%">
                    {chartTitle}
                  </ChartTitleText>
                </Row>
                <Row mb={8}>
                  <HorizontalBarChart
                    width={pageWidth - TOTAL_PAGE_MARGIN}
                    maxWidth={WEB_MAX_WIDTH}
                    barHeight={15}
                    data={bars as HorizontalBarChartDatum[]}
                  />
                </Row>
              </Box>
            )
          })}
        </PaddedContentAreaConditional>
      )}
    </Box>
  )
}
