import React, { useEffect, useMemo, useState } from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { View } from 'react-native'
import { BodyText } from '../../components/common/copy/text-body'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { UserTagDescription } from './user-tag-description'
import { SymbolComponent } from '../../assets/symbols'
import { TagViewNavigationProp } from '.'
import DreamMap from '../Dreams/DreamMap/DreamMap'
import { DreamPoint } from '../../../../api/frontend-types'
import { useSelector } from '../../ducks/root-reducer'
import { selectIsElsewhereTeam, selectUser } from '../../ducks/user/user'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import { useTranslation } from 'react-i18next'
import TagViewImage from './tag-view-image'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { Loading } from '../../components/layout/loading'
import { Row } from '../../components/common/row/row'
import DreamHighlight from '../Characters/DreamHighlight'
import {
  getTagI18nKey,
  getTagLabel,
} from '../../modules/tag-helpers/tag-helpers'
import {
  CountedFastTag,
  FastGroup,
  FastTag,
  FastTagLocationService,
  TagHighlight,
  TagHighlightService,
  TagSummaryStats,
  TagSummaryStatsService,
} from '../../../../api/_openapi'
import { Center } from '../../components/common/center/center'

const MIN_RELATED_TAGS = 4

export type TagPageHeaderNavigationProp = TagViewNavigationProp

type TagPageHeaderProps = {
  title: string
  fullTag: FastTag | null
  currentGroup: FastGroup | null
  pageWidth: number
  SymbolComponent?: SymbolComponent
  userCanSeeMap?: boolean
  isMoonPhaseTag?: boolean
}

export const TagPageHeader = ({
  title,
  fullTag,
  currentGroup,
  pageWidth,
  userCanSeeMap,
  isMoonPhaseTag,
}: TagPageHeaderProps) => {
  // STATE
  const [loading, setLoading] = useState(true)
  const [tagHighlights, setTagHighlights] = useState<TagHighlight[]>([])
  const [dreamPoints, setDreamPoints] = useState<DreamPoint[] | null>(null)
  const [summaryStats, setSummaryStats] = useState<TagSummaryStats>({
    id: '',
    tagType: 'NONE',
    tagName: '',
    numDreamsWithTag: 0,
    percent: 0,
    relatedTags: [],
  })

  // HOOKS
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const { t } = useTranslation()

  // SELECTORS
  const user = useSelector(selectUser)
  const userIsTeam = useSelector(selectIsElsewhereTeam)

  // EFFECTS
  useEffect(() => {
    const isSettingTag = fullTag?.type === 'SETTING'
    if (isSettingTag && dreamPoints === null && user && userIsTeam) {
      FastTagLocationService.tagLocations(fullTag.id)
        .then((results) => {
          setDreamPoints(results)
        })
        .catch((error) => {
          console.error('Error with apiTagLocationsGetCollection', error)
        })
    }
  }, [fullTag, user])

  useEffect(() => {
    if (fullTag?.id) {
      TagHighlightService.tagHighlights(fullTag.id, 30).then((highlights) => {
        //get one random highlight
        const randomHighlight =
          highlights[Math.floor(Math.random() * highlights.length)]
        setTagHighlights([randomHighlight])
      })
    }
  }, [fullTag?.id])

  // VARS
  let { numDreamsWithTag, percent, relatedTags } = summaryStats

  // MEMOS
  const tagDescriptionString: string = useMemo(() => {
    return generateRelatedTagsString(
      relatedTags,
      percent,
      fullTag,
      isMoonPhaseTag,
      numDreamsWithTag,
      t,
    )
  }, [relatedTags, fullTag, isMoonPhaseTag, numDreamsWithTag, percent])

  //load the tag summary stats
  useEffect(() => {
    if (fullTag && currentGroup) {
      TagSummaryStatsService.tagSummaryStats(fullTag.id, currentGroup.id)
        .then((stats: TagSummaryStats) => {
          setSummaryStats(stats)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [fullTag, currentGroup?.id])

  return (
    <View
      pointerEvents="box-none"
      style={{
        backgroundColor,
        maxWidth: WEB_MAX_WIDTH,
        width: '100%',
        alignItems: 'center',
      }}
    >
      <TagViewImage
        name={fullTag?.name}
        imageUrl={fullTag?.imageUrl || undefined}
        type={fullTag?.type}
        pageWidth={pageWidth}
      />
      {/* {fullTag?.type === 'CHARACTER' && (
        <ButtonPill
          mt={2}
          buttonSize={'xs'}
          width={'auto'}
          onPress={() => {
            navigation.navigate('PrivateTagImageAdd', {
              id: fullTag?.id,
              name: fullTag?.name,
            })
          }}
        >
          {fullTag?.imageId ? 'Edit' : 'Add'} Image
        </ButtonPill>
      )} */}
      <PaddedContentArea mb={0} pointerEvents="none">
        <HeadingMain mt={4} mb={4}>
          {title}
        </HeadingMain>
        {loading ? (
          <Center w="100%">
            <Loading />
          </Center>
        ) : (
          <BodyText
            textAlign={tagDescriptionString.length < 50 ? 'center' : 'left'}
          >
            {tagDescriptionString}
          </BodyText>
        )}
      </PaddedContentArea>
      {tagHighlights.map((highlight) => (
        <Row mt={2} key={highlight?.id}>
          <DreamHighlight highlight={highlight} size={'md'} maxWidth={450} />
        </Row>
      ))}
      {!isMoonPhaseTag && <UserTagDescription tag={fullTag} tagName={title} />}
      {userCanSeeMap && fullTag?.type === 'SETTING' && (
        <PaddedContentAreaConditional mb={4} webPadding={0}>
          <HeadingMain mt={8} mb={4}>
            {t('common.map')}
          </HeadingMain>
          <DreamMap
            dream={null}
            mode={'tag'}
            tag={fullTag}
            dreamPoints={dreamPoints || []}
            allowAdding={true}
            setDreamPoints={setDreamPoints}
            useCurrentLocation={true}
          />
        </PaddedContentAreaConditional>
      )}
    </View>
  )
}

const generateRelatedTagsString = (
  relatedTags: CountedFastTag[],
  percent: number,
  fullTag: FastTag | null,
  isMoonPhaseTag: boolean | undefined,
  numDreamsWithTag: number,
  t: any,
): string => {
  const showPercent = percent >= 1
  let tagName = getTagLabel(fullTag?.name, fullTag?.type, fullTag?.subType, t)

  // i18n
  const baseKey = isMoonPhaseTag ? 'tagPage.moonPhase' : 'tagPage'

  // 1) Tag summary string (e.g. "Dan is in 10 of your dreams")
  const summaryKey = `${baseKey}.tagDescription.numDreams`
  const tagSummaryString = t(summaryKey, { numDreamsWithTag, tagName })

  // 2) Tag percent string (e.g. "That's 5% of your dreams")
  const tagI18nKey = `${baseKey}.tagDescription.percent`
  const tagPercentString = t(tagI18nKey, { percent })

  // 3) Related tags string (e.g "Dan is related to the tags: John, Mary, and Bob")
  const translatedTags: string[] = relatedTags.map(getTagI18nKey).map(t)
  const lowerCaseTags: string[] = translatedTags.map((tag) => tag.toLowerCase())
  const uniqueTags: string[] = [...new Set(lowerCaseTags)]
  const showRelatedTags = uniqueTags.length >= MIN_RELATED_TAGS
  const [relatedTag1, relatedTag2, relatedTag3, relatedTag4] = uniqueTags
  const relatedI18nKey = `${baseKey}.tagDescription.relatedTags`
  const i18nProps = {
    tagName,
    relatedTag1,
    relatedTag2,
    relatedTag3,
    relatedTag4,
  }
  const relatedTagsStr = showRelatedTags ? t(relatedI18nKey, i18nProps) : ''

  // Create the whole string
  let descString = ''
  if (showRelatedTags && showPercent) {
    descString = `${tagSummaryString} ${tagPercentString} ${relatedTagsStr}`
  } else if (showPercent) {
    descString = `${tagSummaryString} ${tagPercentString}`
  } else if (showRelatedTags) {
    descString = `${tagSummaryString} ${relatedTagsStr}`
  } else {
    descString = `${tagSummaryString}`
  }
  return descString
}
