import React, { useEffect, useMemo, useState } from 'react'
import { useColorModeValue, useColorMode } from 'native-base'
import { useTranslation } from 'react-i18next'
import {
  BLACK,
  DARKER_CREAM,
  LIGHTER_BLACK,
  PLACEHOLDER_COLOR_DARK,
  PLACEHOLDER_COLOR_LIGHT,
  WHITE,
} from '../../constants/ui-constants'
import { dateRangeFromKey } from './chart-helpers'
import { ChartTitleText } from '../../components/composite/chart/chart-title'
import { FEW } from '../../i18n/config'
import {
  DateRangeWithKey,
  Hemisphere,
  MoonPhase,
} from '../../../../api/frontend-types'
import { CountedFastTag, CountedFastTagService } from '../../../../api/_openapi'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { useActiveGroupId } from '../../hooks/useActiveGroupId'
import { Row } from '../../components/common/row/row'
import { Box } from '../../components/common/box/box'
import { SansText } from '../../components/common/copy/text-sans'
import { useNavigation } from '@react-navigation/native'
import { PressableFixed } from '../../utilities/PressableFixed'
import { MainStackNavigationProp } from '../../navigation/types'
import { Column } from '../../components/common/column/column'
import { useSelector } from '../../ducks/root-reducer'
import { selectUserIsPremium } from '../../ducks/user/user'
import { PremiumBadge } from '../../components/common/badge/premium-badge'
import { PleaseSubscribeDialog } from '../../components/composite/subscriptions/please-subscribe-dialog'
import { useHemisphere } from '../../hooks/useHemisphere'
import { Moon } from '../../components/composite/moon/moon'
import { HighlightBox } from '../../components/layout/highlight-box'
import useContentWidth from '../../hooks/useContentWidth'

type MoonPhasesChartProps = {
  dateRangeWithKey: DateRangeWithKey
  moonPhaseData?: CountedFastTag[]
  skipDataFetch?: boolean
}

const NUM_TAGS_TO_SHOW = 10

const MOON_PHASE_ORDER: MoonPhase[] = [
  'newMoon',
  'waxingCrescent',
  'firstQuarter',
  'waxingGibbous',
  'fullMoon',
  'waningGibbous',
  'lastQuarter',
  'waningCrescent',
]

export const MoonPhasesChart = ({
  dateRangeWithKey,
  moonPhaseData: initialMoonPhaseData,
  skipDataFetch,
}: MoonPhasesChartProps) => {
  const navigation = useNavigation<MainStackNavigationProp<any>>()
  const activeGroupId = useActiveGroupId()
  const [moonPhaseTags, setMoonPhaseTags] = useState<CountedFastTag[]>([])
  const [warningDialogIsOpen, setWarningDialogIsOpen] = useState<boolean>(false)

  // HOOKS
  const { t } = useTranslation()
  const { paddedContentWidth } = useContentWidth()
  const { colorMode } = useColorMode()
  const textAndIconColor = useColorModeValue(BLACK, WHITE)
  const moonColor = useColorModeValue(WHITE, WHITE)
  const moonShadowColor = useColorModeValue(BLACK, BLACK)
  const moonBorderColor = useColorModeValue(DARKER_CREAM, LIGHTER_BLACK)
  const disabledColor = useColorModeValue(
    PLACEHOLDER_COLOR_LIGHT,
    PLACEHOLDER_COLOR_DARK,
  )
  const userIsPremium = useSelector(selectUserIsPremium)
  const hemisphere: Hemisphere = useHemisphere()

  const phaseRows = [MOON_PHASE_ORDER.slice(0, 4), MOON_PHASE_ORDER.slice(4)]
  const boxWidth = paddedContentWidth / 4
  const iconSize: number = Math.min(boxWidth * 0.8, 70)

  // MEMOIZED VALUES
  const dateRange = useMemo(() => {
    return dateRangeFromKey(dateRangeWithKey.key)
  }, [dateRangeWithKey])

  useEffect(() => {
    if (skipDataFetch) {
      if (initialMoonPhaseData) {
        setMoonPhaseTags(initialMoonPhaseData)
      }
      return
    }

    if (activeGroupId) {
      const startDate = dateRange.start
        ? formatDateForDatabase(dateRange.start)
        : undefined
      const endDate = dateRange.end
        ? formatDateForDatabase(dateRange.end)
        : undefined

      CountedFastTagService.countedTypeTags(
        activeGroupId,
        'MOON_PHASE',
        NUM_TAGS_TO_SHOW,
        startDate,
        endDate,
      ).then((data: CountedFastTag[]) => {
        if (data.length > 0) {
          // Only update state if we have data
          setMoonPhaseTags(data)
        }
      })
    }
  }, [activeGroupId, dateRange, skipDataFetch, initialMoonPhaseData])

  const handlePhasePress = (id: string) => {
    navigation.push('MainTabs', {
      screen: 'MainStack',
      params: {
        screen: 'PrivateTagView',
        params: {
          id,
        },
      },
    })
  }

  const dataIsPresent = moonPhaseTags.length > 0

  const MoonPhaseRow = ({
    phases,
  }: {
    phases: MoonPhase[]
    isLastRow: boolean
  }) => (
    <Row justifyContent={'space-between'} width={'100%'}>
      {phases.map((phase) => {
        const phaseTag = moonPhaseTags.find(
          (tag) => tag.name === `moonphase.${phase}`,
        )
        const datumIsZero = (phaseTag?.count ?? 0) === 0
        const disableMoon = datumIsZero || !userIsPremium
        return (
          <PressableFixed
            onPress={
              userIsPremium && phaseTag?.count
                ? () => handlePhasePress(phaseTag?.id as string)
                : !userIsPremium
                ? () => setWarningDialogIsOpen(true)
                : undefined
            }
            _hover={{
              opacity: 0.5,
            }}
          >
            <Box key={phase} alignItems="center" justifyContent="center">
              {/* @ts-ignore */}
              {colorMode !== 'auto' && phase && (
                <Moon
                  phase={phase}
                  colorMode={colorMode || 'light'}
                  hemisphere={hemisphere}
                  moonColor={moonColor}
                  borderColor={moonBorderColor}
                  shadowColor={disableMoon ? disabledColor : moonShadowColor}
                  size={iconSize}
                />
              )}
              {userIsPremium && dataIsPresent && (
                <SansText
                  marginY={1}
                  textAlign="center"
                  color={disableMoon ? disabledColor : textAndIconColor}
                >
                  {userIsPremium ? phaseTag?.count || 0 : 0}
                </SansText>
              )}
              {(!userIsPremium || !dataIsPresent) && (
                <SansText marginY={1} textAlign="center" color={disabledColor}>
                  ?
                </SansText>
              )}
            </Box>
          </PressableFixed>
        )
      })}
    </Row>
  )

  return (
    <>
      <Column>
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
          mb={6}
          mt={2}
        >
          <Row alignItems="center" width="100%" justifyContent="center">
            <ChartTitleText
              width={undefined}
              mt={2}
              style={{
                textAlign: 'center',
              }}
              type={!userIsPremium ? 'gray' : undefined}
            >
              {t('tagType.moonPhase_plural', { count: FEW })}
            </ChartTitleText>
            {!userIsPremium && <PremiumBadge ml={2} mt={1} />}
          </Row>
        </Row>

        <HighlightBox
          width={paddedContentWidth}
          padding={4}
          margin={'auto'}
          mb={8}
        >
          {phaseRows.map((rowPhases, index) => (
            <MoonPhaseRow
              key={index}
              phases={rowPhases}
              isLastRow={index === phaseRows.length - 1}
            />
          ))}
        </HighlightBox>

        {userIsPremium && !dataIsPresent && (
          <SansText
            mt={1}
            size={12}
            textAlign="center"
            w="100%"
            alignItems="center"
            color={disabledColor}
            mb={!dataIsPresent ? 8 : 0}
          >
            {userIsPremium && t('chartsPage.noDataInRangeMessage')}
          </SansText>
        )}
      </Column>

      {warningDialogIsOpen && (
        <PleaseSubscribeDialog
          isOpen={warningDialogIsOpen}
          includeCreditsLink={false}
          onClose={() => {
            setWarningDialogIsOpen(false)
          }}
          onConfirmAdditionalAction={() => {
            setWarningDialogIsOpen(false)
          }}
        />
      )}
    </>
  )
}
