//thunk thunk, baby
import {
  SubscriptionStatusResponse,
  FastDream,
  FastDreamService,
  ReactionService,
} from '../../../../../api/_openapi'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { AppThunk } from '../../store'
import {
  addFastDream,
  decReactionCount,
  incReactionCount,
  removeFastDream,
  selectFastDreamById,
  setFastDreams,
  setGroupDreams,
  setGroupDreamsLoading,
  setLastSyncTime,
  setLoading,
  updateDreamsFromSync,
} from '../dream-tag'
import { setDreamFieldsLoading, setFeedRefreshLoading } from '../../ui/ui'
import {
  formatDateTimeForDatabase,
  truncateString,
} from '../../../modules/strings/string-helpers'
import { showImageToast } from '../../../modules/image-helpers/image-helpers'
import {
  QueueItemWrite,
  addQueueItem,
  removeQueueItem,
} from '../../queue/queue'
import { undecorateId } from '../../../../../api/decorate-ids'
import { selectUserPrefersNoImages } from '../../user/user'

// LOAD DREAMS
export function initialLoadUserDreams(groupId: string): AppThunk {
  return async (dispatch: any) => {
    dispatch(setLoading(true))
    FastDreamService.groupDreams(groupId)
      .then((res) => {
        dispatch(setFastDreams(res))
        dispatch(setLoading(false))
      })
      .catch((err) => {
        dispatch(setLoading(false))
        elsewhereToast.showToast({
          title: 'toast.errorLoadingDreams.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })
  }
}

export function fetchGroupDreams(groupId: string): AppThunk {
  return async (dispatch: any) => {
    dispatch(setGroupDreamsLoading(true))
    FastDreamService.groupDreams(groupId)
      .then((dreams) => {
        dispatch(
          setGroupDreams(
            dreams.map((dream) => ({
              ...dream,
              groupId,
            })),
          ),
        )
      })
      .catch((err) => {
        elsewhereToast.showToast({
          title: 'toast.errorLoadingDreams.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })
      .finally(() => {
        dispatch(setGroupDreamsLoading(false))
      })
  }
}

// SYNC DREAMS FROM SERVER
export function syncGroupDreams(groupId: string, date: string): AppThunk {
  // If there's no userId, don't do anything
  // This is for when the user is not logged in
  if (!groupId) {
    console.log('User not logged in, not syncing dreams')
    return (dispatch: any) => dispatch(setFeedRefreshLoading(false))
  }
  return async (dispatch: any) => {
    // Set feed loading
    dispatch(setFeedRefreshLoading(true))
    // Load dreams
    FastDreamService.groupDreams(groupId, undefined, date)
      .then((dreams) => {
        dispatch(setLastSyncTime(new Date().toISOString()))
        if (dreams.length > 0) {
          return dispatch(updateDreamsFromSync(dreams))
        }
      })
      .catch((err) => {
        console.log('Error syncing dreams', err)
        elsewhereToast.showToast({
          title: 'toast.errorSyncingDreams.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })
      .finally(() => {
        dispatch(setFeedRefreshLoading(false))
      })
  }
}

export function saveDraftDream(
  groupId: string,
  dream: Partial<FastDream>,
): AppThunk {
  return async (dispatch: any, getState: any) => {
    try {
      const savedDream = await FastDreamService.groupDreamCreate(
        groupId,
        dream,
        false,
        false,
      )

      if (savedDream) {
        dispatch(addFastDream(savedDream))

        elsewhereToast.showToast({
          description: 'common.saved',
          status: 'success',
        })

        return savedDream
        // common.saved
      } else {
        console.log('error saving dream', savedDream)
        elsewhereToast.showToast({
          title: 'toast.errorSavingDream.title',
          description: 'toast.errorSavingDream.description',
          status: 'error',
        })
      }
    } catch (err) {
      console.log('error saving dream', err)
    }
  }
}

// Here we assume the dream is already in the store
export function saveDream(
  groupId: string,
  id: string,
  dream: Partial<FastDream>,
  subscriptionStatus: SubscriptionStatusResponse | null,
  addImage: boolean = true,
): AppThunk {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(
        setDreamFieldsLoading({ dreamId: id, fields: ['tags'], loading: true }),
      )

      // Make a queue item in case the user leaves the app
      // and the response can't set state
      const q: QueueItemWrite = {
        id: id,
        onSuccess: {
          action: 'addFastDream',
        },
        onFailure: {
          action: 'addFastDream',
        },
        refetchAction: {
          functionKey: 'fetchFastDreamById',
          args: [groupId, id],
        },
      }

      dispatch(addQueueItem(q))

      // Send dream to API
      // requestBody: Partial<Dream>,
      // addImage?: boolean,
      // addTags?: boolean,
      const savedDream = await FastDreamService.groupDreamCreate(
        groupId,
        dream,
        addImage,
        true,
      )

      if (savedDream) {
        // Remove the queue item
        dispatch(removeQueueItem(id))

        const userPrefersNoImages = selectUserPrefersNoImages(getState())

        if (savedDream.imageId) {
          elsewhereToast.showToast({
            description: 'toast.dreamSaved.description',
            status: 'success',
          })
        } else if (!userPrefersNoImages) {
          if (savedDream.imageStatus === 'censored') {
            //open AI censored the immage
            elsewhereToast.showToast({
              title: 'toast.warningSavedWithoutImage.title',
              description: 'toast.warningSavedWithoutImage.description',
              status: 'warning',
              duration: 8000,
            })
          } else if (savedDream.imageStatus !== 'usage_limit_reached') {
            // It gets here if user selects no image style, ignore for now
            // elsewhereToast.showToast({
            //   title: 'Dream saved, image failed',
            //   description:
            //     'Image failed to generate: ' + savedDream.imageStatus,
            //   status: 'warning',
            //   duration: 8000,
            // })
          }
        }

        if (savedDream.imageId) {
          const { imagesRemaining = null, creditsRemaining = null } =
            subscriptionStatus || {}
          showImageToast({ imagesRemaining, creditsRemaining })
        }

        dispatch(addFastDream(savedDream))
        return savedDream
      } else {
        // Remove the queue item
        dispatch(removeQueueItem(id))
        console.log('error saving dream in saveDream', savedDream)
        elsewhereToast.showToast({
          title: 'toast.errorSavingDream.title',
          description: 'toast.errorSavingDream.description',
          status: 'error',
        })
      }
    } catch (err) {
      console.log('error saving dream in saveDream catch block', err)
    } finally {
      dispatch(
        setDreamFieldsLoading({
          dreamId: id,
          fields: ['tags'],
          loading: false,
        }),
      )
    }
  }
}

// Here we assume the dream is already in the store
export function dreamifyDraft(
  groupId: string,
  updatedDream: FastDream,
  artStyle: string,
): AppThunk {
  const dreamId = updatedDream.id || ''
  return async (dispatch: any) => {
    // 1) Set image and tags loading on FE
    dispatch(
      setDreamFieldsLoading({
        dreamId: dreamId,
        fields: ['tags', 'imageId'],
        loading: true,
      }),
    )

    const artStyleId = undecorateId(artStyle)

    const reqBody: Partial<FastDream> = {
      ...updatedDream,
      // @ts-ignore
      artStyle: artStyleId,
    }

    dispatch(addFastDream(updatedDream))

    FastDreamService.groupDreamUpdate(groupId, dreamId, reqBody)
      .then((dream) => {
        elsewhereToast.showToast({
          description: 'toast.dreamSaved.description',
          status: 'success',
        })
        dispatch(addFastDream(dream))
      })
      .catch((err) => {
        console.error('error saving dream in dreamifyDraft', err)
        elsewhereToast.showToast({
          title: 'toast.errorSavingDream.title',
          description: 'toast.errorSavingDream.description',
          status: 'error',
        })
      })
      .finally(() => {
        dispatch(
          setDreamFieldsLoading({
            dreamId: dreamId,
            fields: ['tags', 'imageId'],
            loading: false,
          }),
        )
      })
  }
}

// UPDATE DREAM
export function updateDream(
  groupId: string,
  dreamId: string,
  dream: FastDream,
  shouldToast = true,
): AppThunk {
  const toastDescription = dream.isDraft
    ? 'toast.draftUpdated.description'
    : 'toast.dreamUpdated.description'
  return async (dispatch: any) => {
    dispatch(addFastDream(dream))
    FastDreamService.groupDreamUpdate(groupId, dreamId, {
      ...dream,
      updatedAt: formatDateTimeForDatabase(new Date()),
    })
      .then((dream) => {
        dispatch(addFastDream(dream))
        if (shouldToast) {
          elsewhereToast.showToast({
            description: toastDescription,
            status: 'success',
          })
        }
      })
      .catch((err) => {
        console.error('error updating dream', err)
        elsewhereToast.showToast({
          title: 'toast.errorUpdatingDream.title',
          description: 'toast.errorSavingDream.description',
          status: 'error',
        })
      })
  }
}

// DELETE DREAM
// (Soft delete)
export function deleteDream(groupId: string, dreamId: string): AppThunk {
  return async (dispatch: any, getState: any) => {
    // Get the dream in question, so we can put it back if the delete fails
    const dreamToDelete = selectFastDreamById(dreamId)(getState())
    if (!dreamToDelete) {
      console.log('Error deleting dream: dream not found')
      return
    }

    const updatedDream: any = {
      title: '',
      description: '',
      note: '',
      updatedAt: formatDateTimeForDatabase(new Date()),
      tags: [],
      imageId: null,
      deleted: true,
    }

    const updateBackEnd = async (showToastOnSuccess = false) => {
      FastDreamService.groupDreamUpdate(
        groupId || '',
        dreamId,
        updatedDream,
      ).then((resDream: FastDream) => {
        // Update the frontend
        updateFrontEnd(resDream.id, showToastOnSuccess)
      })
    }

    const updateFrontEnd = (dreamId: string, showToast = true) => {
      dispatch(removeFastDream(dreamId))
      showToast &&
        elsewhereToast.showToast({
          description: 'toast.dreamDeleted.description',
          status: 'success',
        })
    }

    // Delete the dream
    if (!dreamToDelete.shareId) {
      // Dream didn't save to the server or the save never reached the frontend
      updateBackEnd(false).catch((err) => {
        console.log('Error deleting dream', err)
      })
      updateFrontEnd(dreamToDelete.id)
    } else {
      updateBackEnd(true).catch((err) => {
        console.log('Error deleting dream', err)
        elsewhereToast.showToast({
          title: 'toast.errorDeletingDream.title',
          description: 'toast.errorGeneric.description',
          status: 'error',
        })
      })
    }
  }
}

export function addReactionToDream(
  dreamId: string,
  shareId: string,
  userReactionCount?: number,
): AppThunk {
  return async (dispatch: any) => {
    if (userReactionCount !== undefined) {
      if (userReactionCount) {
        // User has already reacted to this dream, so we're removing the reaction
        dispatch(decReactionCount({ dreamId, shareId }))
      } else {
        dispatch(incReactionCount({ dreamId, shareId }))
      }
    }
    ReactionService.addReaction(shareId, {})
      .then((reaction) => {
        if (userReactionCount === undefined) {
          if (reaction.type === 'removed') {
            dispatch(decReactionCount({ dreamId, shareId }))
          } else {
            dispatch(incReactionCount({ dreamId, shareId }))
          }
        }
      })
      .catch((err) => {
        console.error('error adding reaction to dream', err)
        elsewhereToast.showToast({
          title: 'toast.errorAddingReaction.title',
          description: 'toast.errorGeneric.description',
          status: 'error',
        })
      })
  }
}
