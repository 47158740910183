import React, { useMemo, useState, useEffect } from 'react'
import { ContentDivider } from '../../layout/content-divider'
import { HeadingSans } from '../../common/copy/heading-sans'
import { Dimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Tabs } from '../../layout/tab-view-tabs'
import { FEW } from '../../../i18n/config'
import { ColorTagArea } from '../tags/tag-area-color'
import { separateTagsForDisplay } from '../../../modules/tag-helpers/tag-helpers'
import { SymbolGrid } from '../symbols/symbol-grid'
import { PADDING_HORIZONTAL_PIXELS } from '../../../constants/constants'
import { TagArea } from '../tags/tag-area'
import { Loading } from '../../layout/loading'
import { PADDING_TO_TABS } from '../../../constants/ui-constants'
import { FastDream, FastTag, FastTagService } from '../../../../../api/_openapi'
import { PaddedContentAreaConditional } from '../../layout/content-area-padded-conditional'
import { useSelector } from '../../../ducks/root-reducer'
import { selectDreamFieldIsLoading } from '../../../ducks/ui/ui'
import { undecorateId } from '../../../../../api/decorate-ids'
import { Column } from '../../common/column/column'

type TagsTabProps = {
  dream: FastDream | null
}

export const TagsTab = ({ dream }: TagsTabProps) => {
  // HOOKS
  const { t } = useTranslation()
  const [tags, setTags] = useState<FastTag[]>([])
  const tagsAreLoading = useSelector(
    selectDreamFieldIsLoading(dream?.id as string, 'tags'),
  )
  const dreamIsSaved = Boolean(dream?.shareId)

  // VARS
  const pageWidth = Dimensions.get('window').width
  const contentWidth = pageWidth - PADDING_HORIZONTAL_PIXELS * 2

  // MEMOS
  const { regularTags, colorTags, symbolTags, dreamTypeTags, themeTags } =
    useMemo(() => {
      return separateTagsForDisplay(tags)
    }, [tags])

  useEffect(() => {
    if (dream && dreamIsSaved) {
      FastTagService.dreamTags(undecorateId(dream.id)).then((tags) => {
        setTags(tags)
      })
    }
  }, [dream, dreamIsSaved])

  return (
    <Tabs.ScrollView>
      <Column w={'100%'} py={PADDING_TO_TABS}>
        {tagsAreLoading ? (
          <Loading loadingLabel={t('loading.loadingGeneric')} mt={10} />
        ) : (
          dream && (
            <PaddedContentAreaConditional>
              <TagArea
                tags={
                  regularTags.concat(dreamTypeTags || [], themeTags || []) || []
                }
                dream={dream}
              />
              {colorTags.length > 0 && (
                <>
                  <ContentDivider />
                  <HeadingSans textAlign={'center'}>
                    {t('tagType.color_plural', { count: FEW })}
                  </HeadingSans>
                  <ColorTagArea tags={colorTags || []} />
                </>
              )}
              {symbolTags.length > 0 && (
                <>
                  <ContentDivider />
                  <HeadingSans textAlign={'center'}>
                    {t('tagType.symbol_plural', { count: FEW })}
                  </HeadingSans>
                  <SymbolGrid
                    showNumUses={false}
                    tags={symbolTags.map((tag) => ({
                      ...tag,
                      count: 1,
                      sentiment: 0,
                      lastUsedDate: null,
                    }))}
                    width={contentWidth}
                  />
                </>
              )}
            </PaddedContentAreaConditional>
          )
        )}
      </Column>
    </Tabs.ScrollView>
  )
}
