import React, { useState, useMemo } from 'react'
import { useColorModeValue } from 'native-base'
import { StyleSheet, ImageSourcePropType, Platform } from 'react-native'
import { Img } from './image'
import { ButtonIcon } from '../buttons/button-icon'
import { LINE_WIDTH } from '../../../constants/constants'
import { FieldImageUpload } from '../inputs/field-image-upload'
import {
  FormFieldOption,
  ImageOnObject,
} from '../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { BLACK, CREAM, WHITE } from '../../../constants/ui-constants'
import { AlertPopup } from '../dialog/alert-dialog'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../action-sheet/action-sheet-menu'
import { useSelector } from '../../../ducks/root-reducer'
import { selectArtStyleOptions } from '../../../ducks/ui/ui'
import { selectUserIsPremium } from '../../../ducks/user/user'
import { UseLinkPropsProps } from '../../../utilities/useLinkPropsFixed'
import useContentWidth from '../../../hooks/useContentWidth'
import { Row } from '../row/row'
import { Box } from '../box/box'
import { Column } from '../column/column'
import { View } from '../view/view'

type FramedImageProps = {
  source?: ImageSourcePropType | ImageOnObject
  SvgIcon?: ({ color, size }: { color: string; size: number }) => JSX.Element
  alt: string
  loading?: boolean
  loadingLabel?: string
  editable?: boolean
  onRegenerateImage?: (
    userUploaded: boolean,
    image?: ImageOnObject | null,
    artStyle?: string,
    prompt?: string,
  ) => void
  onChangeImageStyle?: (
    artStyle: string,
    userUploaded: boolean,
    image?: ImageOnObject | null,
  ) => void
  setImageLoading?: (loading: boolean) => void
  setLoadingLabel?: (loadingLabel: string) => void
  regenDisabled?: boolean
  creditsRemaining?: number | null
  imagesRemaining?: number | null
  tier?: string | null
  linkObject?: UseLinkPropsProps['to']
}

const CUSTOM_ART_STYLE: FormFieldOption = {
  value: 'custom',
  label: 'artStyles.custom',
  languageKey: 'artStyles.custom',
  isPremium: true,
  iconKey: 'gem',
}

export const FramedImage = React.memo(
  ({
    source,
    alt,
    loading,
    loadingLabel,
    editable,
    SvgIcon,
    onRegenerateImage,
    setImageLoading,
    setLoadingLabel,
    regenDisabled = false,
    creditsRemaining,
    imagesRemaining,
    tier,
    linkObject,
  }: FramedImageProps) => {
    const h = 512
    const w = 512

    const zeroCreditsRemaining =
      (imagesRemaining ?? 0) === 0 && (creditsRemaining ?? 0) === 0

    // STATE
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
    const [ownPromptAlertOpen, setOwnPromptAlertOpen] = useState(false)
    const [styleSettingsMenuOpen, setStyleSettingsMenuOpen] = useState(false)

    // HOOKS
    const { t } = useTranslation()
    const {
      contentWidth,
      imageWidth: imgW,
      paddedImageWidth: imageFrameWidth,
    } = useContentWidth()

    // SELECTORS
    const artStyleOptions = useSelector(selectArtStyleOptions(false))
    const isPremium = useSelector(selectUserIsPremium)

    // VARS
    const allArtStyles = isPremium
      ? [...artStyleOptions, CUSTOM_ART_STYLE]
      : artStyleOptions

    let color, borderColor, borderBottomColor
    color = borderColor = borderBottomColor = useColorModeValue(BLACK, WHITE)
    const svgColor = BLACK
    const svgBackgroundColor = CREAM

    // MEMOIZED VARS
    const artStyleMenuItems: ActionSheetMenuItem[] = useMemo(() => {
      return allArtStyles.map((artStyle) => {
        if (artStyle.value === 'custom') {
          return {
            label: t(artStyle.label),
            onPress: () => {
              setStyleSettingsMenuOpen(false)
              setOwnPromptAlertOpen(true)
            },
            locked: artStyle.locked,
            lockedAction: artStyle.lockedAction,
            iconKey: artStyle.iconKey,
          }
        } else {
          return {
            label: t(artStyle.label),
            onPress: () => {
              setStyleSettingsMenuOpen(false)
              onRegenerateImage &&
                onRegenerateImage(false, null, artStyle.value)
            },
            locked: artStyle.locked,
            lockedAction: artStyle.lockedAction,
            iconKey: artStyle.iconKey,
          }
        }
      })
    }, [allArtStyles])

    return (
      <>
        <Column
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          {/* Top line */}
          <View style={{ ...styles.frameLineHorizontal, borderBottomColor }} />
          <Row
            style={{
              width: contentWidth,
              justifyContent: 'center',
            }}
          >
            {/* Immediate left and right borders of the image */}
            <Row
              style={{
                ...styles.frameBorderVertical,
                width: imageFrameWidth,
                borderColor,
                alignSelf: 'center',
              }}
            >
              {SvgIcon && (
                <Box
                  height={imgW}
                  width={imgW}
                  mt={4}
                  mb={4}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: svgBackgroundColor,
                  }}
                >
                  <SvgIcon color={svgColor} size={imgW} />
                </Box>
              )}
              {!SvgIcon && (
                <Img
                  alt={alt}
                  source={source as ImageSourcePropType}
                  loading={loading}
                  loadingLabel={loadingLabel}
                  creditsRemaining={creditsRemaining}
                  imagesRemaining={imagesRemaining}
                  tier={tier}
                  width={imgW}
                  height={imgW}
                  mt={4}
                  mb={4}
                  style={{
                    ...styles.image,
                    aspectRatio: w / h,
                  }}
                />
              )}
            </Row>
          </Row>
          {/* Bottom line */}
          <View style={{ ...styles.frameLineHorizontal, borderBottomColor }} />
          {editable && (
            <Box position={'absolute'} right={4} bottom={7}>
              <Column space={3}>
                <ButtonIcon
                  isDisabled={regenDisabled || loading || zeroCreditsRemaining}
                  iconKey="settings"
                  size="sm"
                  variant={'outlined'}
                  onPress={() => {
                    setStyleSettingsMenuOpen(true)
                  }}
                />
                <ButtonIcon
                  isDisabled={regenDisabled || loading || zeroCreditsRemaining}
                  iconKey="refresh"
                  size="sm"
                  variant={'outlined'}
                  onPress={() => {
                    setLoadingLabel &&
                      setLoadingLabel(t('loading.dreamArtLoading'))
                    onRegenerateImage && onRegenerateImage(false)
                  }}
                />
                <FieldImageUpload
                  options={{
                    mediaType: 'photo',
                    includeBase64: false,
                    cropping: true,
                    cropperChooseColor: WHITE,
                    cropperChooseText: 'Replace',
                    cropperCancelColor: WHITE,
                    cropperCancelText: 'Cancel',
                    width: 1200,
                    height: 1200,
                    type: 'dream-image',
                    alt: 'Uploaded dream image',
                    // @ts-ignore
                    croppingAspectRatio: Platform.OS === 'web' ? 1 : undefined,
                  }}
                  iconKey="image-upload"
                  value={source as ImageOnObject}
                  setImageLoading={setImageLoading}
                  setLoadingLabel={setLoadingLabel}
                  isDisabled={loading}
                  size="sm"
                  onChange={(image: ImageOnObject) => {
                    onRegenerateImage && onRegenerateImage(true, image)
                  }}
                />
                {/* @ts-ignore */}
                {source?.uri ? (
                  <ButtonIcon
                    isDisabled={regenDisabled || loading}
                    iconKey="trash"
                    variant={'outlined'}
                    onPress={() => {
                      setDeleteAlertOpen(true)
                    }}
                    size="sm"
                  />
                ) : null}
              </Column>
            </Box>
          )}
        </Column>
        {deleteAlertOpen && (
          <AlertPopup
            isOpen={deleteAlertOpen}
            header={t('removeImageDialog.title')}
            description={t('removeImageDialog.description')}
            confirmLabel={t('common.remove')}
            onConfirm={() => {
              setDeleteAlertOpen(false)
              onRegenerateImage && onRegenerateImage(true, null)
            }}
            onClose={() => {
              setDeleteAlertOpen(false)
            }}
          />
        )}
        {ownPromptAlertOpen && (
          <AlertPopup
            isOpen={ownPromptAlertOpen}
            header={t('artStyles.custom')}
            description={`${t('common.forExample')} ${t(
              'artStyles.examplePrompt',
            )}`}
            confirmLabel={t('common.confirm')}
            onConfirm={(userInput?: string) => {
              if (userInput && onRegenerateImage) {
                onRegenerateImage(false, null, undefined, userInput)
              }
              setOwnPromptAlertOpen(false)
            }}
            onClose={() => {
              setOwnPromptAlertOpen(false)
            }}
            hasUserInput={true}
          />
        )}
        {styleSettingsMenuOpen && (
          <ActionSheetMenu
            isOpen={styleSettingsMenuOpen}
            onClose={() => {
              setStyleSettingsMenuOpen(false)
            }}
            menuItems={artStyleMenuItems}
            heading={t('common.artStyle')}
          />
        )}
      </>
    )
  },
)

const styles = StyleSheet.create({
  frameLineHorizontal: {
    borderBottomWidth: LINE_WIDTH,
  },
  frameBorderVertical: {
    borderLeftWidth: LINE_WIDTH,
    borderRightWidth: LINE_WIDTH,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: undefined,
    resizeMode: 'contain',
  },
})
