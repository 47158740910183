import { colord } from 'colord'
import { Color } from './color-types'
import { parseColorString } from './color-helpers'

export const stringifyHsl = (
  hue: number,
  saturation: number,
  value: number,
): string => {
  return `hsl(${hue}, ${saturation}%, ${value}%)`
}

export const hslToHexString = (h: number, s: number, l: number): string => {
  const hslString = stringifyHsl(h, s, l)
  return colord(hslString).toHex()
}

export const colorStringsAreEqual = (
  colorStringA: string,
  colorStringB: string,
): boolean => {
  if (colorStringA === colorStringB) return true

  const colorNameA = parseColorString(colorStringA).colorName
  const colorNameB = parseColorString(colorStringB).colorName
  return colorNameA === colorNameB
}

export const colorNameIsShiny = (colorName: string) => {
  return (
    colorName === 'gold' ||
    colorName === 'silver' ||
    colorName === 'bronze' ||
    colorName === 'metallic'
  )
}

export const colorIsShiny = (color: Color): boolean => {
  const { colorName } = color
  return colorNameIsShiny(colorName)
}

export const isShiny = (colorString: string): boolean => {
  const color = parseColorString(colorString)
  return colorIsShiny(color)
}

export const colorToOldColorString = (color: Color): string => {
  return `${color.colorName} (${color.simpleColor}) ${color.hex}`
}
