import { DARK_MID_GRAY } from '../../constants/ui-constants'
import { camelCase } from '../strings/string-helpers'
import { hslToHexString } from './color-string-helpers'
import {
  Color,
  ColorDefinition,
  ColorDefinitionWithChildren,
  ColorTagSubType,
  PickerI18nLayout,
  SimpleColor,
} from './color-types'
import { colorsList } from './colorList'
import {
  getColorFromTag,
  simpleColorValidify,
} from '../../components/composite/colorpicker/picker-helpers'

// Specific color, general color, hex
type ColorParts = [string, SimpleColor, string]

const translatableColors = [
  'red',
  'orange',
  'brown',
  'yellow',
  'green',
  'turquoise',
  'blue',
  'purple',
  'pink',
  'black',
  'white',
  'cream',
  'gray',
  'gold',
  'silver',
  'bronze',
  'dark red-brown',
  'golden yellow',
  'light golden brown',
  'light yellow-brown',
  'yellow-green',
  'dark olive green',
  'light spring green',
  'turquoise',
  'teal',
  'prussian blue',
  'dark royal blue',
  'light purple',
  'magenta',
]

const translatableColorKeys = translatableColors.map((color) =>
  camelCase(color),
)

// Take a colour string like "light brown (blue) #2797e7"
// And extract the three parts (specific color, general color, hex)
export function extractColorParts(color: string): ColorParts {
  // Get the hex part with regex
  const hex = color.match(/#[0-9a-fA-F]{6}/)
  // Get the general color part (in brackets)
  const general = color.match(/\(([^)]+)\)/)
  // Get the specific color part (before the brackets)
  const specific = color.split('(')[0]
  if (!hex || !general || !specific) {
    console.log('Invalid color string:', color)
    return ['', 'black', '#000000']
  }
  const generalColor = general[1].trim() as SimpleColor
  const specificColor = specific.trim()
  const hexColor = hex[0]
  return [specificColor, generalColor, hexColor]
}

// Take a color and return the closest translatable color
// Usually the general color (middle part of the color string) is the translatable part
// But sometimes the specific color is more appropriate (e.g. turquoise)
export function getTranslatableColor(parts: ColorParts): string {
  const specific = camelCase(parts[0])
  const general = parts[1]
  if (translatableColorKeys.includes(specific)) {
    return specific
  } else {
    return general
  }
}

export function i18nKeyFromColorStr(color: string): string {
  const parts = extractColorParts(color)
  const translatableColor = getTranslatableColor(parts)
  const key = `colors.${translatableColor}`
  return key
}

export function hexFromColorString(color: string | undefined): string {
  if (!color) {
    console.log('No color provided')
    return '#000000'
  }
  const parts = extractColorParts(color)
  const hexPart = parts[2]
  // Make sure it's a valid hex color
  if (!hexPart.match(/#[0-9a-fA-F]{6}/)) {
    console.log('Invalid hex color:', hexPart)
    return '#000000'
  }
  return hexPart
}

// Parse a color string to a color object
export const parseColorString = (colorString: string): Color => {
  const regex = /([a-zA-Z- ]+)\(([a-zA-Z- ]+)\) ([A-Za-z0-9#]+)/
  const found = colorString.match(regex)

  if (!found) {
    const emptyColor: Color = {
      colorName: 'black',
      simpleColor: 'black',
      hex: '#000000',
    }
    return emptyColor
  }

  const [colorName, simpleColor, hex] = found
    .slice(1, found.length)
    .map((x) => x.trim())

  const ret: Color = {
    colorName: colorName,
    simpleColor: simpleColor as SimpleColor,
    hex: hex,
  }
  return ret
}

// Typeguard to check if a subtype is a simple color
export const isSimpleColor = (
  subtype: ColorTagSubType,
): subtype is SimpleColor => {
  return subtype === 'COLOR'
}

export const i18nKeyFromColorTag = (
  tagName: string,
  subType: ColorTagSubType,
): string => {
  let ret = ''
  // CASE 1) It's a simple color (red, blue, etc.)
  if (isSimpleColor(subType)) {
    ret = `colors.${tagName}`
  }
  // CASE 2) It's a complex color (lightBrown, darkBlue, etc.)
  else {
    ret = `colorTagNames.${tagName}`
  }
  return ret
}

// Of a given list of color definitions,
// See which ones have child colors
// (i.e. other colors that are associated with them)
export const getColorDefinitionsWithChildren = (
  colorDefinitions: ColorDefinition[],
  pickerLayout: PickerI18nLayout,
): ColorDefinitionWithChildren[] => {
  const allColorDefinitions = colorsList

  let ret: ColorDefinitionWithChildren[] = []

  // Loop through all the color definitions
  for (let i = 0; i < colorDefinitions.length; i++) {
    const cd = colorDefinitions[i]
    let hasChildColorsInSimpleMode = false
    let hasChildColors = false

    // Loop through all the color definitions again to get the children
    for (let j = 0; j < allColorDefinitions.length; j++) {
      const cd2 = allColorDefinitions[j]

      const isAssociated = cd2.associations.includes(
        cd.simpleOpts.id as SimpleColor,
      )

      // If they're the same, ignore
      if (cd.name === cd2.name) {
        continue
      } else {
        // If the second color is a child of the first
        if (isAssociated) {
          hasChildColors = true
          if (cd2.simpleOpts.showInSimpleModeForLayout.includes(pickerLayout)) {
            hasChildColorsInSimpleMode = true
          }
        }
      }
    }

    ret.push({
      ...cd,
      hasChildColors,
      hasChildColorsInSimpleMode,
    })
  }
  return ret
}

export const colorDefinitionToColor = (
  colorDef: ColorDefinition | ColorDefinitionWithChildren | undefined,
  simpleColor: SimpleColor | undefined,
): Color | undefined => {
  if (!colorDef || !simpleColor) {
    return undefined
  } else {
    const { name: complexColorName, hsl } = colorDef
    const colorHex = hslToHexString(...hsl)
    const color: Color = {
      colorName: colorDef.name,
      simpleColor: simpleColorValidify(complexColorName, simpleColor),
      hex: colorHex,
    }
    return color
  }
}

export const getContrastColor = (color: Color): string => {
  const hex = color.hex
  const r = parseInt(hex.substr(1, 2), 16)
  const g = parseInt(hex.substr(3, 2), 16)
  const b = parseInt(hex.substr(5, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 170 ? DARK_MID_GRAY : '#ffffff'
}
