import React from 'react'
import { Dimensions, Platform } from 'react-native'
import { SymbolGrid } from '../../components/composite/symbols/symbol-grid'
import { useSelector } from '../../ducks/root-reducer'
import { selectSymbolTags } from '../../ducks/ui/ui'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { CountedFastTag } from '../../../../api/_openapi'
import { Loading } from '../../components/layout/loading'
import { View } from '../../components/common/view/view'
import { Row } from '../../components/common/row/row'

type SymbolDexProps = {
  userSymbols: CountedFastTag[]
  loading: boolean
}

export const SymbolDex = ({ userSymbols, loading }: SymbolDexProps) => {
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  const allSymbols = useSelector(selectSymbolTags)

  // Merge the tags the user has used with the full list of tags
  // Use either the user's tag count or 0
  // We need to use the image from the user's tag
  // Since the full list has only IDs and we don't want to fetch them all
  const allSymbolsWithCounts: CountedFastTag[] = allSymbols
    .map((s) => {
      const userCountedTag = userSymbols.find((us) => us.id === s.id)
      return {
        ...s,
        count: userCountedTag?.count || 0,
        sentiment: 0,
        lastUsedDate: null,
      }
    })
    .sort((a, b) => a.id.localeCompare(b.id))

  if (loading) {
    return <Loading />
  }

  return (
    <View>
      <Row justifyContent="center" h={'100%'} w={'100%'}>
        <SymbolGrid
          width={pageWidth - (Platform.OS === 'web' ? 0 : 32)}
          tags={allSymbolsWithCounts}
        />
      </Row>
    </View>
  )
}
