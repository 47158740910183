import React from 'react'
import { SymbolComponent, SymbolComponentMap } from '../../assets/symbols'
import { snakeCase } from '../../modules/strings/string-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import {
  BLACK,
  CREAM,
  DARKER_CREAM,
  LIGHT_BLACK,
  LIGHTER_BLACK,
  MID_CREAM,
  WHITE,
} from '../../constants/ui-constants'
import { useColorMode, useColorModeValue } from 'native-base'
import { Platform } from 'react-native'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { Center } from '../../components/common/center/center'
import { MoonPhase, TagType } from '../../../../api/frontend-types'
import { useHemisphere } from '../../hooks/useHemisphere'
import { Icon as SymbolMask } from '../../assets/symbols/symbol-mask'
import { Loading } from '../../components/layout/loading'
import { Moon } from '../../components/composite/moon/moon'

type TagViewImageProps = {
  imageUrl?: string
  name?: string
  type?: TagType
  pageWidth: number
  loading?: boolean
}

export default function TagViewImage({
  imageUrl,
  name,
  type,
  pageWidth,
  loading = false,
}: TagViewImageProps) {
  const tagNameAsMoonPhase: MoonPhase = name?.replace(
    'moonphase.',
    '',
  ) as MoonPhase
  const cream = tagNameAsMoonPhase === 'fullMoon' ? DARKER_CREAM : MID_CREAM
  const color = useColorModeValue(BLACK, WHITE)
  const imageBgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const moonColor = useColorModeValue(WHITE, WHITE)
  const moonBorderColor = useColorModeValue(cream, LIGHTER_BLACK)
  const moonShadowColor = useColorModeValue(BLACK, BLACK)
  const hemisphere = useHemisphere()
  const { colorMode } = useColorMode()

  const isWeb = Platform.OS === 'web'
  const isSymbolType = type === 'SYMBOL'
  const isMoonPhaseTag = type === 'MOON_PHASE'

  if (loading) {
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <Loading />
      </Center>
    )
  }

  if (!name) {
    return null
  }

  if ((isSymbolType && SymbolComponentMap[snakeCase(name)]) || isMoonPhaseTag) {
    const SymbolComponent: SymbolComponent = SymbolComponentMap[snakeCase(name)]

    if (isMoonPhaseTag) {
      return (
        <Center
          backgroundColor={imageBgColor}
          width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          pointerEvents="none"
        >
          <Moon
            size={200}
            phase={name.replace('moonphase.', '') as MoonPhase}
            hemisphere={hemisphere}
            colorMode={colorMode || 'light'}
            moonColor={moonColor}
            borderColor={moonBorderColor}
            shadowColor={moonShadowColor}
            borderWidth={3}
          />
        </Center>
      )
    }
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <SymbolComponent color={color} size={200} />
      </Center>
    )
  } else if (imageUrl) {
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <AvatarCircle
          source={{ uri: imageUrl }}
          name={'dunno'}
          loading={false}
          disablePress={true}
          size={'2xl'}
        />
      </Center>
    )
  } else if (type === 'CHARACTER') {
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <SymbolMask color={color} size={200} />
      </Center>
    )
  } else {
    return null
  }
}
