import React, { useState } from 'react'
import { Checkbox } from 'native-base'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import { ColorType } from 'native-base/lib/typescript/components/types'
import { AvatarCircle } from '../../avatar/avatar-circle'
import { SansText } from '../../copy/text-sans'
import { Row } from '../../row/row'
import { Column } from '../../column/column'
import { updateCloudinaryUrlForDisplay } from '../../../../modules/image-helpers/image-helpers'
import { MID_GRAY, SM_THUMB_RES } from '../../../../constants/ui-constants'
import { BodyText } from '../../copy/text-body'
import { useTranslation } from 'react-i18next'

type CheckboxGroupWithImagesProps = {
  options: FormFieldOption[]
  maxOptions?: number
  onChange: (value: string[]) => void
  multiSelect?: boolean
  values: string[]
  defaultValues: string[]
  color?: ColorType
  disablePreSelected?: boolean
}

// Possibly we should be using Checkbox.Group here
// But it doesn't seem to play nice with a free option
export const CheckboxGroupWithImages = React.memo(
  ({
    options,
    maxOptions = 0,
    multiSelect = false,
    onChange,
    values,
    defaultValues,
    color,
    disablePreSelected = false,
  }: CheckboxGroupWithImagesProps) => {
    // STATE
    const [isExpanded, setIsExpanded] = useState(false)

    // HOOKS
    const { t } = useTranslation()

    // FUNCTIONS
    const addToSelected = (value: string, values: string[]) => {
      // 1) If the value is already in the array, do nothing
      // This shouldn't happen, but just in case
      if (values.includes(value)) {
        return
      }
      // 2) If it's a multi-select, add the value to the array
      else if (multiSelect) {
        const newValues = [...values, value]
        onChange(newValues)
      }
      // Else, it's a single select, so replace the array with the value
      else {
        onChange([value])
      }
    }

    const removeFromSelected = (value: string, values: string[]) => {
      const newValues = values.filter((item) => {
        return item !== value
      })
      onChange(newValues)
    }

    const toggleIsExpanded = () => {
      setIsExpanded(!isExpanded)
    }

    // VARS
    const preSelected = defaultValues
    const displayOptions =
      !maxOptions || isExpanded ? options : options.slice(0, maxOptions)
    const showMoreOrLess = maxOptions > 0 && options.length > maxOptions

    // We need a key on the VStack so that it re-renders when the number of values changes
    // Since react-hook-form has its own state and somehow doesn't trigger a re-render
    return (
      <Column w="100%" space={0} key={`${values.length}`}>
        {displayOptions.map((option) => {
          const imageSrc = option.image
            ? updateCloudinaryUrlForDisplay(option.image, SM_THUMB_RES)
            : undefined

          const label = option.languageKey
            ? t(option.languageKey)
            : option.label

          return (
            <Row
              key={option.value}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Row
                alignItems={'center'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Column>
                  <Row alignItems={'center'}>
                    <Column>
                      <AvatarCircle
                        size={'md'}
                        source={{ uri: imageSrc }}
                        name={label}
                      />
                    </Column>
                    <Column>
                      <SansText ml={4}>{label}</SansText>
                      {option.subLabel && (
                        <SansText
                          fontSize={'xs'}
                          fontStyle={'italic'}
                          ml={4}
                          maxWidth={'70%'}
                          color={MID_GRAY}
                        >
                          {t(option.subLabel)}
                        </SansText>
                      )}
                    </Column>
                  </Row>
                </Column>
                <Column>
                  <Checkbox
                    _checked={{
                      bg: color,
                      borderColor: color,
                    }}
                    borderRadius={0}
                    value={option.value}
                    accessibilityLabel={option.label}
                    my={2}
                    isDisabled={
                      disablePreSelected
                        ? preSelected.includes(option.value)
                        : false
                    }
                    isChecked={values.includes(option.value)}
                    onChange={(checked) => {
                      if (checked) {
                        addToSelected(option.value, values)
                      } else {
                        removeFromSelected(option.value, values)
                      }
                    }}
                    // @ts-ignore
                    label={option.label}
                  />
                </Column>
              </Row>
            </Row>
          )
        })}
        {showMoreOrLess && (
          <Row mt={2} ml={2}>
            <SansText
              fontStyle={'italic'}
              fontSize={'sm'}
              mt={4}
              onPress={toggleIsExpanded}
            >
              {isExpanded ? t('common.showLess') : t('common.showMore')}
            </SansText>
          </Row>
        )}
      </Column>
    )
  },
)
