/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagSummaryStats } from '../models/TagSummaryStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagSummaryStatsService {
  /**
   * Retrieves a TagSummaryStats resource.
   * Retrieves a TagSummaryStats resource.
   * @param tagId
   * @param id TagSummaryStats identifier
   * @param relatedTagCount
   * @returns TagSummaryStats TagSummaryStats resource
   * @throws ApiError
   */
  public static tagSummaryStats(
    tagId: string,
    id: string,
    relatedTagCount: number = 10,
  ): CancelablePromise<TagSummaryStats> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/groups/{id}/tag-summary-stats',
      path: {
        'id': id,
      },
      query: {
        'tagId': tagId,
        'relatedTagCount': relatedTagCount,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
