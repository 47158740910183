import i18n from 'i18next'
import * as React from 'react'
import { Path, Circle } from 'react-native-svg'
import { Icon, IconProps } from '../../../components/common/icon/icon'

export const IconEllipsis = (props: IconProps) => {
  const { fill } = props
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Circle cx={4} cy={12} r={2} fill={fill} />
      <Circle cx={12} cy={12} r={2} fill={fill} />
      <Circle cx={20} cy={12} r={2} fill={fill} />
    </Icon>
  )
}

export const IconCross = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path d="m4 4 16 16M20 4 4 20" stroke={'currentColor'} strokeWidth={2} />
    </Icon>
  )
}

export const IconSignout = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path d="M8 12h14" stroke={'currentColor'} strokeWidth={2} />
      <Path
        d="m16 6 6 6-6 6M11 3H3v18h8"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconHelp = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path
        fill="none"
        stroke="currentColor"
        d="m18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0m-5 0a4 4 0 1 1-8 0a4 4 0 0 1 8 0"
      />
    </Icon>
  )
}

export const IconOnline = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <Path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        fill={'none'}
        d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12ZM4 16h24"
      />
      <Path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        fill={'none'}
        d="M16 27.675c2.761 0 5-5.227 5-11.675S18.761 4.325 16 4.325 11 9.552 11 16s2.239 11.675 5 11.675Z"
      />
    </Icon>
  )
}

// Elsewhere Logo
export const IconElsewhere = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 33 32" fill="currentColor" {...props}>
      <Path
        d="M0 0V32H32.3802V0H0ZM8.83095 29.0909V8.72727H23.8436V11.6364H11.7746V29.0909H8.83095ZM14.7183 29.0909V14.5455H23.5492V17.4545H17.6619V29.0909H14.7183ZM20.6056 29.0909V20.3636H23.5492V29.0909H20.6056ZM29.4365 29.0909H26.7872V5.81818H5.8873V29.0909H2.94365V2.90909H29.4365V29.0909Z"
        fill="currentColor"
      />
    </Icon>
  )
}

export const IconLocation = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
      <Path
        fill="currentColor"
        d="M11 22.95v-2q-3.125-.35-5.363-2.587T3.05 13h-2v-2h2q.35-3.125 2.588-5.363T11 3.05v-2h2v2q3.125.35 5.363 2.588T20.95 11h2v2h-2q-.35 3.125-2.587 5.363T13 20.95v2zM12 19q2.9 0 4.95-2.05T19 12t-2.05-4.95T12 5T7.05 7.05T5 12t2.05 4.95T12 19m0-3q-1.65 0-2.825-1.175T8 12t1.175-2.825T12 8t2.825 1.175T16 12t-1.175 2.825T12 16m0-2q.825 0 1.413-.587T14 12t-.587-1.412T12 10t-1.412.588T10 12t.588 1.413T12 14m0-2"
      />
    </Icon>
  )
}

export const IconInstagram = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <Path
        fill="currentColor"
        d="M12.7 10c0-1.5-1.2-2.7-2.7-2.7S7.3 8.5 7.3 10s1.2 2.7 2.7 2.7c1.5 0 2.7-1.2 2.7-2.7zm1.4 0c0 2.3-1.8 4.1-4.1 4.1S5.9 12.3 5.9 10S7.7 5.9 10 5.9s4.1 1.8 4.1 4.1zm1.1-4.3c0 .6-.4 1-1 1s-1-.4-1-1s.4-1 1-1s1 .5 1 1zM10 3.4c-1.2 0-3.7-.1-4.7.3c-.7.3-1.3.9-1.5 1.6c-.4 1-.3 3.5-.3 4.7s-.1 3.7.3 4.7c.2.7.8 1.3 1.5 1.5c1 .4 3.6.3 4.7.3s3.7.1 4.7-.3c.7-.3 1.2-.8 1.5-1.5c.4-1.1.3-3.6.3-4.7s.1-3.7-.3-4.7c-.2-.7-.8-1.3-1.5-1.5c-1-.5-3.5-.4-4.7-.4zm8 6.6v3.3c0 1.2-.4 2.4-1.3 3.4c-.9.9-2.1 1.3-3.4 1.3H6.7c-1.2 0-2.4-.4-3.4-1.3c-.8-.9-1.3-2.1-1.3-3.4V6.7c0-1.3.5-2.5 1.3-3.4C4.3 2.5 5.5 2 6.7 2h6.6c1.2 0 2.4.4 3.4 1.3c.8.9 1.3 2.1 1.3 3.4V10z"
      />
    </Icon>
  )
}

export const IconSettings = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M2 12h20M2 5h20M6 2v6M6 16v6M18 9v6M2 19h20"
        stroke={'currentColor'}
        strokeWidth={2}
      />
    </Icon>
  )
}

export const IconNotifications = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fill="currentColor"
        d="M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7Zm-2 0v-7a6 6 0 0 0-12 0v7h12Zm-9 4h6v2H9v-2Z"
      />
    </Icon>
  )
}

// Same as IconUser, but with a different viewBox (24x24)
export const IconUserOutline = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fill="currentColor"
        d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25ZM6.945 18.516a6 6 0 0 1 10.11 0 8.234 8.234 0 0 1-10.11 0ZM9 11.25a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm9.165 6.226a7.469 7.469 0 0 0-3.38-2.695 4.5 4.5 0 1 0-5.57 0 7.469 7.469 0 0 0-3.38 2.695 8.25 8.25 0 1 1 12.33 0Z"
      />
    </Icon>
  )
}

export const IconMapPin = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fill="currentColor"
        d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.944 1.36 6.064 3.938 9.023a23.837 23.837 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.835 23.835 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.5Zm0 19.313c-1.55-1.22-6.75-5.696-6.75-11.063a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.063Z"
      />
    </Icon>
  )
}

export const IconUserSettings = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fill="currentColor"
        d="M 19.749 17.627 C 19.327 16.628 18.715 15.72 17.946 14.955 C 17.18 14.187 16.272 13.575 15.274 13.152 C 15.265 13.148 15.256 13.146 15.247 13.141 C 16.64 12.135 17.546 10.495 17.546 8.646 C 17.546 5.582 15.063 3.099 11.999 3.099 C 8.936 3.099 6.453 5.582 6.453 8.646 C 6.453 10.495 7.359 12.135 8.752 13.143 C 8.743 13.148 8.734 13.15 8.725 13.155 C 7.723 13.577 6.824 14.183 6.053 14.957 C 5.285 15.723 4.673 16.631 4.25 17.63 C 3.835 18.608 3.611 19.656 3.59 20.718 C 3.588 20.819 3.669 20.902 3.769 20.902 L 5.111 20.902 C 5.21 20.902 5.288 20.823 5.29 20.727 C 5.335 19.001 6.028 17.384 7.254 16.158 C 8.522 14.89 10.206 14.192 11.999 14.192 C 13.793 14.192 15.477 14.89 16.745 16.158 C 17.971 17.384 18.664 19.001 18.709 20.727 C 18.711 20.826 18.789 20.902 18.888 20.902 L 20.23 20.902 C 20.33 20.902 20.411 20.819 20.409 20.718 C 20.386 19.649 20.165 18.609 19.749 17.627 Z M 11.999 12.492 C 10.973 12.492 10.007 12.092 9.28 11.365 C 8.553 10.639 8.153 9.672 8.153 8.646 C 8.153 7.619 8.553 6.653 9.28 5.926 C 10.007 5.199 10.973 4.799 11.999 4.799 C 13.026 4.799 13.992 5.199 14.719 5.926 C 15.446 6.653 15.846 7.619 15.846 8.646 C 15.846 9.672 15.446 10.639 14.719 11.365 C 13.992 12.092 13.026 12.492 11.999 12.492 Z"
      />
    </Icon>
  )
}

export const IconEdit = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="m13 7 4-4 4 4-4 4m-4-4L3 17v4h4l10-10m-4-4 4 4"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconGem = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M4.878 3.003h14.254a1 1 0 0 1 .809.412l3.822 5.256a.5.5 0 0 1-.037.633l-11.354 12.3a.5.5 0 0 1-.706.029c-.007-.006-3.801-4.116-11.383-12.329a.5.5 0 0 1-.037-.633l3.823-5.256a1 1 0 0 1 .809-.412m.509 2l-2.8 3.849l9.418 10.202l9.417-10.202l-2.8-3.85z"
        stroke={'currentColor'}
        strokeWidth={0}
        strokeLinejoin="round"
        fill={'currentColor'}
      />
    </Icon>
  )
}

{
  /* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 12C8 12.6667 11.3333 16 12 22C12.6667 16 16 12.6667 22 12C16 11.3333 12.6667 8 12 2C11.3333 8 8 11.3333 2 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */
}
export const IconSparkle = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M2 12C8 12.6667 11.3333 16 12 22C12.6667 16 16 12.6667 22 12C16 11.3333 12.6667 8 12 2C11.3333 8 8 11.3333 2 12Z"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || 'none'}
      />
    </Icon>
  )
}

export const IconCoin = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="M10.005 20.003c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-12.95 4.95 4.95-4.95 4.95-4.95-4.95 4.95-4.95Zm0 2.828-2.121 2.122 2.12 2.12 2.122-2.12-2.121-2.122Z"
        stroke={'currentColor'}
        strokeWidth={0}
        strokeLinejoin="round"
        fill={'currentColor'}
      />
    </Icon>
  )
}

export const IconLock = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 26" {...props}>
      <Path
        d="M10 0C6.156 0 3 3.156 3 7v3H0v16h20V10h-3V7c0-3.844-3.156-7-7-7Zm0 2c2.754 0 5 2.246 5 5v3H5V7c0-2.754 2.246-5 5-5ZM2 12h16v12H2V12Z"
        stroke={'currentColor'}
        strokeWidth={0}
        strokeLinejoin="round"
        fill={'currentColor'}
      />
    </Icon>
  )
}

export const IconWand = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 100 125" {...props}>
      <Path
        d="m10.163 85.659 51.053-51.053 4.243 4.242-51.054 51.053zM54.4 10.2h6v18.78h-6zM71 39.6h18.8v6H71zM66.701 29.063 80.42 15.345l4.243 4.243-13.718 13.718zM32.1 32.5h18.8v6H32.1zM61.5 49.1h6v18.78h-6z"
        stroke={'currentColor'}
        data-name="Path"
      />
    </Icon>
  )
}

export const IconCaretRight = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="m8 4 8 8-8 8"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconCaretLeft = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        d="m16 4-8 8 8 8"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

// Directional based on RTL or LTR
export const IconCareti18nConditional = (props: IconProps) => {
  const isRtl = i18n.dir() === 'rtl'
  if (isRtl) {
    return <IconCaretLeft {...props} />
  } else {
    return <IconCaretRight {...props} />
  }
}

export const IconExport = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path stroke={'currentColor'} strokeWidth={2} d="M12 17V3" />
      <Path
        d="m6 8 6-6 6 6M16.5 13H21v8H3v-8h4.5"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
      />
    </Icon>
  )
}

// IconImport is just IconExport flipped vertically
export const IconImport = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path stroke={'currentColor'} strokeWidth={2} d="M12 3v14" />
      <Path
        d="m6 8 6-6 6 6M16.5 13H21v8H3v-8h4.5"
        stroke={'currentColor'}
        strokeWidth={2}
        strokeLinejoin="round"
        fill={'none'}
        transform={'rotate(180 12 12)'}
      />
    </Icon>
  )
}

export const IconInfo = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Circle
        cx={12}
        cy={12}
        r={9}
        stroke={'currentColor'}
        strokeWidth={2}
        fill={'none'}
      />
      <Path stroke={'currentColor'} strokeWidth={2} d="M12 10v7M12 6v2" />
    </Icon>
  )
}

export const IconTrash = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        stroke={'currentColor'}
        strokeLinejoin="round"
        strokeWidth={2}
        fill={'none'}
        d="m4 6 1.143 16h13.714L20 6"
      />
      <Path stroke={'currentColor'} strokeWidth={2} d="M9.5 9v10M14.5 9v10" />
      <Path
        stroke={'currentColor'}
        strokeLinejoin="round"
        strokeWidth={2}
        d="m17 6-1-4H8L7 6"
        fill={'none'}
      />
      <Path stroke={'currentColor'} strokeWidth={2} d="M0 6h24" />
    </Icon>
  )
}

export const IconRefresh = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        stroke={'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16.519 9.347h4.5v-4.5"
        fill={'none'}
      />
      <Path
        stroke={'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17.831 17.831a8.25 8.25 0 1 1 0-11.662l3.188 3.178"
        fill={'none'}
      />
    </Icon>
  )
}

export const IconCopy = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <Path
        fill={'currentColor'}
        d="M27 4.25H11a.75.75 0 0 0-.75.75v5.25H5a.75.75 0 0 0-.75.75v16a.75.75 0 0 0 .75.75h16a.75.75 0 0 0 .75-.75v-5.25H27a.75.75 0 0 0 .75-.75V5a.75.75 0 0 0-.75-.75Zm-6.75 22H5.75v-14.5h14.5v14.5Zm6-6h-4.5V11a.75.75 0 0 0-.75-.75h-9.25v-4.5h14.5v14.5Z"
      />
    </Icon>
  )
}

export const IconFilter = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        stroke={'currentColor'}
        fill="none"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 13 2 3h20l-8 10v8h-4v-8Z"
      />
    </Icon>
  )
}

export const IconEmail = (props: IconProps) => {
  return (
    <Icon viewBox="-950 950 100 75" {...props}>
      <Path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
        d="M-946.5,967v67.9l95,0V967L-946.5,967z M-899,1008.8l-36.7-35.1l73.5,0L-899,1008.8z M-858.1,978.9v46.8l-24.5-23.4    L-858.1,978.9z M-915.4,1002.3l-24.5,23.4v-46.8L-915.4,1002.3z M-910.6,1006.9l11.6,11.1l11.6-11.1l22.4,21.5h-68.1 L-910.6,1006.9z"
      />
    </Icon>
  )
}

export const IconLink = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <Path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m15.287 8.925 2.475-2.475a5.513 5.513 0 0 1 7.788 7.788l-3.538 3.525a5.487 5.487 0 0 1-7.775 0"
      />
      <Path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m16.713 23.075-2.475 2.475a5.513 5.513 0 0 1-7.788-7.787l3.538-3.525a5.488 5.488 0 0 1 7.775 0"
      />
    </Icon>
  )
}
