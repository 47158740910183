import React, { useState, useMemo, useEffect } from 'react'
import FeedItemSymbolInterpretation from '../../components/composite/feed/feed-item-symbol-interpretation'
import { Dimensions, View, ListRenderItem, Platform } from 'react-native'
import {
  CountedFastTag,
  FastDream,
  FastDreamService,
  FastGroup,
  FastTagDescription,
  TagSummaryStats,
  TagSummaryStatsService,
} from '../../../../api/_openapi'
import { RouteProp } from '@react-navigation/native'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser, selectUserIsPremium } from '../../ducks/user/user'
import { TabView } from '../../components/layout/tab-view'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { Loading } from '../../components/layout/loading'
import { TagPageHeader } from './header'
import { useTranslation } from 'react-i18next'
import { capitalize } from '../../modules/strings/string-helpers'
import { TagChartTab } from './tag-chart-tab'
import { FEW } from '../../i18n/config'
import { selectSubscriptionStatus } from '../../ducks/subscription/subscription'
import { EmptyFeedPage } from '../../components/composite/feed/page-empty-feed'
import { InsightTab } from '../Insights/InsightsMain/InsightTab'
import FeedItemDream from '../../components/composite/feed/feed-item-dream'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { FastTag } from '../../../../api/_openapi'
import { useAuth } from '../../contexts/auth-context'
import { CentredListEmptyComponent } from '../../components/layout/centred-list-empty-component'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { SansText } from '../../components/common/copy/text-sans'
import Autolink from 'react-native-autolink'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { Row } from '../../components/common/row/row'
import { IconGem } from '../../assets/react-native-svg/icons/icons-ui'
import { title } from '../../modules/strings/title-case'
import { SANS_BOLD_FONT_WEIGHT } from '../../constants/constants'
import { Center } from '../../components/common/center/center'
import { i18nKeyFromColorTag } from '../../modules/color/color-helpers'
import { TagType } from '../../../../api/frontend-types'
import {
  MainStackNavigationProp,
  MainStackParamList,
} from '../../navigation/types'
import { getTagLabel } from '../../modules/tag-helpers/tag-helpers'
import { ColorTagSubType } from '../../modules/color/color-types'

export type TagViewNavigationProp = MainStackNavigationProp<'PrivateTagView'>
export type TagViewRouteProp = RouteProp<MainStackParamList, 'PrivateTagView'>

type TagPageTabName = 'Dreams' | 'Insights' | 'Dictionary' | 'Charts'

type TagViewComponentProps = {
  tag: FastTag | null
  symbolDictItems: FastTagDescription[]
  currentGroup: FastGroup | undefined
  showMap: boolean
  navigation: any
  route: any
}

const tagTypesWithInsights: TagType[] = ['CHARACTER', 'SETTING', 'SYMBOL']

export default function TagViewComponent({
  tag,
  symbolDictItems,
  showMap,
  currentGroup,
  navigation,
  route,
}: TagViewComponentProps) {
  const privateGroupId = useSelector(selectPrivateGroupId)
  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()
  const color = useColorModeValue(BLACK, WHITE)

  // SELECTORS
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const appUser = useSelector(selectUser)
  const userIsPremium = useSelector(selectUserIsPremium)

  // VARS
  const isMoonPhaseTag = tag?.type === 'MOON_PHASE'
  // const isColorTag = tag?.type === 'COLOR'
  const pageWidth = Dimensions.get('window').width
  const tagSubtype = (tag?.subType || 'COLOR') as ColorTagSubType
  const pageTitle = tag && getTagLabel(tag.name, tag.type, tagSubtype, t)
  const activeGroupId = currentGroup?.id || ''

  const tabsToRender: {
    [key in TagPageTabName]: boolean
  } = {
    Dreams: true,
    Insights: tagTypesWithInsights.includes(tag?.type || 'NONE'),
    Dictionary: !isMoonPhaseTag && symbolDictItems.length > 0,
    // TODO: Enable charts tab
    Charts: !isMoonPhaseTag && userIsPremium,
  }

  // RENDERERS
  const renderDreamItem: ListRenderItem<FastDream> = ({ item, index }) => {
    return appUser ? (
      <FeedItemDream
        key={item.id}
        route={route}
        dream={item}
        index={index}
        privateGroupId={privateGroupId}
        currentGroup={currentGroup}
        appUser={appUser}
        activeGroupId={activeGroupId}
      />
    ) : null
  }

  const renderTagDescriptionItem: ListRenderItem<FastTagDescription> = ({
    item,
    index,
  }) => {
    return (
      <FeedItemSymbolInterpretation
        route={route}
        tagDescription={item}
        index={index}
      />
    )
  }

  // TAB CONTENTS
  const DreamTabContents = () => {
    const [dreams, setDreams] = useState<FastDream[]>([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
      if (!activeGroupId) return
      FastDreamService.groupDreams(activeGroupId, tag?.id).then((dreams) => {
        setDreams(dreams)
        setLoading(false)
      })
    }, [activeGroupId, tag?.id])

    return (
      <Tabs.FlatList
        data={dreams}
        initialNumToRender={10}
        renderItem={renderDreamItem}
        keyExtractor={(item) => item.id as string}
        contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
        ListEmptyComponent={CentredListEmptyComponent(
          loading ? <Loading color={color} /> : <EmptyFeedPage h="100%" />,
        )}
      />
    )
  }

  const InsightTabContents = () =>
    !authData?.token ? (
      <EmptyFeedPage />
    ) : (
      <InsightTab
        route={route}
        tagId={tag?.id}
        user={appUser}
        noInsightsMsg={t('insightsPage.emptyInsightsSymbol')}
        showLocked={true}
        subscriptionStatus={subscriptionStatus}
      />
    )

  const DictionaryTabContents = () => (
    <Tabs.FlatList
      data={symbolDictItems}
      renderItem={renderTagDescriptionItem}
      keyExtractor={(item: FastTagDescription) => item.id}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      ListEmptyComponent={CentredListEmptyComponent(<Loading color={color} />)}
    />
  )

  const ChartTabContents = () => {
    if (!tag || !currentGroup) {
      return null
    }

    return (
      <Tabs.ScrollView>
        <TagChartTab tag={tag} activeGroupId={currentGroup.id || ''} />
      </Tabs.ScrollView>
    )
  }

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Dictionary',
      children: <DictionaryTabContents />,
      label: t('common.symbolDictionary'),
    },
    {
      name: 'Charts',
      children: <ChartTabContents />,
      label: t('common.charts'),
    },
    {
      name: 'Insights',
      children: <InsightTabContents />,
      label: capitalize(t('common.insight_plural', { count: FEW })),
    },
    {
      name: 'Dreams',
      children: <DreamTabContents />,
      label: t('common.dream_plural', { count: FEW }),
    },
  ].filter((tab) => tabsToRender[tab.name as TagPageTabName])

  if (!userIsPremium && isMoonPhaseTag) {
    return (
      <PaddedContentArea>
        <Center>
          <Row alignItems="center" mb={5}>
            <IconGem size={'xs'} color={color} marginX={3} />
            <SansText fontWeight={SANS_BOLD_FONT_WEIGHT}>
              {title(t('common.premiumFeature'))}
            </SansText>
          </Row>
          <SansText>
            <Autolink
              text={t('subscriptions.subscribeToUnlock') as string}
              matchers={[
                {
                  pattern: /<linkTag>(.*)<\/linkTag>/g,
                  getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                  onPress: () => {
                    navigation.navigate('PrivateSubscriptionSettingsEdit', {
                      tab: 'premium',
                    })
                  },
                },
              ]}
              linkProps={{
                style: {
                  textDecorationLine: 'underline',
                },
              }}
            />
          </SansText>
        </Center>
      </PaddedContentArea>
    )
  }

  if (!tag || !currentGroup) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </View>
    )
  } else {
    return (
      <TabView
        header={() => (
          <TagPageHeader
            title={pageTitle || ''}
            fullTag={tag}
            currentGroup={currentGroup}
            pageWidth={pageWidth}
            userCanSeeMap={showMap}
            isMoonPhaseTag={isMoonPhaseTag}
          />
        )}
        tabs={tabs}
        scenes={{
          Dictionary: DictionaryTabContents,
          Dreams: DreamTabContents,
          Insights: InsightTabContents,
          Charts: ChartTabContents,
        }}
      />
    )
  }
}
