import { FastDream } from '../../../../../api/_openapi'

export function sortDreamsByShareDate<T extends FastDream>(
  dreams: T[],
  order: 'asc' | 'desc' = 'desc',
): T[] {
  const sortedDreams = dreams.sort((a, b) => {
    const descDiff =
      new Date(b.sharedAt).getTime() - new Date(a.sharedAt).getTime()
    return order === 'desc' ? descDiff : -descDiff
  })
  return [...sortedDreams]
}

export function sortDreamsByDate<T extends FastDream>(
  dreams: T[],
  order: 'asc' | 'desc' = 'desc',
): T[] {
  const sortedDreams = dreams.sort((a, b) => {
    const descDiff = new Date(b.date).getTime() - new Date(a.date).getTime()
    const dateDiff = order === 'desc' ? descDiff : -descDiff
    if (dateDiff === 0) {
      if (a.createdAt < b.createdAt) return order === 'asc' ? -1 : 1
      if (a.createdAt > b.createdAt) return order === 'asc' ? 1 : -1
      return 0
    } else {
      return dateDiff
    }
  })
  return [...sortedDreams]
}

export function idOrTempId(dream: FastDream): string {
  return dream.id
}

export function newFastDream(dream: Partial<FastDream>): FastDream {
  const fastDream: FastDream = {
    shareCode: '',
    collectionId: '',
    isVerified: false,
    city: null,
    country: null,
    createdAt: '',
    date: '',
    deleted: false,
    description: '',
    sharedDescription: null,
    displayDate: null,
    dreamcloudId: null,
    id: '',
    imageAlt: null,
    imageId: null,
    imageStatus: null,
    imageUrl: null,
    isDraft: false,
    isPublic: false,
    languageCode: null,
    latitude: null,
    longitude: null,
    note: '',
    sharedNote: null,
    place: null,
    placeId: null,
    province: null,
    shareId: '',
    sharedAt: '',
    source: null,
    title: '',
    type: 'dream',
    updatedAt: null,
    userId: '',
    commentCount: 0,
    reactionCount: 0,
    userReactionCount: 0,
    username: '',
    profileImageUrl: '',
  }

  return {
    ...fastDream,
    ...dream,
  }
}
