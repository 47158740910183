import React, { useMemo, useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import { GridDisplay } from '../../components/layout/grid-display'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { Factoid } from '../../components/composite/stats/factoid'
import { CloudIcon } from '../../assets/react-native-svg/illustrations/Cloud'
import { SunIcon } from '../../assets/react-native-svg/illustrations/Sun'
import { MoonIcon } from '../../assets/react-native-svg/illustrations/Moon'
import { useTranslation } from 'react-i18next'
import { getStats } from './chart-helpers'
import { DateRangeWithKey } from '../../../../api/frontend-types'
import {
  PADDING_HORIZONTAL_PIXELS,
  WEB_MAX_WIDTH,
} from '../../constants/constants'
import { DreamsPerDayChart } from './dreams-per-day-chart'
import { LucidDreamsChart } from './lucid-dreams-chart'
import { useDreams } from '../../hooks/useDreams'
import { useSelector } from '../../ducks/root-reducer'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import i18n from '../../i18n/i18nnext'
import { Box } from '../../components/common/box/box'
import { MoonPhasesChart } from './moon-phases-chart'
import { CountedFastTagService, CountedFastTag } from '../../../../api/_openapi'
import { selectUserIsPremium } from '../../ducks/user/user'

type OverviewChartsProps = {
  dateRangeWithKey: DateRangeWithKey
}

export const OverviewCharts = ({ dateRangeWithKey }: OverviewChartsProps) => {
  // SELECTORS
  const privateGroupId = useSelector(selectPrivateGroupId)
  const userIsPremium = useSelector(selectUserIsPremium)
  // HOOKS
  const { dreams, loading } = useDreams(privateGroupId, false)
  const { t } = useTranslation()

  // PAGE VARIABLES
  const hasDreams = !loading && dreams.length > 0
  const pageWidth = Dimensions.get('window').width
  const language = i18n.resolvedLanguage || 'en'

  const [moonPhaseData, setMoonPhaseData] = useState<CountedFastTag[]>([])
  const [isChartsLoading, setIsChartsLoading] = useState(true)

  const factoidLabels = {
    numDreams: {
      line1: t('chartsPage.factoids.dreamsRecorded.line1'),
      line2: t('chartsPage.factoids.dreamsRecorded.line2'),
      Icon: CloudIcon,
    },
    numDreamsPerDay: {
      line1: t('chartsPage.factoids.dreamsPerDay.line1'),
      line2: t('chartsPage.factoids.dreamsPerDay.line2'),
      Icon: SunIcon,
    },
    monthWithMostDreams: {
      line1: t('chartsPage.factoids.dreamiestMonth.line1'),
      line2: t('chartsPage.factoids.dreamiestMonth.line2'),
      Icon: MoonIcon,
    },
  }

  const dateRangeKey = dateRangeWithKey.key

  const topStats = useMemo(() => {
    if (loading) return
    if (dateRangeKey && dreams.length > 0) {
      return getStats(dreams, dateRangeKey, language)
    }
  }, [dreams, dateRangeKey, loading, language])

  useEffect(() => {
    if (privateGroupId && hasDreams) {
      setIsChartsLoading(true)
      Promise.all([
        CountedFastTagService.countedTypeTags(
          privateGroupId,
          'MOON_PHASE',
          10, // or whatever NUM_TAGS_TO_SHOW is
          dateRangeWithKey.range.start
            ? dateRangeWithKey.range.start.toISOString()
            : undefined,
          dateRangeWithKey.range.end
            ? dateRangeWithKey.range.end.toISOString()
            : undefined,
        ),
        // Add other data fetching promises here if needed
      ]).then(([moonPhaseResults]) => {
        setMoonPhaseData(moonPhaseResults)
        setIsChartsLoading(false)
      })
    }
  }, [privateGroupId, dateRangeWithKey, hasDreams])

  return (
    <Box>
      <PaddedContentArea alignItems={'center'}>
        {topStats && (
          <GridDisplay
            mt={4}
            mb={6}
            maxWidth={WEB_MAX_WIDTH}
            width={pageWidth - PADDING_HORIZONTAL_PIXELS * 2}
            items={Object.entries(topStats).map(([key, value]) => {
              // @ts-ignore
              const { line1, line2, Icon } = factoidLabels[key]
              return {
                id: key,
                item: (
                  <Factoid
                    key={key}
                    Icon={Icon}
                    factoidFact={`${value}`}
                    textLineOne={`${line1}`}
                    textLineTwo={line2}
                    layout="fact-text-text"
                  />
                ),
              }
            })}
          />
        )}
      </PaddedContentArea>
      {[
        hasDreams && (
          <React.Fragment key="dreams">
            <DreamsPerDayChart
              dateRangeWithKey={dateRangeWithKey}
              dreams={dreams}
            />
            <LucidDreamsChart dateRangeWithKey={dateRangeWithKey} />
          </React.Fragment>
        ),
        <MoonPhasesChart
          key="moon-phases"
          dateRangeWithKey={dateRangeWithKey}
          moonPhaseData={moonPhaseData}
          skipDataFetch={true}
        />,
      ].sort((a, b) => (userIsPremium ? -1 : 1))}
    </Box>
  )
}
