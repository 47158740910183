import React from 'react'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Row } from '../row/row'
import { Column } from '../column/column'
import {
  BLACK,
  MID_GRAY,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../constants/ui-constants'

type ProgressBarProps = InterfaceBoxProps & {
  progress: number
  total: number
}

export const ProgressBar = ({ progress, total, ...rest }: ProgressBarProps) => {
  const fill = useColorModeValue(BLACK, WHITE)
  const barBgColor = useColorModeValue(LIGHT_MID_GRAY, MID_GRAY)
  const { t } = useTranslation()
  const percent = Math.round((progress / total) * 100)
  return (
    <Column width={'100%'} alignItems={'center'} {...rest}>
      <Row width={'100%'} height={4}>
        <Row backgroundColor={barBgColor} width={'100%'} borderRadius={20}>
          <Row
            style={{
              width: `${percent}%`,
              backgroundColor: fill,
              borderRadius: 20,
            }}
          />
        </Row>
      </Row>
      <SansText mt={2}>
        {t('importDataPage.progressIndicator', {
          num: progress,
          total: total,
        })}
      </SansText>
    </Column>
  )
}
