import React, { FC, ReactNode } from 'react'
import {
  SMALL_LINE_HEIGHT,
  XXL_LINE_HEIGHT,
} from '../../../constants/constants'
import { DisplayDate } from './display-date'
import { useUserDate } from '../../../hooks/useUserDate'
import { HeadingMain } from './heading-main'
import { TextProps } from './text'
import { Column } from '../column/column'
import {
  ScriptType,
  SimpleTextAlign,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import { dateFromString } from '../../../modules/strings/string-helpers'
import { AstrologicalData } from '../../composite/astrological/astrological-data'

type HeadingMainWithDateProps = TextProps & {
  children: ReactNode
  date?: string
  displayDate?: string | null
  textAlign?: SimpleTextAlign
  scriptType?: ScriptType
  language?: SupportedLanguage
  includeLinkToMoonPhase?: boolean
}

export const HeadingMainWithDate: FC<HeadingMainWithDateProps> = ({
  children,
  lineHeight = XXL_LINE_HEIGHT,
  mt = 4,
  mb = 5,
  date,
  displayDate,
  textAlign,
  scriptType,
  language,
  includeLinkToMoonPhase = false,
  ...rest
}) => {
  const { dateString } = useUserDate(date as string)
  const dateVar = dateFromString(date as string)
  const title = children as string
  const hasTitle = Boolean(title)
  const hasDate = Boolean(date)

  if (!hasTitle && !hasDate) {
    return null
  } else {
    return (
      <Column
        mt={mt}
        mb={mb}
        justifyContent={'center'}
        pointerEvents="box-none"
      >
        {/* If there's a title, show that. Otherwise, show the date as the title */}
        <HeadingMain
          selectable={true}
          textAlign={textAlign}
          scriptType={scriptType}
          language={language}
          {...rest}
        >
          {!children && date ? dateString : children}
        </HeadingMain>
        {/* If there's a title, show the date in small text. */}
        {/* Otherwise if the date has been used in the title, show nothing */}
        {date && children ? (
          <DisplayDate
            date={date}
            mt={2}
            displayDate={displayDate}
            includeLinkToMoonPhase={includeLinkToMoonPhase}
          />
        ) : null}
        {dateVar && !children && (
          <AstrologicalData
            date={date as string}
            size={SMALL_LINE_HEIGHT}
            includeLinkToMoonPhase={includeLinkToMoonPhase}
          />
        )}
      </Column>
    )
  }
}
