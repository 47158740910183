import React, { useEffect } from 'react'
import { Dimensions, Platform } from 'react-native'
import { RootStackScreenProps } from '../../navigation/types'
import {
  GenerateTagImage,
  GenerateTagImageService,
} from '../../../../api/_openapi'
import { HeadingMain } from '../../components/common/copy/heading-main'
import TagViewImage from '../TagView/tag-view-image'
import { Row } from '../../components/common/row/row'
import { useNavigation } from '@react-navigation/core'
import { LG_FONTSIZE_PIXELS, WEB_MAX_WIDTH } from '../../constants/constants'
import { TextArea } from '../../components/common/inputs/input-textarea'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { ButtonPill } from '../../components/common/buttons/button-pill'
import { FieldMultiImagePickerWithCamera } from '../../components/common/inputs/multi-image-picker-with-camera'
import { ImageOnObject } from '../../../../api/frontend-types'
import { WHITE } from '../../constants/ui-constants'
import { Column } from '../../components/common/column/column'
import { BodyText } from '../../components/common/copy/text-body'
import { elsewhereToast } from '../../components/common/toast/toast'

export default function TagImageAdd({
  route,
}: RootStackScreenProps<'PrivateTagImageAdd'>) {
  const id = route.params?.id
  const name = route.params?.name
  const pageWidth = Dimensions.get('window').width
  const navigation = useNavigation<any>()

  const [pickedImages, setPickedImages] = React.useState<ImageOnObject[]>([])
  const [description, setDescription] = React.useState<string>('')
  const [selectedImage, setSelectedImage] = React.useState<
    ImageOnObject | undefined
  >(undefined)

  const [loading, setLoading] = React.useState(true)

  const setFieldsFromTagImage = (tagImage: GenerateTagImage) => {
    if (tagImage.imageId && tagImage.imageUrl && tagImage.imageAlt) {
      setSelectedImage({
        id: tagImage.imageId,
        url: tagImage.imageUrl,
        alt: tagImage.imageAlt,
      })
    }

    if (tagImage.description) {
      setDescription(tagImage.description)
    }
  }

  //Load the image for the tag if it exists
  useEffect(() => {
    if (id) {
      GenerateTagImageService.getTagImage(id)
        .then((tagImage: GenerateTagImage) => {
          setFieldsFromTagImage(tagImage)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [id])

  const imagePickerOptions: any = {
    mediaType: 'photo',
    includeBase64: false,
    width: 512,
    height: 512,
    cropping: true,
    cropperCircleOverlay: true,
    cropperChooseColor: WHITE,
    cropperCancelColor: WHITE,
    type: 'profile-image',
    alt: 'Profile picture for group',
    croppingAspectRatio: Platform.OS === 'web' ? 1 : undefined,
    showSkipCropButton: Platform.OS === 'web' ? false : undefined,
    croppingDefaultSelectionRatio: Platform.OS === 'web' ? 0.99 : undefined,
  }

  const onImagesChanged = (images: ImageOnObject[]) => {
    setPickedImages(images)
    if (images.length) {
      const pickedImage = images[images.length - 1]
      generateDescription(pickedImage)
    }
  }

  const generateDescription = (image: ImageOnObject) => {
    setLoading(true)
    GenerateTagImageService.generateTagImage(id, {
      imageId: image.id,
    }).then((tagImage: GenerateTagImage) => {
      setLoading(false)
      setFieldsFromTagImage(tagImage)
      elsewhereToast.showToast({
        description: 'Description created.',
        status: 'success',
      })
    })
  }

  //Generates an image for the tag based on the description
  const generateImage = (description: string) => {
    setLoading(true)
    GenerateTagImageService.generateTagImage(id, {
      description,
    }).then((tagImage: GenerateTagImage) => {
      setLoading(false)
      setFieldsFromTagImage(tagImage)
      elsewhereToast.showToast({
        description: 'Description created.',
        status: 'success',
      })
    })
  }

  const saveTagImage = () => {
    if (!description || !selectedImage?.id) {
      return
    }
    //the user is happy with everything, save!
    GenerateTagImageService.saveTagImage(id, {
      imageId: selectedImage.id,
      description,
    }).then(() => {
      elsewhereToast.showToast({
        description: 'Image saved.',
        status: 'success',
      })
      navigation.goBack()
    })
  }

  return (
    <PaddedContentArea>
      <ButtonPill mb={4} onPress={saveTagImage}>
        !! SAVE - UPPER RIGHT TICK BOX !!
      </ButtonPill>
      <Row width={'100%'} display={'flex'} justifyContent={'center'}>
        <TagViewImage
          name={name}
          type={'CHARACTER'}
          pageWidth={pageWidth}
          imageUrl={selectedImage?.url}
          loading={loading}
        />
      </Row>
      <HeadingMain mt={4}>{name}</HeadingMain>
      <BodyText my={3}>
        You can either upload an image for your character or write a description
        and we'll generate an image for you! We'll use this image and
        description to depict them in your dreams in the future! When you upload
        an image, we'll generate a description automatically, but you can edit
        it if it isn't right or if you want to add additional details.
      </BodyText>
      <Row mt={2} mb={8} justifyContent={'center'}>
        <Column maxWidth={300}>
          <FieldMultiImagePickerWithCamera
            pickedImages={pickedImages}
            onChange={onImagesChanged}
            options={imagePickerOptions}
            buttonSize={'xs'}
            buttonText={'Upload Image'}
            showImages={false}
          />
        </Column>

        <Column>
          <ButtonPill
            ml={2}
            buttonSize={'xs'}
            width={'auto'}
            onPress={() => generateImage(description)}
          >
            Generate Image
          </ButtonPill>
        </Column>
      </Row>

      <Row width={'100%'} display={'flex'} justifyContent={'center'}>
        <TextArea
          fieldName={'character-image'}
          autoFocus={true}
          id={id}
          placeholder={"Visual description of '" + name + "'..."}
          value={description}
          onChangeText={setDescription}
          minRows={2}
          minHeight={LG_FONTSIZE_PIXELS * 2}
        />
      </Row>
    </PaddedContentArea>
  )
}
