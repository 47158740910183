import React, { FC } from 'react'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SELECTION_COLOR } from '../../../constants/constants'
import i18n from '../../../i18n/i18nnext'
import {
  getHeadingFontKey,
  getScriptTypeForLanguage,
} from '../../../modules/language-helpers/language-helpers'
import { TextProps, Text } from './text'
import {
  ScriptType,
  SimpleTextAlign,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import { View } from '../view/view'

type HeadingMainProps = TextProps & {
  textAlign?: SimpleTextAlign
  scriptType?: ScriptType
  language?: SupportedLanguage
}

const LH_ARABIC = 80
const LH_LATIN = 48

export const HeadingMain: FC<HeadingMainProps> = ({
  children,
  textAlign = 'center',
  scriptType,
  language,
  ...rest
}: HeadingMainProps) => {
  // HOOKS
  const bgColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const fontKey = getHeadingFontKey(lang, scriptType)
  const script = scriptType || getScriptTypeForLanguage(lang)
  const textAlignValue = textAlign || 'center'
  const lineHeight = script === 'arabic' ? LH_ARABIC : LH_LATIN

  return (
    <View pointerEvents="none">
      <Text
        bgColor={bgColor}
        fontFamily={fontKey}
        fontWeight={300}
        style={{
          textTransform: 'uppercase',
          textAlign: textAlignValue,
        }}
        fontSize={'2xl'}
        lineHeight={lineHeight}
        selectionColor={SELECTION_COLOR}
        {...rest}
      >
        {children}
      </Text>
    </View>
  )
}
