import { getLocales } from 'react-native-localize'
import { dateFromString } from '../../modules/strings/string-helpers'
import { intlFormat } from 'date-fns'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import formatISO from 'date-fns/formatISO'
import { validifyLanguageLocaleCode } from '../../modules/language-helpers/language-helpers'

export const useUserDate = (date?: string) => {
  // THIS SHOULD BE STRICTLY ONLY USED FOR DATES STORED AS "DATES" - EG DREAM.DATE
  const user = useSelector(selectUser)

  if (!date) {
    return { dateString: '' }
  }

  const userlanguageCode = user?.languageCode || 'en'
  const locale = getLocales()[0]
  const deviceLanguageTag = locale.languageTag || 'en-US'

  const userLanguageTagRaw = userlanguageCode
    ? userlanguageCode + '-' + locale.countryCode
    : deviceLanguageTag

  const userLanguageTag = validifyLanguageLocaleCode(userLanguageTagRaw)

  const jsDate = dateFromString(date)
  const dateISO = jsDate && formatISO(jsDate, { representation: 'complete' })

  const DATE_PARTS = dateISO?.split('T')[0]?.split('-')
  if (DATE_PARTS?.length !== 3) {
    return { dateString: '' }
  }

  const YEAR = parseInt(DATE_PARTS[0], 10)
  const MONTH = parseInt(DATE_PARTS[1], 10) - 1 // Month is 0-indexed
  const DAY = parseInt(DATE_PARTS[2], 10)

  const dateObj = new Date(Date.UTC(YEAR, MONTH, DAY))

  const dateString = intlFormat(
    dateObj,
    { timeZone: 'UTC' },
    {
      locale: userLanguageTag.replace('_', '-'),
    },
  )

  return { dateString }
}
