/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateTagImage } from '../models/GenerateTagImage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GenerateTagImageService {
  /**
   * Creates a GenerateTagImage resource.
   * Creates a GenerateTagImage resource.
   * @param id GenerateTagImage identifier
   * @param requestBody The new GenerateTagImage resource
   * @returns GenerateTagImage GenerateTagImage resource created
   * @throws ApiError
   */
  public static generateTagImage(
    id: string,
    requestBody: GenerateTagImage,
  ): CancelablePromise<GenerateTagImage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/fast-tags/{id}/generate-image',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a GenerateTagImage resource.
   * Retrieves a GenerateTagImage resource.
   * @param id GenerateTagImage identifier
   * @returns GenerateTagImage GenerateTagImage resource
   * @throws ApiError
   */
  public static getTagImage(
    id: string,
  ): CancelablePromise<GenerateTagImage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/fast-tags/{id}/get-image',
      path: {
        'id': id,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Creates a GenerateTagImage resource.
   * Creates a GenerateTagImage resource.
   * @param id GenerateTagImage identifier
   * @param requestBody The new GenerateTagImage resource
   * @returns GenerateTagImage GenerateTagImage resource created
   * @throws ApiError
   */
  public static saveTagImage(
    id: string,
    requestBody: GenerateTagImage,
  ): CancelablePromise<GenerateTagImage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/fast-tags/{id}/save-image',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
