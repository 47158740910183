import React from 'react'
import { Box } from '../../common/box/box'
import { LINE_WIDTH } from '../../../constants/constants'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { Column } from '../../common/column/column'
import {
  ColorMode,
  Hemisphere,
  MoonPhase,
} from '../../../../../api/frontend-types'

const CRESCENT_PERCENT = '23%'
const QUARTER_PERCENT = '47%' // Approx centre of curve, not the edge
const CRESCENT_INNER_MULTIPLIER = 1.5
const QUARTER_INNER_MULTIPLIER = 6
const FULL_MOON_MULTIPLIER = 1

const innerCircleColor: { [key in MoonPhase]: 'white' | 'black' | null } = {
  newMoon: null,
  waxingCrescent: 'black',
  firstQuarter: 'black',
  waxingGibbous: 'white',
  fullMoon: null,
  waningGibbous: 'white',
  lastQuarter: 'black',
  waningCrescent: 'black',
}

const innerCircleWidthMultiplier: { [key in MoonPhase]: number } = {
  newMoon: FULL_MOON_MULTIPLIER,
  waxingCrescent: CRESCENT_INNER_MULTIPLIER,
  firstQuarter: QUARTER_INNER_MULTIPLIER,
  waxingGibbous: CRESCENT_INNER_MULTIPLIER,
  fullMoon: FULL_MOON_MULTIPLIER,
  waningGibbous: CRESCENT_INNER_MULTIPLIER,
  lastQuarter: QUARTER_INNER_MULTIPLIER,
  waningCrescent: CRESCENT_INNER_MULTIPLIER,
}

type InnerCirclePosition = {
  left: string | undefined
  right: string | undefined
}
const innerCirclePosition: { [key in MoonPhase]: InnerCirclePosition } = {
  newMoon: { left: undefined, right: undefined },
  waxingCrescent: { left: undefined, right: CRESCENT_PERCENT },
  firstQuarter: { left: undefined, right: QUARTER_PERCENT },
  waxingGibbous: { left: CRESCENT_PERCENT, right: undefined },
  fullMoon: { left: undefined, right: undefined },
  waningGibbous: { left: undefined, right: CRESCENT_PERCENT },
  lastQuarter: { left: QUARTER_PERCENT, right: undefined },
  waningCrescent: { left: CRESCENT_PERCENT, right: undefined },
}

type MoonProps = {
  phase: MoonPhase
  colorMode: ColorMode
  hemisphere: Hemisphere
  size?: number
  borderWidth?: number
  moonColor?: string
  shadowColor?: string
  borderColor?: string
}

export const Moon = ({
  phase,
  colorMode,
  hemisphere,
  size = 50,
  borderWidth = LINE_WIDTH,
  moonColor = WHITE,
  shadowColor = BLACK,
  borderColor,
}: MoonProps) => {
  // VARS
  const overlayCircleColor =
    innerCircleColor[phase] === 'black' ? shadowColor : moonColor
  const innerCircleWidth = size * innerCircleWidthMultiplier[phase]
  const framedSize = size - 2 * borderWidth
  const topOffset = (innerCircleWidth - framedSize) / 2
  const innerCirclePos = innerCirclePosition[phase]
  const hasInnerCircle = !(phase === 'fullMoon' || phase === 'newMoon')

  const getMoonBgColor = (): string => {
    if (phase === 'fullMoon') {
      return moonColor
    } else if (phase === 'newMoon') {
      return shadowColor
    } else {
      return innerCircleColor[phase] === 'black' ? moonColor : shadowColor
    }
  }

  const getBorderColor = (): string => {
    if (borderColor) {
      return borderColor
    } else {
      return colorMode === 'dark' ? moonColor : shadowColor
    }
  }

  return (
    <Column
      width={`${size}px`}
      style={{
        transform: hemisphere === 'Southern' ? 'rotate(180deg)' : 'none',
      }}
    >
      <Box
        width={`${size}px`}
        height={`${size}px`}
        borderRadius="full"
        borderColor={getBorderColor()}
        borderWidth={borderWidth}
        position={'relative'}
        overflow={'hidden'}
        backgroundColor={getMoonBgColor()}
      >
        {/* INNER CIRCLE (SHADOW / LIGHT) */}
        {hasInnerCircle && (
          <Box
            backgroundColor={overlayCircleColor}
            position={'absolute'}
            width={innerCircleWidth}
            height={innerCircleWidth}
            top={-topOffset}
            left={innerCirclePos.left}
            right={innerCirclePos.right}
            borderRadius="full"
          />
        )}
      </Box>
    </Column>
  )
}
