import React from 'react'
import { SplashScreen } from '../screens/SplashScreen'
import {
  NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack'
import { View } from 'react-native'
import { useColorModeValue } from 'native-base'
import {
  HEADER_TITLE_FONT_SIZE,
  LINE_WIDTH,
  WEB_MAX_WIDTH,
} from '../constants/constants'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../constants/ui-constants'
import { cap } from '../modules/strings/string-helpers'
import { HeaderLeft, HeaderRight } from './shared'
import MainStack from './RootStack'
import AuthStack from './AuthStack'
import { NavigationContainer } from '../libs/react-navigation'
import { DefaultTheme } from '@react-navigation/native'
import { linking } from './linking'
import { Header as RNHeader } from '@react-navigation/elements'
import * as Screens from '../screens'
import { RootStackParamList } from './types'
import { useSafeArea } from '../libs/safe-area-view'
import { navigationRef } from './RootNavigation'
import { OfflineBar } from '../components/composite/offline-bar/offline-bar'
import { useAuth } from '../contexts/auth-context'
import { UserInit } from '../UserInit'
import i18n from '../i18n/i18nnext'
import { getSansFont } from '../modules/language-helpers/language-helpers'
import { Center } from '../components/common/center/center'

const RootStack = createNativeStackNavigator<RootStackParamList>()

export default function Routes() {
  // HOOKS
  const { t } = useTranslation()
  const { top } = useSafeArea()
  const { authData } = useAuth()
  const statusBarStyle = useColorModeValue('dark', 'light')
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const color = useColorModeValue(BLACK, WHITE)

  // VARS
  const borderTopColor = color
  const borderBottomColor = color
  const statusBarColor = backgroundColor
  const headerTintColor = borderTopColor
  const language = i18n.resolvedLanguage || 'en'
  const sansFontFamily = getSansFont(language)

  const defaultScreenOptions = {
    headerShown: true,
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor,
      borderBottomColor,
      borderBottomWidth: LINE_WIDTH,
    },
    contentStyle: {
      headerTintColor,
      width: '100%',
    },
    headerTitleStyle: {
      fontFamily: sansFontFamily,
      fontSize: HEADER_TITLE_FONT_SIZE,
    },
    header: (props: any) => {
      return (
        <View
          style={{
            marginHorizontal: 'auto',
            maxWidth: WEB_MAX_WIDTH,
            width: '100%',
            paddingTop: top,
          }}
        >
          <RNHeader
            {...props.options}
            title={props.options.title || props.options.headerTitle}
            headerTintColor={headerTintColor}
            headerBackgroundContainerStyle={{ backgroundColor }}
            navigation={props.navigation}
            route={props.route}
            headerStyle={{
              backgroundColor,
              borderBottomColor,
              borderBottomWidth: LINE_WIDTH,
            }}
          />
          <OfflineBar />
        </View>
      )
    },
  } as NativeStackNavigationOptions

  const defaultGroupOptions = {
    headerTitleStyle: {
      fontFamily: sansFontFamily,
      fontWeight: '400',
    },
    headerTitleAlign: 'center',
  } as NativeStackNavigationOptions

  const dark = useColorModeValue(false, true)

  const navTheme = {
    ...DefaultTheme,
    dark,
    colors: {
      ...DefaultTheme.colors,
      background: backgroundColor,
    },
  }

  return (
    <UserInit>
      <NavigationContainer
        documentTitle={{
          formatter: (options, route: any) => {
            return `${
              typeof options?.headerTitle === 'string'
                ? options?.headerTitle
                : options?.title ?? route?.name ?? t('common.siteName')
            }`
          },
        }}
        linking={linking}
        ref={navigationRef}
        theme={navTheme}
        fallback={
          <Center bg={backgroundColor} w="100%" h="100%">
            <SplashScreen />
          </Center>
        }
      >
        <RootStack.Navigator
          screenOptions={{
            statusBarStyle,
            statusBarColor,
            statusBarAnimation: 'fade',
          }}
          initialRouteName={!!authData?.token ? 'MainTabs' : 'Home'}
        >
          {!!authData?.token
            ? MainStack(RootStack, defaultGroupOptions, defaultScreenOptions)
            : AuthStack(RootStack, defaultGroupOptions, defaultScreenOptions)}
          {/* Shared screens below*/}
          <RootStack.Group
            navigationKey={authData?.token ? 'user' : 'guest'}
            screenOptions={{
              ...defaultGroupOptions,
              presentation: 'containedModal',
              animation: 'slide_from_bottom',
              headerBackVisible: false,
              headerRight: (props) => (
                <HeaderRight {...props} text={t('common.close')} />
              ),
            }}
          >
            {/* Any screens shared by web and app below */}
            <RootStack.Screen
              name="PrivateFriendPreferences"
              component={Screens.FriendPreferences}
              options={{
                ...defaultScreenOptions,
                headerTitle: t(':Preferences'),
                headerShown: Boolean(authData?.token),
              }}
            />
            <RootStack.Screen
              name="Invite"
              component={Screens.Invite}
              options={{
                ...defaultScreenOptions,
                headerTitle: t('common.groupInvite'),
                headerShown: false,
              }}
            />
            <RootStack.Screen
              name="About"
              component={Screens.About}
              options={{
                ...defaultScreenOptions,
                headerTitle: t('common.aboutElsewhere'),
                headerShown: Boolean(authData?.token),
              }}
            />
            <RootStack.Screen
              name="Journal"
              component={Screens.Journal}
              options={{
                ...defaultScreenOptions,
                headerTitle: t('common.elsewhereJournal'),
                headerShown: Boolean(authData?.token),
              }}
            />
            <RootStack.Screen
              name="AstralTravelBlueprint"
              component={Screens.AstralTravelBlueprint}
              options={{
                ...defaultScreenOptions,
                headerTitle: t('Astral Travel Blueprint Free Trial'),
                headerShown: Boolean(authData?.token),
              }}
            />
            <RootStack.Screen
              name="DeleteMyData"
              component={Screens.DeleteMyData}
              options={{
                ...defaultScreenOptions,
                headerTitle: t('deleteMyData.heading'),
                headerShown: Boolean(authData?.token),
              }}
            />
          </RootStack.Group>
          <RootStack.Group
            navigationKey={authData ? 'user' : 'guest'}
            screenOptions={{
              ...defaultGroupOptions,
            }}
          >
            {/* Library */}
            <RootStack.Screen
              name="Library"
              component={Screens.Library}
              options={{
                ...defaultScreenOptions,
                title: t('common.library'),
                headerLeft: HeaderLeft,
              }}
            />
            <RootStack.Screen
              name="LibraryDreamerView"
              component={Screens.DreamerView}
              options={{
                ...defaultScreenOptions,
                title: t('common.user'),
                headerLeft: HeaderLeft,
              }}
            />
            <RootStack.Screen
              name="LibraryDreamView"
              component={Screens.DreamView}
              options={{
                ...defaultScreenOptions,
                title: cap(t('common.dream_plural', { count: 1 })),
                headerLeft: HeaderLeft,
              }}
            />
            <RootStack.Screen
              name="LibraryInsightView"
              component={Screens.InsightView}
              options={{
                ...defaultScreenOptions,
                title: cap(t('common.insight_plural', { count: 1 })),
                headerLeft: HeaderLeft,
              }}
            />
          </RootStack.Group>
          {/* Web-only */}
          <RootStack.Group screenOptions={{ headerShown: false }}>
            {/* Privacy, Terms and cookies are web only as they are reliant on Iubenda */}
            <RootStack.Screen name="Privacy" component={Screens.Privacy} />
            <RootStack.Screen name="Terms" component={Screens.Terms} />
            <RootStack.Screen name="Cookies" component={Screens.Cookies} />
            <RootStack.Screen
              name="DreamAnimationFestival"
              component={Screens.DreamAnimationFestival}
              options={{
                ...defaultScreenOptions,
                title: t('Dream Animation Festival'),
              }}
            />
            <RootStack.Screen
              name="DreamAnimationFestivalDreamView"
              component={Screens.DreamView}
              options={{
                ...defaultScreenOptions,
                title: t('Dream Animation Festival'),
              }}
            />
            <RootStack.Screen
              name="SymbolDictionary"
              component={Screens.SymbolDictionary}
              options={{
                ...defaultScreenOptions,
                title: t('Symbol Dictionary'),
              }}
            />
          </RootStack.Group>
        </RootStack.Navigator>
      </NavigationContainer>
    </UserInit>
  )
}
