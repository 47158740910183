import { ColorDefinition, PickerI18nLayout, TintGrouping } from './color-types'
import { colorMap } from './colorList'

function groupTints(colorList: ColorDefinition[]): TintGrouping[] {
  const tintMap = colorList.reduce((acc, curr: ColorDefinition) => {
    // Just skip over 'metallic', it's not a real colour
    // Just a way to group metallic colours
    if (curr.name === 'metallic') {
      return acc
    }

    // Group by hue and saturation type
    const [hueName, saturationType] = curr.hslType
    const key = hueName + saturationType

    if (acc[key]) {
      return {
        ...acc,
        [key]: { tints: [...acc[key].tints, curr] },
      }
    } else {
      return {
        ...acc,
        [key]: { tints: [curr] },
      }
    }
  }, {} as { [key: string]: TintGrouping })

  return Object.values(tintMap)
}

export const getAssociatedColors = (
  simpleColorName: string,
  advancedModeOn: boolean,
  pickerLayout: PickerI18nLayout,
): TintGrouping[] => {
  // If advanced mode is on, we show all the colours
  // If it's off, we only show the colors where
  // simpleOpts.showInSimpleModeForLayout includes the current layout
  const allHues: ColorDefinition[] = Object.values(colorMap)
  const hues = advancedModeOn
    ? allHues
    : allHues.filter((c) =>
        c.simpleOpts.showInSimpleModeForLayout.includes(pickerLayout),
      )
  const groupedColors = groupTints(hues)

  const ret = groupedColors
    .filter((c: TintGrouping) =>
      c.tints.some((t) => t.associations.some((a) => a === simpleColorName)),
    )
    .map((c: TintGrouping) => {
      return {
        ...c,
        tints: c.tints.filter((t) =>
          t.associations.some((a) => a === simpleColorName),
        ),
      }
    })
  return ret
}
