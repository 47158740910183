import React, { useEffect, useState } from 'react'
import { SymbolsList } from '../Symbols/mysymbols'
import { useSelector } from '../../ducks/root-reducer'
import { useTranslation } from 'react-i18next'
import { CountedFastTag, CountedFastTagService } from '../../../../api/_openapi'
import { selectUser } from '../../ducks/user/user'
import { Loading } from '../../components/layout/loading'
import { Column } from '../../components/common/column/column'
import { ScrollView } from '../../components/common/scrollview/scrollview'

export default function Characters({ route }: { route: any }) {
  const { t } = useTranslation()
  const [topCharacters, setTopCharacters] = React.useState<CountedFastTag[]>([])
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(Boolean(user && user.userGroupId))

  useEffect(() => {
    if (user && user.userGroupId) {
      CountedFastTagService.countedTypeTags(
        user.userGroupId,
        'CHARACTER',
        1000,
      ).then((countedTags) => {
        setTopCharacters(countedTags.filter((tag) => tag.type === 'CHARACTER'))
        setLoading(false)
      })
    }
  }, [user])

  if (loading) {
    return <Loading />
  }

  return (
    <ScrollView>
      <Column w={'100%'}>
        <SymbolsList tags={topCharacters} canSearch={true} />
      </Column>
    </ScrollView>
  )
}
