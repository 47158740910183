import React, { useEffect } from 'react'
import {
  FastDream,
  FastDreamService,
  TagHighlight,
} from '../../../../api/_openapi'
import { Quote } from '../../components/layout/quote'
import { useSelector } from '../../ducks/root-reducer'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { dateFromString } from '../../modules/strings/string-helpers'
import { useUserDate } from '../../hooks/useUserDate'
import ConditionalLink from '../../utilities/ConditionalLink'
import { MainStackParamList, RootStackParamList } from '../../navigation/types'
import { Link } from '@react-navigation/native'
import { UseLinkPropsProps } from '../../utilities/useLinkPropsFixed'
import { ButtonPill } from '../../components/common/buttons/button-pill'

type DreamHighlightProp = {
  highlight: TagHighlight
  size?: string
  maxWidth?: number | string
}

export default function DreamHighlight({
  highlight,
  size = 'sm',
  maxWidth = 400,
}: DreamHighlightProp) {
  const [dream, setDream] = React.useState<FastDream | null>(null)
  const privateGroupId = useSelector(selectPrivateGroupId)
  const dreamDate = useUserDate(dream?.date)

  useEffect(() => {
    if (highlight?.dreamId && privateGroupId) {
      FastDreamService.groupDream(privateGroupId, highlight?.dreamId).then(
        (dream) => {
          setDream(dream)
        },
      )
    }
  }, [highlight?.dreamId, privateGroupId])

  const linkObject: any = {
    screen: 'PrivateDreamView' as keyof RootStackParamList,
    params: {
      id: highlight?.dreamId,
      isRevealing: true,
    },
  }

  const sourceTitle = dream
    ? dream.title || 'dream from ' + dreamDate.dateString
    : ''

  const highlightText = (highlight?.highlight || '')
    .replaceAll('<mark>', '')
    .replaceAll('</mark>', '')
  const maxLength = 125

  let highlightAbbreviated = highlightText
  //find position of any period, space, or other punctuation after the 150th character
  if (highlightAbbreviated.length > maxLength) {
    const punctuationIndex = highlightText.slice(maxLength).search(/[.\s,!?]/)
    if (punctuationIndex !== -1) {
      highlightAbbreviated = highlightText.slice(
        0,
        maxLength + punctuationIndex,
      )
    } else {
      highlightAbbreviated = highlightText.slice(0, maxLength)
    }
    highlightAbbreviated += '...'
  }

  if (!highlight) return null

  return (
    <ConditionalLink condition={true} to={linkObject}>
      <Quote
        mt={0}
        quote={highlightAbbreviated}
        source={sourceTitle}
        fontSize={size}
        maxWidth={maxWidth}
      />
    </ConditionalLink>
  )
}
