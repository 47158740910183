import React, { useEffect, useState } from 'react'
import { Dimensions, Keyboard, Platform, Pressable } from 'react-native'
import { SymbolGrid } from '../../components/composite/symbols/symbol-grid'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { CountedFastTag } from '../../../../api/_openapi'
import { View } from '../../components/common/view/view'
import { Row } from '../../components/common/row/row'
import { InputSearch } from '../../components/common/inputs/input-search'
import { ButtonIcon } from '../../components/common/buttons/button-icon'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../../components/common/action-sheet/action-sheet-menu'
import useContentWidth from '../../hooks/useContentWidth'
import { useTranslation } from 'react-i18next'

type TagsProp = {
  tags: CountedFastTag[]
  canSearch?: boolean
}

type SearchOrder = 'recent' | 'common' | 'a'

export const SymbolsList = ({ tags, canSearch = false }: TagsProp) => {
  let pageWidth = Dimensions.get('window').width
  pageWidth = Math.min(pageWidth, WEB_MAX_WIDTH)

  // STATE
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOrder, setSortOrder] = useState<SearchOrder>('common')
  const [filteredTags, setFilteredTags] = useState<CountedFastTag[]>([])
  const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState(false)

  // HOOKS
  const { t } = useTranslation()

  // SELECTORS
  const contentWidth = useContentWidth()

  // EFFECTS
  useEffect(() => {
    if (filteredTags.length === 0 && tags.length > 0) {
      setFilteredTags(tags)
    }
  }, [tags])

  // VARS
  const searchBarAreaWidth = contentWidth.paddedContentWidth
  const filterButtonWidth = 60
  const isFiltering = sortOrder !== 'common'

  const handleSearch = (sortOrder: SearchOrder) => {
    try {
      if (searchTerm === '') {
        setFilteredTags(sortTags(tags, sortOrder))
      } else {
        const results = searchTags(searchTerm)
        setFilteredTags(sortTags(results, sortOrder))
      }
    } catch (error) {
      console.error('Search error:', error)
    }
  }

  const searchTags = (term: string): CountedFastTag[] => {
    const termLower = term.toLowerCase()
    return tags.filter((tag) => {
      return tag.name.toLowerCase().includes(termLower)
    })
  }

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term)
  }

  // SEARCH ORDER
  const sortOrderOptions: SearchOrder[] = ['common', 'recent', 'a']
  const optionTranslations: { [key in SearchOrder]: string } = {
    common: 'Most common',
    recent: 'Most recent',
    a: "Most close to 'a'",
  }
  const options: ActionSheetMenuItem[] = sortOrderOptions.map((option) => ({
    label: optionTranslations[option],
    onPress: () => {
      setSortOrder(option)
      handleSearch(option)
      setOptionsMenuIsOpen(false)
    },
  }))

  const sortTags = (tags: CountedFastTag[], sortOrder: SearchOrder) => {
    if (sortOrder === 'common') {
      return tags.sort((a, b) => b.count - a.count)
    } else if (sortOrder === 'recent') {
      return tags.sort((a, b) => {
        if (a.lastUsedDate && b.lastUsedDate) {
          const aDate = new Date(a.lastUsedDate)
          const bDate = new Date(b.lastUsedDate)
          return bDate.getTime() - aDate.getTime()
        }
        return 0
      })
    } else {
      return tags.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      )
    }
  }

  return (
    <View>
      {canSearch && (
        <>
          <Pressable
            style={{}}
            onPress={
              Platform.OS === 'web' ? undefined : () => Keyboard.dismiss()
            }
          >
            <Row
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={6}
              mb={6}
              mx="auto"
              width={searchBarAreaWidth}
            >
              <InputSearch
                value={searchTerm}
                onChangeText={handleSetSearchTerm}
                placeholder={t('Search')}
                onSubmit={() => handleSearch(sortOrder)}
                onSubmitEditing={() => handleSearch(sortOrder)}
                onClear={() => {
                  setSearchTerm('')
                }}
                showClearButton={searchTerm !== ''}
                wrapperWidth={searchBarAreaWidth - filterButtonWidth}
                maxWidth={WEB_MAX_WIDTH}
                autoFocus
              />
              <Row
                justifyContent="flex-end"
                alignItems="center"
                style={{
                  width: filterButtonWidth,
                }}
              >
                <ButtonIcon
                  iconKey="filter"
                  onPress={() => {
                    setOptionsMenuIsOpen(true)
                  }}
                  variant={isFiltering ? 'filled' : 'placeholder'}
                />
              </Row>
            </Row>
          </Pressable>
          {optionsMenuIsOpen && (
            <ActionSheetMenu
              isOpen={optionsMenuIsOpen}
              onClose={() => setOptionsMenuIsOpen(false)}
              heading={t('searchPage.sortBy.header')}
              menuItems={options}
            />
          )}
        </>
      )}
      <Row justifyContent="center" w={'100%'}>
        <SymbolGrid
          width={pageWidth - (Platform.OS === 'web' ? 0 : 32)}
          tags={filteredTags.slice(0, 112)}
          showNumUses={true}
        />
      </Row>
    </View>
  )
}
