import React from 'react'
import { useTranslation } from 'react-i18next'
import { cap } from '../modules/strings/string-helpers'
import { FEW } from '../i18n/config'
import { DrawerWrapper } from './DrawerWrapper'
import { HeaderRight } from './shared'
import * as Screens from '../screens'
import { RootStackScreenProps } from './types'

export default function (
  RootStack: any,
  defaultGroupOptions: any,
  defaultScreenOptions: any,
) {
  const { t } = useTranslation()
  return (
    <>
      <RootStack.Screen
        name="MainTabs"
        options={{
          ...defaultScreenOptions,
          headerShown: false,
          // reset maxWidth so drawer can be full width on web
          contentStyle: {
            ...defaultScreenOptions.contentStyle,
            maxWidth: undefined,
          },
        }}
      >
        {(props: any) => (
          <DrawerWrapper
            {...props}
            defaultScreenOptions={defaultScreenOptions}
          />
        )}
      </RootStack.Screen>
      {/* Private Modals */}
      <RootStack.Group
        screenOptions={{
          ...defaultGroupOptions,
          presentation: 'containedModal',
          animation: 'slide_from_bottom',
        }}
      >
        <RootStack.Screen
          name="PrivateDreamAdd"
          component={Screens.DreamAdd}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('addDreamPage.header'),
          }}
        />
        <RootStack.Screen
          name="PrivateTagImageAdd"
          component={Screens.TagImageAdd}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('Add Character Image'),
          }}
        />
        <RootStack.Screen
          name="PrivateDreamScan"
          component={Screens.DreamScan}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('dreamscaaaaan'),
          }}
        />
        <RootStack.Screen
          options={{
            ...defaultScreenOptions,
            headerTitle: t('editDreamPage.header'),
          }}
          name="PrivateDreamEdit"
          component={Screens.DreamEdit}
        />
        <RootStack.Screen
          name="PrivateDraftEdit"
          component={Screens.DraftEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('editDraftPage.header'),
          }}
        />
        <RootStack.Screen
          name="PrivateDiaryEdit"
          component={Screens.DiaryEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('Edit Entry'),
          }}
        />
        <RootStack.Screen
          name="PrivateDreamShare"
          component={Screens.DreamShare}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.share'),
          }}
        />
        <RootStack.Screen
          name="PrivateDreamerView"
          component={Screens.DreamerView}
          options={{
            ...defaultScreenOptions,
            title: t('common.user'),
            headerRight: (props: any) => (
              <HeaderRight {...props} text={t('common.close')} />
            ),
          }}
        />
      </RootStack.Group>
      {/* Settings */}
      <RootStack.Group
        screenOptions={{
          ...defaultGroupOptions,
          presentation: 'containedModal',
          animation: 'slide_from_bottom',
          headerBackVisible: false,
          headerRight: (props: any) => (
            <HeaderRight {...props} text={t('common.close')} />
          ),
        }}
      >
        <RootStack.Screen
          name="PrivateSettings"
          component={Screens.SettingsMain}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.settings'),
          }}
        />
        {/* Subscriptions */}
        <RootStack.Screen
          name="PrivateSubscriptionStatusView"
          component={Screens.SubscriptionStatus}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.subscriptions.aboutSubscriptions'),
          }}
        />
        <RootStack.Screen
          name="PrivateSubscriptionSettingsEdit"
          component={Screens.Subscriptions}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.subscriptions.heading'),
          }}
        />
        <RootStack.Screen
          name="PrivateCreditSettingsEdit"
          component={Screens.Credits}
          options={{
            ...defaultScreenOptions,
            headerTitle: cap(t('common.credit_plural', { count: FEW })),
          }}
        />
        {/* App Settings */}
        <RootStack.Screen
          name="PrivateDreamerSettingsEdit"
          component={Screens.DreamerSettingsEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.userSettings'),
          }}
        />
        <RootStack.Screen
          name="PrivateDreamSettingsEdit"
          component={Screens.DreamSettingsEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.dreamSettings'),
          }}
        />
        <RootStack.Screen
          name="PrivateNotificationsSettingsEdit"
          component={Screens.NotificationsSettingsEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.notificationSettings'),
          }}
        />
        {/* Profile Settings */}
        <RootStack.Screen
          name="PrivateDreamerEdit"
          component={Screens.DreamerEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.editProfile'),
          }}
        />
        <RootStack.Screen
          name="PrivateDreamerProfileEdit"
          component={Screens.DreamerProfileEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.editPersonalInfo'),
          }}
        />
        {/* Data */}
        <RootStack.Screen
          name="PrivateDataExport"
          component={Screens.DataExport}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.exportData'),
          }}
        />
        <RootStack.Screen
          name="PrivateDataImport"
          component={Screens.DataImport}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.importData.heading'),
          }}
        />
        <RootStack.Screen
          name="PrivateDataDelete"
          component={Screens.DataDelete}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.deleteData'),
          }}
        />
        {/* Support */}
        <RootStack.Screen
          name="PrivateSupport"
          component={Screens.Support}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.support'),
          }}
        />
        {/* About Elsewhere */}
        <RootStack.Screen
          name="PrivateAboutElsewhere"
          component={Screens.AboutElsewhere}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.aboutElsewhere'),
          }}
        />
        <RootStack.Screen
          name="PrivateAboutElsewhereTeam"
          component={Screens.AboutElsewhereTeam}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.team'),
          }}
        />
        <RootStack.Screen
          name="PrivatePrivacyAndTerms"
          component={Screens.PrivacyAndTerms}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.terms'),
          }}
        />
        {/* Other */}
        <RootStack.Screen
          name="Contact"
          component={Screens.Contact}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.communityAndSocials'),
          }}
        />
        <RootStack.Screen
          name="InterpretationStyle"
          component={Screens.InterpretationStyle}
          options={({ route }: RootStackScreenProps<'InterpretationStyle'>) => {
            const { style } = route.params
            return {
              ...defaultScreenOptions,
              headerTitle: t(`interpretationStyles.${style}.title`),
            }
          }}
        />
        <RootStack.Screen
          name="PrivateDreamerPaths"
          component={Screens.PrivateDreamerPaths}
          options={({ route }: RootStackScreenProps<'PrivateDreamerPaths'>) => {
            return {
              ...defaultScreenOptions,
              headerTitle: t('Paths'),
            }
          }}
        />
        <RootStack.Screen
          name="PrivateDreamerPath"
          component={Screens.PrivateDreamerPath}
          options={({ route }: RootStackScreenProps<'PrivateDreamerPath'>) => {
            return {
              ...defaultScreenOptions,
              headerTitle: t('Path'),
            }
          }}
        />
      </RootStack.Group>
      {/* Group modals */}
      <RootStack.Group
        screenOptions={({
          route,
          navigation,
        }: RootStackScreenProps<
          | 'GroupAdd'
          | 'GroupSettings'
          | 'GroupProfileEdit'
          | 'GroupProfileView'
          | 'GroupMembersView'
          | 'GroupDreamerView'
        >) => ({
          ...defaultGroupOptions,
          presentation: 'containedModal',
          animation: 'slide_from_bottom',
          headerBackVisible: false,
          headerRight: (props: any) => {
            return (
              <HeaderRight
                {...props}
                text={t('common.close')}
                onPress={() => {
                  if (route?.params?.groupId) {
                    navigation.navigate('MainTabs', {
                      screen: 'MainStack',
                      params: {
                        screen: 'GroupFeed',
                        params: {
                          groupId: route?.params?.groupId,
                        },
                      },
                    })
                  } else {
                    navigation.navigate('MainTabs', {
                      screen: 'MainStack',
                      params: {
                        screen: 'PrivateFeed',
                      },
                    })
                  }
                }}
              />
            )
          },
        })}
      >
        <RootStack.Screen
          name="GroupAdd"
          component={Screens.GroupAdd}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('addGroupForm.title'),
          }}
        />
        <RootStack.Screen
          name="GroupSettings"
          component={Screens.GroupSettings}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('common.groupSettings'),
          }}
        />
        <RootStack.Screen
          name="GroupProfileEdit"
          component={Screens.GroupProfileEdit}
          options={{
            ...defaultScreenOptions,
            headerTitle: t('settings.group.editGroup.title'),
          }}
        />
        <RootStack.Screen
          name="GroupProfileView"
          component={Screens.GroupProfile}
          options={{
            ...defaultScreenOptions,
            headerBackVisible: false,
            title: cap(t('common.group_plural', { count: 1 })),
          }}
        />
        <RootStack.Screen
          name="GroupMembersView"
          component={Screens.GroupMembers}
          options={{
            ...defaultScreenOptions,
            headerBackVisible: false,
            title: cap(t('common.member_plural', { count: FEW })),
          }}
        />
        <RootStack.Screen
          name="GroupDreamerView"
          component={Screens.DreamerView}
          options={{
            ...defaultScreenOptions,
            title: t('common.user'),
            headerRight: (props: any) => (
              <HeaderRight {...props} text={t('common.close')} />
            ),
          }}
        />
      </RootStack.Group>
    </>
  )
}
